import React from "react";

// MUI
import { Dialog, DialogTitle, List, ListItemText } from "@material-ui/core";

import { ITreeDevice } from "../../../interfaces";

interface IPointModalInfo {
    devicePointInfo: ITreeDevice | any;
    open: boolean;
    handleClose: () => void;
}

/*
  PointInfoModal for DeviceSelection 
    devicePointInfo:      //ITreeDevice object from DeviceTree
    open:                 //boolean to control modal
    handleClose:          //removes devicePointInfo, closes modal
*/
export default function PointInfoModal({
    devicePointInfo,
    open,
    handleClose,
}: IPointModalInfo) {
    const { deviceName, points } = devicePointInfo;

    return (
        <div>
            <Dialog
                onClose={handleClose}
                aria-labelledby="point-dialog"
                open={open}>
                {/* Title */}
                <DialogTitle id="point-dialog-title" style={{ minWidth: 240 }}>
                    {deviceName} Point Data
                </DialogTitle>
                {/* List of Points and data */}
                <List style={{ padding: "1rem" }}>
                    {points?.map((point: any) => {
                        return (
                            <ListItemText
                                key={point.id}
                                primary={point.name}
                                secondary={point.data.displayString}
                            />
                        );
                    })}
                </List>
            </Dialog>
        </div>
    );
}
