import React, { useContext, useEffect, useState } from "react";

// DB
import { functions } from "../../firebase/firebase";
import { useFirebase } from "react-redux-firebase";

// MUI
import {
	AppBar,
	Button,
	CircularProgress,
	Container,
	IconButton,
	Step,
	Stepper,
	StepLabel,
	Typography,
	Toolbar,
	makeStyles,
	LinearProgress,
} from "@material-ui/core";

// Icons
import { ChevronLeft } from "@material-ui/icons";

// Util
import clsx from "clsx";

// Hooks
import { useHistory, useLocation, useParams } from "react-router";
import { useSelector } from "react-redux";

// Contexts
import { DataContext } from "../../contexts";

// Pages
import CreateRole from "../../components/AddGroup/CreateRole";
import DeviceSelection from "../../components/AddGroup/DeviceSelection";
import NameDescription from "../../components/AddGroup/NameDescription";

// Interfaces
import { ITreeDevice } from "../../interfaces";

// Styles
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	button: {
		marginRight: theme.spacing(1),
	},
	content: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		padding: "1rem",
	},
	wrapper: {
		display: "flex",
		justifyContent: "space-evenly",
		flexWrap: "wrap",
		width: "100%",
		marginBottom: "2rem",
	},
	buttonWrapper: {
		display: "flex",
		justifyContent: "space-evenly",
		width: "100%",
		margin: "1rem 0",
	},
	// Appbar
	menuButton: {
		marginRight: 36,
	},
}));

// Interfaces
export interface IFormValues {
	name: string;
	description: string;
}

// DB Functions
const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

// Page for the /addGroup endpoint, the AddGroup stepper
interface IAddGroup {
	group?: any;
}

export default function AddGroup({ group }: IAddGroup) {
	const classes = useStyles();

	// Get group path for edit
	const { pathname } = useLocation();
	const groupPath = group
		? pathname.slice(0, pathname.lastIndexOf("edit"))
		: "";

	// Get group id for edit
	const params: any = useParams();
	const groupId = params?.groupId;

	// Helpers
	const getSteps = () => {
		// Edit group excludes roles
		return group ? ["Name", "Devices"] : ["Name", "Devices", "Roles"];
	};

	// Hooks (firebase)
	const firebase = useSelector((state: any) => state.firebase);
	const rrf = useFirebase();

	// Hooks (control)
	const [activeStep, setActiveStep] = useState(0);
	const [loading, setLoading] = useState(false);
	const [submitting, setSubmitting] = useState(false);

	// Step 1 State
	const defaultFormValues = {
		name: group ? group.name : "",
		description: group ? group.description : "",
	};
	const [formValues, setFormValues] = useState<IFormValues>(defaultFormValues);
	const { name, description } = formValues;

	// Step 2 State (Tree-specific)
	const [checkedKeys, setCheckedKeys] = useState<string[]>(
		group ? group.checkedKeys : []
	);
	const [expandedKeys, setExpandedKeys] = useState<string[]>(
		group ? group.expandedKeys : []
	);
	const [selectedDevices, setSelectedDevices] = useState<ITreeDevice[] | []>(
		group ? group.selectedDevices : []
	);

	// Step 3 State
	const [assignedPermissions, setAssignedPermissions] = useState<any>([]);
	const [roleName, setRoleName] = useState("");

	// Context
	const { clients, setClients } = useContext(DataContext);

	// Redux
	const history = useHistory();

	// Const
	const steps = getSteps();

	// Handlers (Stepper)
	const handleNext = () => {
		setActiveStep((prevActiveStep) => prevActiveStep + 1);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	// Handlers (PUSH GROUP)
	const handleFinish = () => {
		setSubmitting(true);

		// Values
		const payload = {
			creator: firebase.auth.uid,
			name,
			description,
			checkedKeys,
			expandedKeys,
			selectedDevices,
		};

		console.log(payload);

		const role = {
			name: roleName,
			permissions: assignedPermissions,
			// Tree
		};

		// Add Group
		const groupAdded = rrf.push(
			`users/${firebase.auth.uid}/manage/groups`,
			payload
		);
		// Add Role to Group
		const roleAdded = groupAdded.then((res: any) => {
			rrf.push(
				`users/${firebase.auth.uid}/manage/groups/${res.key}/roles`,
				role
			);
		});
		// Cleanup
		roleAdded.then((res: any) => {
			setSubmitting(false);
			setFormValues(defaultFormValues);
			setCheckedKeys([]);
			setExpandedKeys([]);
			setSelectedDevices([]);
			setRoleName("");
			history.push("/dashboard");
		});
	};

	// Update for EDIT
	const handleUpdate = () => {
		setSubmitting(true);
		// Values
		const payload = {
			creator: firebase.auth.uid,
			name,
			description,
			checkedKeys,
			expandedKeys,
			selectedDevices,
		};

		// Add Group
		const groupUpdated = rrf.update(
			`users/${firebase.auth.uid}/manage/groups/${groupId}`,
			payload
		);

		// Cleanup
		groupUpdated.then((res: any) => {
			setSubmitting(false);
			setFormValues(defaultFormValues);
			setCheckedKeys([]);
			setExpandedKeys([]);
			setSelectedDevices([]);
			setRoleName("");
			history.push(groupPath);
		});
	};

	const handleGoBack = () => {
		setActiveStep(0);
		setFormValues(defaultFormValues);
		history.push("/dashboard");
	};

	// Handlers (Step 1: Name/Desc)
	const handleChange =
		(value: keyof IFormValues) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			setFormValues({ ...formValues, [value]: event.target.value });
		};

	// Handlers (Step 3: Role)
	const handleRoleName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRoleName(event.target.value);
	};

	// Get data
	useEffect(() => {
		if (firebase.profile?.api && !clients.length) {
			setLoading(true);

			fetchEndpoint({
				route: "clients",
				api: firebase.profile.api,
			})
				.then((res: any) => {
					setClients(JSON.parse(res.data));
					setLoading(false);
				})
				.catch((e: any) => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line
	}, [firebase.profile]);

	// Step content
	const getStepContent = (step: number) => {
		switch (step) {
			case 0:
				return (
					<NameDescription
						name={name}
						description={description}
						handleChange={handleChange}
					/>
				);
			case 1:
				return (
					<DeviceSelection
						clients={clients}
						expandedKeys={expandedKeys}
						setExpandedKeys={setExpandedKeys}
						checkedKeys={checkedKeys}
						setCheckedKeys={setCheckedKeys}
						selectedDevices={selectedDevices}
						setSelectedDevices={setSelectedDevices}
					/>
				);
			case 2:
				return (
					<CreateRole
						roleName={roleName}
						handleRoleName={handleRoleName}
						selectedDevices={selectedDevices}
						assignedPermissions={assignedPermissions}
						setAssignedPermissions={setAssignedPermissions}
					/>
				);

			default:
				return "Unknown step";
		}
	};

	return (
		<div className={classes.root}>
			<AppBar position="absolute" color={group ? "secondary" : "primary"}>
				<Toolbar>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="drawerOpen drawer"
						onClick={group ? () => history.push(groupPath) : handleGoBack}
						className={clsx(classes.menuButton)}
					>
						<ChevronLeft />
					</IconButton>
					<Typography component="h1" variant="h6" color="inherit" noWrap>
						{group ? `Editing ${group.name}` : "Create Group"}
					</Typography>
					<span style={{ display: "flex", flexGrow: 1 }} />
					{loading && <CircularProgress size={20} style={{ color: "white" }} />}
				</Toolbar>
			</AppBar>
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};

					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			<Container
				maxWidth="md"
				style={{
					justifyContent: "flex-end",
					position: "relative",
					marginTop: "1rem",
				}}
			>
				{activeStep === steps.length - 1 ? (
					<div className={classes.wrapper}>
						{/* Step Content */}
						<div className={classes.content}>{getStepContent(activeStep)}</div>

						{/* Final Step Buttons */}
						{submitting ? (
							<LinearProgress style={{ width: "100%" }} />
						) : (
							<div className={classes.buttonWrapper}>
								<Button
									disabled={activeStep === 0}
									onClick={handleBack}
									className={classes.button}
								>
									Back
								</Button>

								{/* Update // Create */}
								{group ? (
									<Button
										variant="contained"
										color="primary"
										onClick={handleUpdate}
										className={classes.button}
									>
										Update Group
									</Button>
								) : (
									<Button
										variant="contained"
										color="primary"
										onClick={handleFinish}
										className={classes.button}
										disabled={Boolean(!roleName)}
									>
										Create Group
									</Button>
								)}
							</div>
						)}
					</div>
				) : (
					<div className={classes.wrapper}>
						{/* Step Content (repeat, fix later too tired) */}
						<div className={classes.content}>{getStepContent(activeStep)}</div>

						{/* Step Buttons */}
						<div className={classes.buttonWrapper}>
							<Button
								disabled={activeStep === 0}
								onClick={handleBack}
								className={classes.button}
							>
								Back
							</Button>

							<Button
								variant="contained"
								color="primary"
								onClick={handleNext}
								className={classes.button}
								disabled={!Boolean(name)}
							>
								{activeStep === steps.length - 1 ? "Finish" : "Next"}
							</Button>
						</div>
					</div>
				)}
			</Container>
		</div>
	);
}
