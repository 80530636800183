import React, { ReactElement } from "react";

// MUI
import { LinearProgress } from "@material-ui/core";

// Hooks
import { useSelector } from "react-redux";
import { useFirebaseConnect } from "react-redux-firebase";
import { useParams } from "react-router";

// Components
import ErrorGroupNotFound from "../../components/Group/ErrorGroupNotFound";
import AddGroup from "../Dashboard/AddGroup";

export default function EditGroup(): ReactElement {
	// Hooks
	const firebase = useSelector((state: any) => state.firebase);
	const { groupId }: any = useParams();

	// Firebase realtime connection
	const uid = firebase.auth.uid;
	const dbString = `users/${uid}/manage/groups/${groupId}`;
	useFirebaseConnect([dbString]);

	// Firebase groups from rrf
	const group = useSelector(
		(state: any) => state.firebase.data?.users?.[uid]?.manage?.groups?.[groupId]
	);

	// Wait for load
	if (!firebase.auth.isLoaded || !firebase.profile.isLoaded) {
		return <LinearProgress />;
	}

	// Test for group
	if (firebase.auth.isLoaded && firebase.profile.isLoaded && !group) {
		return <ErrorGroupNotFound />;
	}

	return (
		<div>
			<AddGroup group={group} />
		</div>
	);
}
