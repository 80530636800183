import React from "react";

// Hooks
import { useSelector } from "react-redux";

// API
import { fetchBuildings } from "../api";

// Contexts
import { DataContext } from "../contexts";

// useBuildingSelector() => string[] of client.id currently loading to allow loading of multiple clients async
export default function useBuildingSelector(client: any) {
	// Redux
	const firebase = useSelector((state: any) => state.firebase);

	// State
	const [loading, setLoading]: any = React.useState([]);

	// Context
	const { buildings, setBuildings }: any = React.useContext(DataContext);

	// No client, return loading as default
	if (!client) return [];

	// If already clients, return loading with client filtered out
	if (buildings[client.id])
		return [loading.filter((id: string) => id !== client.id)];

	// If not currently loading a client
	if (!loading.includes(client.id) && !buildings[client.id]) {
		// Add the id to the list
		setLoading([...loading, client.id]);
		// Fetch buildings from client
		fetchBuildings(firebase.profile.api, client)
			.then((res: any) => {
				// Set the [client.id]: Building[]
				setBuildings({ ...buildings, [client.id]: res });
				// Remove client.id from loading
				setLoading(loading.filter((id: string) => id !== client.id));
			})
			.catch((e: any) => ({
				error: e,
			}));
	}

	// Check if buildings loaded and filter out client
	if (buildings[client.id])
		return [loading.filter((id: string) => id !== client.id)];

	// Catch the rest and return loading
	return [loading];
}
