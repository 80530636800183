import { functions } from "../firebase/firebase";

const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

const fetchBuildings = (api: string, client: any) => {
	const buildingEndpoints: any[] = [];

	client?.sites?.forEach((site: any) =>
		site.buildings.forEach((building: any) => {
			buildingEndpoints.push(
				fetchEndpoint({
					route: `building/${building.id}`,
					api,
				})
					.then((res: any) => ({
						...JSON.parse(res.data),
					}))
					.catch((e: any) => ({ error: e }))
			);
		})
	);

	return Promise.all(buildingEndpoints)
		.then((res: any) => {
			return res;
		})
		.catch((e: any) => {
			return { error: e };
		});
};

export default fetchBuildings;
