import firebase from "firebase";

import "firebase/auth/dist/index.cjs";
import "firebase/database/dist/index.cjs";

let config;
if (
    process.env.NODE_ENV === "development" ||
    window.location.host.split(".")[0] === "staging"
) {
    config = {
        apiKey: "AIzaSyDxxVCGEK27RIPr6XkoXqeTsReg2-6Txqs",
        authDomain: "tapa-dev.firebaseapp.com",
        databaseURL: "https://tapa-dev.firebaseio.com",
        projectId: "tapa-dev",
        storageBucket: "tapa-dev.appspot.com",
        messagingSenderId: "522408820738",
        appId: "1:522408820738:web:56579f738c2f41baa6a71b",
    };
} else {
    config = {
        apiKey: "AIzaSyDxxVCGEK27RIPr6XkoXqeTsReg2-6Txqs",
        authDomain: "tapa-dev.firebaseapp.com",
        databaseURL: "https://tapa-dev.firebaseio.com",
        projectId: "tapa-dev",
        storageBucket: "tapa-dev.appspot.com",
        messagingSenderId: "522408820738",
        appId: "1:522408820738:web:56579f738c2f41baa6a71b",
    };
}

if (!firebase.apps.length) {
    firebase.initializeApp(config);
}

//Use Admin SDK
const auth = firebase.auth();
const db = firebase.database();
const functions = firebase.functions();
const store = firebase.firestore();
const storage = firebase.storage();

export { auth, db, functions, storage, store };
