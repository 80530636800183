import React, { ReactElement, memo, useState, useCallback } from "react";

// MUI
import { Divider, List } from "@material-ui/core";

// Util
import { orderBy } from "lodash";

// Components
import UserListItem from "./UserListItem";
import ManageUserDialog from "../Users/ManageUserDialog";

// Interfaces
import { IRoleUser } from "../../../interfaces";

// Firebase
import { functions } from "../../../firebase/firebase";
import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";

const deleteUser = functions.httpsCallable("deleteUser");

interface Props {
	users: any;
}

// Render
const UserList = ({ users }: Props): ReactElement => {
	const [user, setUser] = useState<any>();

	// DB Data
	const uid = useSelector((state: any) => state.firebase.auth?.uid);
	const rrf = useFirebase();

	// Handlers
	// Opens Dialog
	const manageUser = useCallback(
		(user: any) => {
			setUser(user);
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[user, users]
	);

	const handleClose = () => {
		setUser(null);
	};

	console.log(user);

	const handleDeleteUser = (user: IRoleUser) => {
		deleteUser({ email: user.email }).then((res: any) => {
			const dbString = `users/${uid}/manage/groups/${user.groupId}/roles/${user.roleId}/users/${user.userId}`;

			// Remove from role
			const removeFromRole = rrf.database().ref(dbString).remove();

			// Remove from users
			removeFromRole.then((res: any) => {
				rrf
					.database()
					.ref(`users/${user.uid}`)
					.remove()
					.catch((e: any) => {
						setUser(null);
					});
				setUser(null);
			});
		});
	};

	return (
		<>
			<List>
				<Divider />
				{orderBy(users, ["firstName", "lastName", "email"]).map((user: any) => {
					return (
						<UserListItem
							key={user.userId}
							user={user}
							manageUser={manageUser}
						/>
					);
				})}
			</List>

			{/* Manage User Dialog */}

			<ManageUserDialog
				open={Boolean(user)}
				user={user}
				handleClose={handleClose}
				handleDeleteUser={handleDeleteUser}
			/>
		</>
	);
};

export default memo(UserList);
