import React, { memo } from "react";

// MUI
import {
	createStyles,
	IconButton,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Theme,
	Tooltip,
	Typography,
} from "@material-ui/core";
// Icons
import { Edit, Security, Visibility } from "@material-ui/icons";

// Colors
import { amber, green, lightBlue } from "@material-ui/core/colors";

// Util
import clsx from "clsx";

export interface Props {
	permission: {
		buildingId: string;
		buildingName: string;
		clientId: string;
		clientName: string;
		deviceId: string;
		deviceName: string;
		floorId: string;
		floorName: string;
		permission: string;
		pointId: string;
		pointName: string;
		siteId: string;
		siteName: string;
		spaceId: string;
		spaceName: string;
	};
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		mobilePadding: {
			[theme.breakpoints.down("sm")]: {
				paddingRight: "20%",
			},
		},
		read: {
			color: green[300],
		},
		write: {
			color: lightBlue[500],
		},
		readwrite: {
			color: amber[700],
		},
	})
);

const PermissionListItem = ({ permission }: Props) => {
	const classes = useStyles();

	return (
		<ListItem>
			<ListItemText
				primary={permission.pointName}
				secondary={
					<Typography component="span" variant="body2" color="textSecondary">
						{permission.buildingName} / {permission.floorName} /{" "}
						{permission.spaceName} / {permission.deviceName}
					</Typography>
				}
				className={classes.mobilePadding}
			/>
			<ListItemSecondaryAction>
				<Tooltip title="Read">
					<span>
						<IconButton
							size="small"
							disabled={permission.permission === "write"}
						>
							<Visibility
								className={clsx({
									[classes.read]: permission.permission !== "write",
								})}
							/>
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip title="Write">
					<span>
						<IconButton
							size="small"
							disabled={permission.permission === "read"}
						>
							<Edit
								className={clsx({
									[classes.write]: permission.permission !== "read",
								})}
							/>
						</IconButton>
					</span>
				</Tooltip>
				<Tooltip title="Full Access">
					<span>
						<IconButton
							size="small"
							disabled={permission.permission !== "readwrite"}
						>
							<Security
								className={clsx({
									[classes.readwrite]: permission.permission === "readwrite",
								})}
							/>
						</IconButton>
					</span>
				</Tooltip>
			</ListItemSecondaryAction>
		</ListItem>
	);
};

export default memo(PermissionListItem);
