import React from "react";

// MUI
import { Grid, LinearProgress, makeStyles, Theme } from "@material-ui/core";

// Components
import Login from "../components/Auth/Login";

// Redux
import { useSelector } from "react-redux";
import { useHistory } from "react-router";

const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
}));

// Page for the / endpoint
// Acts mainly as a staging area to load up auth and then push to /dashboard
function Auth() {
	const classes = useStyles();

	// Hooks
	const auth = useSelector((state: any) => state.firebase.auth);
	const history = useHistory();

	// Wait for auth to init
	if (!auth.isLoaded) {
		return <LinearProgress style={{ width: "100%" }} />;
	}

	// If not authorized, show login
	if (auth.isEmpty) {
		return (
			<div className="App">
				<Grid container className={classes.root}>
					<Grid item xs={12}>
						<Login />
					</Grid>
				</Grid>
			</div>
		);
	}

	if (!auth.isEmpty) {
		history.push("/dashboard");
		return null;
	}

	return null;
}

export default Auth;
