import React, { useState } from "react";

// Hooks
import { useSelector } from "react-redux";

// Functions
import { functions } from "../firebase/firebase";

// Tapa Fetch
const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

export default function useClientSelector(contextClients: any[]) {
	// Redux
	const firebase = useSelector((state: any) => state.firebase);

	// State
	const [clients, setClients]: any = useState([]);
	const [loading, setLoading] = React.useState(false);

	// If already clients, return;
	if (contextClients?.length) return [contextClients, false];

	// Else, get clients and handle loading;
	if ((!clients || (!clients.length && !clients?.error)) && !loading) {
		setLoading(true);
		fetchEndpoint({
			route: "clients",
			api: firebase.profile.api,
		})
			.then((res: any) => {
				setClients(JSON.parse(res.data));
				setLoading(false);
			})
			.catch((e: any) => {
				console.log(e);
				setClients({ error: e });
				setLoading(false);
			});
	}

	// eslint-disable-next-line

	return [clients, loading];
}
