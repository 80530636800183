import React, { useState } from "react";

// Firebase
import { auth, functions } from "../../../firebase/firebase";
import { useFirebase } from "react-redux-firebase";

// MUI
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Grid,
	LinearProgress,
	List,
	Paper,
	Tab,
	Tabs,
	TextField,
	useTheme,
} from "@material-ui/core";

// Interfaces
import { IRoleUser } from "../../../interfaces";

// Hooks
import { useSelector } from "react-redux";

// Util
import { sortBy } from "lodash";

// Components
import ListUser from "./ListUser";
import { useParams } from "react-router";

// Cloud Function
const getApiKey = functions.httpsCallable("getApiKey");
const fetchEndpoint = functions.httpsCallable("fetchEndpoint");
const postPermissions = functions.httpsCallable("postPermissions");
const createUser = functions.httpsCallable("createUser");
const deleteUser = functions.httpsCallable("deleteUser");

// API
const getPointsPermissions = (permission: any, api: string) => {
	return fetchEndpoint({ route: `device/${permission.device}`, api })
		.then((res) => JSON.parse(res.data))
		.catch((e: Error) => {
			console.log(e);
			return e;
		});
};

/*

TAB COMPONENT

*/

interface TabPanelProps {
	children?: React.ReactNode;
	dir?: string;
	index: any;
	value: any;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`full-width-tabpanel-${index}`}
			aria-labelledby={`full-width-tab-${index}`}
			{...other}
		>
			{value === index && <Box p={3}>{children}</Box>}
		</div>
	);
}

function a11yProps(index: any) {
	return {
		id: `full-width-tab-${index}`,
		"aria-controls": `full-width-tabpanel-${index}`,
	};
}

/*

  MAIN COMPONENT

*/
interface IAddUserDialog {
	open: boolean;
	handleClose: () => void;
	role: any;
}

export default function AddUserDialog({
	open,
	handleClose,
	role,
}: IAddUserDialog) {
	const theme = useTheme();

	// Construct DB String
	const params: any = useParams();
	const uid = useSelector((state: any) => state.firebase.auth.uid);
	const api = useSelector((state: any) => state.firebase.profile.api);
	const groupId = params?.groupId;
	const roleId = role.roleId || params?.roleId;

	console.log(role);
	console.log(roleId);

	// DB String
	const dbString = `users/${uid}/manage/groups/${groupId}/roles/${roleId}/users`;

	interface IFormValues {
		firstName: string;
		lastName: string;
		position: string;
		phone: string;
		text: string;
		email: string;
	}

	const defaultFormValues = {
		firstName: "",
		lastName: "",
		position: "",
		phone: "",
		text: "",
		email: "",
	};
	// State (Form)
	const [formValues, setFormValues] = useState<IFormValues>(defaultFormValues);
	const { firstName, lastName, phone, text, email } = formValues;

	// State (Status)
	const [deleting, setDeleting] = useState(false);
	const [error, setError] = useState("");
	const [loading, setLoading] = useState(false);

	// State (Tabs)
	const [value, setValue] = React.useState(0);

	// Redux

	// Db
	const rrf = useFirebase();

	// Handlers (Textfield)
	const handleChange =
		(value: keyof IFormValues) =>
		(event: React.ChangeEvent<HTMLInputElement>) => {
			if (error) {
				setError("");
			}
			setFormValues({ ...formValues, [value]: event.target.value });
		};

	// Handlers (Tabs)
	const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setValue(newValue);
	};

	// Add User Complete
	const handleSignUp = () => {
		setLoading(true);

		// Create Payload for TAPA API post
		console.log(role);

		const permissionsPayloadPromises = role?.permissions.map(
			(permission: any) => {
				return getPointsPermissions(permission, api).then((res: any) => {
					console.log(res);
					if (res?.points) {
						return res.points.map((point: any) => {
							return {
								clientId: permission.client,
								siteId: permission.site,
								buildingId: permission.building,
								floorId: permission.floor,
								spaceId: permission.space,
								deviceId: permission.device,
								pointId: point.id,
								permission: permission.permission,
							};
						});
					} else return [];
				});
			}
		);

		const permissionsPayload = Promise.all(permissionsPayloadPromises).then(
			(res: any) => {
				let permissionsArray: any[] = [];

				if (Array.isArray(res)) {
					res.forEach((pA: any) => {
						console.log(pA);
						permissionsArray = permissionsArray.concat(pA);
					});
				}

				return permissionsArray;
			}
		);

		// Get API KEY from TAPA API using email
		const getKey = getApiKey(email).then((res: any) => res);

		// Use API KEY to post permissions to TAPA API
		const postKeyPermissions = getKey.then((res: any) => {
			const { data } = res;

			return permissionsPayload.then((res: any) => {
				console.log(res);
				return postPermissions({
					api: data.apiKey,
					permissions: res,
				});
			});
		});

		// Await success, add data to Firebase AUTH
		postKeyPermissions.then((res: any) => {
			const { data: { api } = { api: "" } } = res;

			if (api) {
				// Create USER
				const userPayload = {
					api,
					firstName,
					lastName,
					email,
					phone,
					text,
					access: {
						canManageGroup: false,
						canAddUsers: false,
						disabled: false,
					},
					groupId,
					roleId,
					parentId: uid,
				};

				// Add USER to AUTH and RTDB
				const addUser = createUser(userPayload).then((res: any) => {
					if (res.data.codePrefix && res.data.codePrefix === "auth") {
						if (res.data.errorInfo.code === "auth/email-already-exists") {
							setError(res.data.errorInfo.message);
						}
						return res.data;
					} else {
						const dbUser = { ...userPayload, uid: res.data.uid };
						return rrf.push(dbString, dbUser);
					}
				});

				// Send RESET and Clean up
				addUser.then((res: any) => {
					if (res.errorInfo) {
						setLoading(false);
						return;
					}
					auth.sendPasswordResetEmail(email).catch((e: any) => {
						console.log(e);
					});
					setFormValues(defaultFormValues);
					setLoading(false);
					handleClose();
				});
			}
		});
	};

	// Handlers
	const handleRemoveUser = (user: IRoleUser) => {
		setDeleting(true);
		deleteUser({ email: user.email }).then((res: any) => {
			const dbString = `users/${uid}/manage/groups/${user.groupId}/roles/${user.roleId}/users/${user.userId}`;

			// Remove from role
			const removeFromRole = rrf.database().ref(dbString).remove();

			// Remove from users
			removeFromRole.then((res: any) => {
				rrf.database().ref(`users/${user.uid}`).remove();
				setDeleting(false);
			});
		});
	};

	// ListUser Map Data
	const usersPayload = role.users
		? Object.keys(role.users).map((key: string) => {
				return { ...role.users[key], userId: key };
		  })
		: [];

	return (
		<div>
			<Dialog
				open={open}
				onClose={handleClose}
				aria-labelledby="form-dialog-title"
				disableBackdropClick={deleting}
				disableEscapeKeyDown={deleting}
			>
				{loading && <LinearProgress style={{ width: "100%" }} />}
				<DialogTitle id="form-dialog-title">
					Add User to {role.name}
				</DialogTitle>

				<DialogContent>
					<DialogContentText>
						Add a user to this role. The user will get all of the permissions
						allowed by the role. Custom changes can be made.
					</DialogContentText>
					<div>
						{/* Tabs */}
						<Paper square>
							<Tabs
								value={value}
								indicatorColor="primary"
								textColor="inherit"
								variant="fullWidth"
								centered
								onChange={handleTabChange}
								aria-label="add users tabs"
							>
								<Tab label="Create User" {...a11yProps(0)} />
								<Tab label="Current Users" {...a11yProps(1)} />
							</Tabs>
						</Paper>

						{/* Add User */}
						<TabPanel value={value} index={0} dir={theme.direction}>
							<Grid container spacing={3}>
								{/* Name */}
								<Grid item xs={4}>
									<TextField
										required
										id="firstName"
										name="firstName"
										label="First Name"
										value={firstName}
										variant="outlined"
										fullWidth
										onChange={handleChange("firstName")}
										disabled={loading}
									/>
								</Grid>
								<Grid item xs={8}>
									<TextField
										required
										id="lastName"
										name="lastName"
										label="Last Name"
										value={lastName}
										variant="outlined"
										fullWidth
										onChange={handleChange("lastName")}
										disabled={loading}
									/>
								</Grid>
								{/* Email */}
								<Grid item xs={12}>
									<TextField
										error={Boolean(error)}
										required
										id="email"
										name="email"
										label="Email"
										value={email}
										variant="outlined"
										fullWidth
										onChange={handleChange("email")}
										disabled={loading}
										helperText={Boolean(error) ? error : ""}
									/>
								</Grid>
								{/* Phone */}
								<Grid item xs={6}>
									<TextField
										id="phone"
										name="phone"
										label="Phone"
										value={phone}
										variant="outlined"
										fullWidth
										onChange={handleChange("phone")}
										disabled={loading}
									/>
								</Grid>
								<Grid item xs={6}>
									<TextField
										id="text"
										name="text"
										label="Text"
										value={text}
										variant="outlined"
										fullWidth
										onChange={handleChange("text")}
										disabled={loading}
									/>
								</Grid>
							</Grid>{" "}
						</TabPanel>

						{/* Existing Users */}
						<TabPanel value={value} index={1} dir={theme.direction}>
							<List>
								{sortBy(usersPayload, ["lastName"], ["asc"]).map(
									(user: any) => {
										return (
											<ListUser
												key={user.userId}
												user={user}
												handleRemoveUser={handleRemoveUser}
												deleting={deleting}
											/>
										);
									}
								)}
							</List>
						</TabPanel>
					</div>
				</DialogContent>
				{Boolean(value === 0) && (
					<DialogActions>
						<Button onClick={handleClose} disabled={loading}>
							Cancel
						</Button>
						<Button onClick={handleSignUp} disabled={loading}>
							Add User
						</Button>
					</DialogActions>
				)}
			</Dialog>
		</div>
	);
}
