import * as React from "react";

import clsx from "clsx";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	createStyles,
	fade,
	FormControl,
	FormControlLabel,
	Link,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem,
	RadioGroup,
	Theme,
	Typography,
} from "@material-ui/core";

import { blue, orange } from "@material-ui/core/colors";

import { ExpandMore } from "@material-ui/icons";
import { compact, orderBy } from "lodash";
import { SpaceContainer } from "../SpaceContainer";

// -------- STYLES START --------- //
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		grow: {
			flexGrow: 1,
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
		icon: {
			verticalAlign: "bottom",
			height: 20,
			width: 20,
		},
		details: {
			alignItems: "center",
		},
		column: {
			width: "100%",
			display: "flex",
			flexBasis: "50%",
		},
		columnThird: {
			display: "flex",
			flexBasis: "33%",
		},
		columnTwoThirds: {
			display: "flex",
			flexBasis: "66%",
		},
		center: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		end: {
			justifyContent: "flex-end",
		},
		helper: {
			borderLeft: `2px solid ${theme.palette.divider}`,
			padding: theme.spacing(1, 2),
		},
		link: {
			color: theme.palette.primary.main,
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
			},
		},
		divider: {
			display: "flex",
			justifyContent: "center",
			borderBottom: "1px solid #ccc",
		},

		// Accordion Heading
		expanded: {
			background: orange[50],
			margin: "2rem -4rem !important",
			boxShadow: theme.shadows[10],
		},
		primary: {
			fontWeight: 800,
		},
		accordionSummaryRoot: {
			flexDirection: "row-reverse",
		},
		accordionSummaryContent: {
			alignItems: "center",
			paddingLeft: "2rem",
		},

		// Search
		search: {
			position: "relative",
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.15),
			"&:hover": {
				backgroundColor: fade(theme.palette.common.white, 0.25),
			},
			marginLeft: 0,
			width: "100%",
			height: 35,
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.spacing(1),
				width: "auto",
			},
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: "100%",
			position: "absolute",
			pointerEvents: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			color: theme.palette.text.hint,
		},
		inputRoot: {
			color: "inherit",
			borderRadius: 8,
			border: `2px solid ${blue[100]}`,
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create("width"),
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: "15ch",
				"&:focus": {
					width: "25ch",
				},
			},
		},
	})
);
// -------- STYLES END --------- //

interface IFloorContainerProps {
	floor: any;
	setParentPermissions: any;
}

const getCheckedState = (data: any, variant: string) => {
	return Boolean(
		data.children &&
			data.children.filter((f: any) => f.permission === variant).length ===
				data.children.length
	);
};

const getIndeterminateState = (data: any, variant: string) => {
	let dataChildren = data.children?.filter(
		(f: any) => f.permission === variant
	);
	return Boolean(
		dataChildren?.length && dataChildren.length !== data.children?.length
	);
};

export const FloorContainer: React.FC<IFloorContainerProps> = ({
	floor,
	setParentPermissions,
}) => {
	// Checkbox states

	const classes = useStyles();
	// -------- COMPONENT STATE --------- //
	const [selectedSpace, setSelectedSpace] = React.useState(-1);
	// -------- END COMPONENT STATE --------- //

	// -------- FLOOR STATE START --------- //
	// Building-level show floors
	const [
		showSpacesAnchor,
		setShowSpacesAnchor,
	] = React.useState<null | HTMLElement>(null);

	const handleShowSpaces = (event: React.MouseEvent<HTMLButtonElement>) => {
		setShowSpacesAnchor(event.currentTarget);
	};

	const handleCloseShowSpaces = () => {
		setShowSpacesAnchor(null);
	};
	// -------- FLOOR STATE END --------- //

	// -------- GENERATE MENU ITEMS --------- //
	const handleSelectSpaceClick = (
		event: React.MouseEvent<HTMLElement>,
		idx: number
	) => {
		setSelectedSpace(idx);
		setShowSpacesAnchor(null);
	};

	const spacesMenu = compact(
		floor?.children.map((s: any, idx: number) => (
			<MenuItem
				key={s.id}
				onClick={(event) => handleSelectSpaceClick(event, idx)}
				selected={idx === selectedSpace}
			>
				{s.name}
			</MenuItem>
		))
	);
	// -------- END MENU ITEMS --------- //

	return (
		<div className={classes.root}>
			<Accordion
				square
				TransitionProps={{ unmountOnExit: true }}
				disabled={!Boolean(spacesMenu?.length)}
				classes={{ expanded: classes.expanded }}
			>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-label="Expand"
					aria-controls="additional-actions1-content"
					id="additional-actions1-header"
					classes={{
						root: classes.accordionSummaryRoot,
						content: classes.accordionSummaryContent,
					}}
				>
					<div className={clsx(classes.column, classes.grow)}>
						<ListItemText
							primary={floor.name}
							secondary={
								spacesMenu?.length ? (
									<Link
										onClick={(event: any) => {
											event.stopPropagation();
											handleShowSpaces(event);
										}}
										onFocus={(event: any) => event.stopPropagation()}
									>
										{selectedSpace !== -1
											? `${floor?.children[selectedSpace]?.name} (Filter Active)`
											: `${spacesMenu?.length || "0"} ${
													spacesMenu?.length === 1 ? "Space" : "Spaces"
											  } Available`}
									</Link>
								) : (
									"No spaces available"
								)
							}
							classes={{ primary: classes.primary }}
						/>
					</div>
					<div
						className={clsx(classes.column, classes.end)}
						style={{ paddingRight: "1rem" }}
					>
						<FormControl
							component="fieldset"
							margin="dense"
							style={{ width: "100%" }}
						>
							<RadioGroup
								row
								aria-label="Assign to all devices in Floor"
								name="mass-select"
								onClick={(event: any) => {
									event.stopPropagation();
								}}
								onFocus={(event: any) => event.stopPropagation()}
							>
								<div
									className={clsx(classes.columnThird, classes.center)}
									style={{ borderRight: "1px solid #ccc" }}
								>
									<FormControlLabel
										onClick={() =>
											setParentPermissions(
												floor,
												floor.permission === "read" ? "" : "read"
											)
										}
										checked={getCheckedState(floor, "read")}
										value="read"
										control={
											<Checkbox
												color="primary"
												size="small"
												indeterminate={getIndeterminateState(floor, "read")}
											/>
										}
										label="Read"
										labelPlacement="bottom"
									/>
								</div>
								<div
									className={clsx(classes.columnThird, classes.center)}
									style={{ borderRight: "1px solid #ccc" }}
								>
									<FormControlLabel
										onClick={() =>
											setParentPermissions(
												floor,
												floor.permission === "write" ? "" : "write"
											)
										}
										checked={getCheckedState(floor, "write")}
										value="write"
										control={
											<Checkbox
												color="primary"
												size="small"
												indeterminate={getIndeterminateState(floor, "write")}
											/>
										}
										label="Write"
										labelPlacement="bottom"
									/>
								</div>
								<div className={clsx(classes.columnThird, classes.center)}>
									<FormControlLabel
										onClick={() =>
											setParentPermissions(
												floor,
												floor.permission === "readwrite" ? "" : "readwrite"
											)
										}
										checked={getCheckedState(floor, "readwrite")}
										value="readwrite"
										control={
											<Checkbox
												color="primary"
												size="small"
												indeterminate={getIndeterminateState(
													floor,
													"readwrite"
												)}
											/>
										}
										label="ReadWrite"
										labelPlacement="bottom"
									/>
								</div>
							</RadioGroup>
						</FormControl>
					</div>
				</AccordionSummary>

				{/* CONTAINER */}
				<AccordionDetails style={{ flexDirection: "column", padding: 0 }}>
					<div className={clsx(classes.columnThird, classes.divider)} />
					<Typography
						variant="caption"
						align="center"
						style={{ padding: ".5rem" }}
					>
						Expand {floor.name} spaces for additional granularity
					</Typography>
					<div className={clsx(classes.columnThird, classes.divider)} />

					{/* // -------- SPACES START --------- // */}
					{selectedSpace === -1
						? orderBy(floor.children, ["name"])?.map((space: any) => (
								<SpaceContainer
									key={space.id}
									space={space}
									setParentPermissions={setParentPermissions}
								/>
						  ))
						: floor.children
								.filter((s: any) => floor.children[selectedSpace]?.id === s.id)
								.map((space: any) => (
									<SpaceContainer
										key={space.id}
										space={space}
										setParentPermissions={setParentPermissions}
									/>
								))}

					{/* // -------- SPACES END --------- // */}
				</AccordionDetails>
			</Accordion>

			{/* Floor-level Menus */}

			{/* Show Floors Menu */}
			<Menu
				id="floors-menu"
				anchorEl={showSpacesAnchor}
				keepMounted
				open={Boolean(showSpacesAnchor)}
				onClose={handleCloseShowSpaces}
			>
				<MenuItem
					onClick={(event: any) => handleSelectSpaceClick(event, -1)}
					selected={selectedSpace === -1}
				>
					All Spaces
				</MenuItem>
				{spacesMenu}
			</Menu>
		</div>
	);
};
