import React, { useState } from "react";

// Hooks
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import useGroupSelector from "../../hooks/useGroupSelector";

// MUI
import {
	Breadcrumbs,
	Collapse,
	Container,
	createStyles,
	IconButton,
	LinearProgress,
	Link,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Paper,
	Theme,
	Tooltip,
	Typography,
} from "@material-ui/core";

// Components
import ErrorGroupNotFound from "../../components/Group/ErrorGroupNotFound";

// Icons
import { Edit, KeyboardArrowDown, PersonAdd } from "@material-ui/icons";

// Util
import clsx from "clsx";
import PermissionList from "../../components/Group/Role/PermissionList";
import UserList from "../../components/Group/Role/UserList";
import AddUserDialog from "../../components/Group/Overview/AddUserDialog";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: "1rem",
		},
		wrapper: {
			display: "flex",
			alignItems: "center",
			marginBottom: "1rem",
			padding: ".5rem",
		},
		headerContainer: {
			padding: "1rem",
		},
		header: {
			display: "flex",
			flexDirection: "column",
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
			fontWeight: theme.typography.fontWeightRegular,
		},
		expanded: {
			transform: "rotate(-180deg)",
			transition: theme.transitions.create("transform", {
				duration: theme.transitions.duration.shortest,
			}),
		},
		closed: {
			transform: "rotate(0)",
			transition: theme.transitions.create("transform", {
				duration: theme.transitions.duration.shortest,
			}),
		},
	})
);

const GroupRole = () => {
	const classes = useStyles();

	const firebase = useSelector((state: any) => state.firebase);
	const history = useHistory();

	const group = useGroupSelector();
	const params: any = useParams();

	const groupId = params?.groupId;
	const roleId = params?.roleId;

	const role = group?.roles ? group?.roles[roleId] : null;

	// States
	const [expandPermissions, setExpandPermissions] = useState(false);
	const [expandUsers, setExpandUsers] = useState(false);
	const [open, setOpen] = useState(false);

	// Wait for load
	if (!firebase.auth.isLoaded || !firebase.profile.isLoaded) {
		return <LinearProgress />;
	}

	// Handle Error
	if (!role) {
		return (
			<ErrorGroupNotFound variant="role" redirect={`/group/${groupId}/`} />
		);
	}

	const users = role?.users
		? Object.keys(role?.users).map((user: any) => ({
				...role.users[user],
				userId: user,
		  }))
		: [];

	return (
		<>
			{/* Breadcrumbs */}
			<Breadcrumbs
				aria-label="breadcrumb"
				style={{
					display: "flex",
					justifyContent: "flex-end",
					padding: "1rem",
					fontWeight: 700,
				}}
			>
				<Typography color="inherit">{group?.name}</Typography>
				<Link
					onClick={() => history.push(`/group/${groupId}`)}
					color="textSecondary"
				>
					Roles
				</Link>
				<Typography color="textPrimary">{role?.name}</Typography>
			</Breadcrumbs>

			{/* Container */}
			<Container maxWidth="md" className={classes.container}>
				<Paper elevation={3} className={classes.wrapper}>
					<ListItemText
						style={{ padding: "0 1rem" }}
						primary={role.name}
						secondary={role.description}
					/>
					<Tooltip title="Edit Role">
						<IconButton
							onClick={() =>
								history.push(`/group/${groupId}/role/${roleId}/edit`)
							}
						>
							<Edit />
						</IconButton>
					</Tooltip>
				</Paper>

				{/* Permissions */}
				<Paper elevation={3} className={classes.wrapper}>
					<List style={{ width: "100%" }}>
						<ListItem>
							<ListItemText
								primary="Permissions"
								secondary={`${role?.permissions?.length} Device Permissions`}
							/>
							<ListItemSecondaryAction>
								<Tooltip title="Edit Permissions">
									<IconButton>
										<Edit />
									</IconButton>
								</Tooltip>
								<Tooltip title="Expand">
									<IconButton
										onClick={() => setExpandPermissions(!expandPermissions)}
										disabled={Boolean(!role || !role.permissions?.length)}
									>
										<KeyboardArrowDown
											className={clsx({
												[classes.expanded]: expandPermissions,
												[classes.closed]: !expandPermissions,
											})}
										/>
									</IconButton>
								</Tooltip>
							</ListItemSecondaryAction>
						</ListItem>

						<Collapse in={expandPermissions}>
							<PermissionList permissions={role?.permissions} />
						</Collapse>
					</List>
				</Paper>

				{/* Users */}
				<Paper elevation={3} className={classes.wrapper}>
					<List style={{ width: "100%" }}>
						<ListItem>
							<ListItemText
								primary="Users"
								secondary={`${users.length} Users`}
							/>
							<ListItemSecondaryAction>
								<Tooltip title="Add User">
									<IconButton onClick={() => setOpen(true)}>
										<PersonAdd />
									</IconButton>
								</Tooltip>
								<Tooltip title="Expand">
									<span>
										<IconButton
											onClick={() => setExpandUsers(!expandUsers)}
											disabled={Boolean(!role || !users.length)}
										>
											<KeyboardArrowDown
												className={clsx({
													[classes.expanded]: expandUsers,
													[classes.closed]: !expandUsers,
												})}
											/>
										</IconButton>
									</span>
								</Tooltip>
							</ListItemSecondaryAction>
						</ListItem>

						<Collapse in={Boolean(expandUsers && users)}>
							<UserList users={users} />
						</Collapse>
					</List>
				</Paper>
			</Container>

			{/* Add user Dialog */}
			<AddUserDialog
				open={open}
				role={role}
				handleClose={() => setOpen(false)}
			/>
		</>
	);
};

export default GroupRole;
