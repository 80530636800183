import React, { ReactElement, useState } from "react";

import {
	Breadcrumbs,
	Button,
	IconButton,
	Link,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Typography,
} from "@material-ui/core";

import { Delete } from "@material-ui/icons";

import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { useHistory, useParams } from "react-router";

import useGroupSelector from "../../hooks/useGroupSelector";

interface Props {}

export default function EditRole(): ReactElement {
	// State
	const [warn, setWarn] = useState(false);
	const uid = useSelector((state: any) => state.firebase.auth?.uid);

	const params: { groupId: string; roleId: string } = useParams();

	const groupId = params?.groupId;
	const roleId = params?.roleId;

	const group = useGroupSelector();
	const role = group?.roles ? group?.roles[roleId] : null;

	const history = useHistory();
	const firebase = useFirebase();

	const handleDeleteRole = () => {
		firebase
			.remove(`users/${uid}/manage/groups/${groupId}/roles/${roleId}`)
			.then((res: any) => {
				history.push(`/group/${groupId}`);
			})
			.catch((e: any) => {
				history.push(`/group/${groupId}`);
			});
	};

	return (
		<>
			{/* Breadcrumbs */}
			<Breadcrumbs
				aria-label="breadcrumb"
				style={{
					display: "flex",
					justifyContent: "flex-end",
					padding: "1rem",
				}}
			>
				<Link color="inherit" onClick={() => history.push(`/group/${groupId}`)}>
					{group?.name}
				</Link>
				<Link
					color="textPrimary"
					onClick={() => history.push(`/group/${groupId}/roles`)}
				>
					Roles
				</Link>
				<Link
					color="inherit"
					onClick={() => history.push(`/group/${groupId}/role/${roleId}`)}
				>
					{role?.name}
				</Link>
				<Typography color="textPrimary">Editing</Typography>
			</Breadcrumbs>

			<List style={{ width: "100%" }}>
				<ListItem>
					<ListItemText
						primary="Delete Role"
						secondary={
							warn &&
							"Warning! This is permanent and will affect all users in this role"
						}
					/>
					<ListItemSecondaryAction>
						{!warn ? (
							<IconButton onClick={() => setWarn(true)}>
								<Delete />
							</IconButton>
						) : (
							<>
								<Button onClick={() => setWarn(false)}>Cancel</Button>
								<Button onClick={() => handleDeleteRole()}>Delete Role</Button>
							</>
						)}
					</ListItemSecondaryAction>
				</ListItem>
			</List>
		</>
	);
}
