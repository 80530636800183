import { createContext } from "react";

// Models
export const MClient = {
	id: "",
	name: "",
	sites: [],
};

export const MSite = {
	id: "",
	name: "",
	buildings: [
		{
			id: "",
		},
	],
};

export const MBuilding = {
	id: "",
	name: "",
	siteId: "",
	city: "",
	address: "",
	zip: "",
	contacts: [],
	floors: [
		{
			id: "",
			spaces: [],
		},
	],
	spaces: [],
	devices: [],
};

export const MFloor = {
	id: "",
	spaceId: "",
	devices: [],
	spaces: [],
};

export const MSpace = {
	id: "",
	devices: [],
};

// DataContext to appease TS
export const DataContext = createContext({
	clients: [],
	setClients: (clients: any) => {},
	buildings: {},
	setBuildings: (buildings: any) => {},
	site: MSite,
	setSite: (site: any) => {},
	building: MBuilding,
	setBuilding: (building: any) => {},
	floor: MFloor,
	setFloor: (floor: any) => {},
	space: MSpace,
	setSpace: (space: any) => {},
});
