import * as React from "react";

// MUI
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Button,
	Dialog,
	IconButton,
	Typography,
	Divider,
	List,
	ListItem,
	ListItemText,
	LinearProgress,
} from "@material-ui/core";

import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

// MUI Styles
import {
	createStyles,
	Theme,
	withStyles,
	WithStyles,
} from "@material-ui/core/styles";

// MUI Icons
import { Close, ExpandMore } from "@material-ui/icons";

// Interfaces
import { IGroupData, IRoleUser, ITableRow } from "../../../interfaces";

// Util
import { compact, flatten } from "lodash";
import { useSnackbar } from "notistack";

// Firebase
import { functions } from "../../../firebase/firebase";
import firebase from "firebase";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";

// Callable fns
const deleteUsers = functions.httpsCallable("deleteUsers");
const deleteGroup = functions.httpsCallable("deleteGroup");

// Styles
const styles = (theme: Theme) =>
	createStyles({
		root: {
			margin: 0,
			padding: theme.spacing(2),
		},
		closeButton: {
			position: "absolute",
			right: theme.spacing(1),
			top: theme.spacing(1),
			color: theme.palette.grey[500],
		},
	});

export interface DialogTitleProps extends WithStyles<typeof styles> {
	id: string;
	children: React.ReactNode;
	onClose: () => void;
	disabled: boolean;
}

// Components
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
	const { children, classes, onClose, disabled, ...other } = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{children}</Typography>
			{onClose ? (
				<IconButton
					aria-label="close"
					className={classes.closeButton}
					onClick={onClose}
					disabled={disabled}
				>
					<Close />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

const DialogContent = withStyles((theme: Theme) => ({
	root: {
		padding: theme.spacing(2),
	},
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
	root: {
		margin: 0,
		padding: theme.spacing(1),
	},
}))(MuiDialogActions);

const Role = ({ role, disabled }: { role: ITableRow; disabled: boolean }) => {
	const usersArray: IRoleUser[] =
		(role?.users &&
			Object.keys(role.users).map((k: string) => ({
				...role.users[k],
				userId: k,
			}))) ||
		[];

	return (
		<div>
			<Accordion disabled={disabled || !Boolean(usersArray.length)}>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-controls="panel1a-content"
					id="panel1a-header"
				>
					<Typography>{`${role.name} ${
						!Boolean(usersArray.length) ? "(No Users)" : ""
					}`}</Typography>
				</AccordionSummary>
				<AccordionDetails>
					<List style={{ width: "100%" }}>
						{Boolean(usersArray.length) &&
							usersArray.map((user: IRoleUser) => {
								return (
									<ListItem key={user.api}>
										<ListItemText
											primary={`${user.firstName} ${user.lastName}`}
											secondary={user.email}
										/>
									</ListItem>
								);
							})}
					</List>
				</AccordionDetails>
			</Accordion>
		</div>
	);
};

interface IWarnDeleteDialogProps {
	open: boolean;
	handleClose: () => void;
	groupData: IGroupData;
}

export const WarnDeleteDialog: React.FC<IWarnDeleteDialogProps> = ({
	open,
	handleClose,
	groupData,
}) => {
	// State
	const [deleting, setDeleting] = React.useState(false);

	// Hooks
	const uid = useSelector((state: any) => state.firebase.auth.uid);
	const { enqueueSnackbar } = useSnackbar();
	const { groupId = "" }: { groupId: string } = useParams();
	const history = useHistory();

	// Roles
	const roleArray: ITableRow[] =
		(groupData?.roles &&
			Object.keys(groupData.roles).map((k: string) => ({
				...groupData.roles[k],
				roleId: k,
			}))) ||
		[];

	// Handlers
	const handleDelete = () => {
		setDeleting(true);
		let uidArray: string[] = [];

		// If roles exist, get a list of users from those roles

		if (roleArray.length) {
			// Get flattened, compacted array of user ids
			uidArray = flatten(
				compact(
					roleArray?.map(
						(role: ITableRow) =>
							role?.users &&
							Object.keys(role.users).map((k) => role.users[k]?.uid)
					)
				)
			);
		}

		// If users exist in the roles, delete them
		if (uidArray.length) {
			// Attempt to delete users
			let removeFromAuth = deleteUsers(uidArray)
				.then((res: any) => {
					// Display success
					if (
						res?.data &&
						!Boolean(res.data?.failureCount) &&
						Boolean(res.data?.successCount)
					) {
						enqueueSnackbar(
							`${res.data.successCount} user(s) successfully deleted.`,
							{ variant: "success" }
						);
					}

					// Warn failure
					if (res?.data && Boolean(res.data?.failureCount)) {
						enqueueSnackbar(
							`${res.data.successCount} user(s) successfully deleted, ${res.data?.failureCount} could not be deleted.`,
							{ variant: "warning" }
						);
					}

					return res.data;
				})
				.catch((e: any) => {
					enqueueSnackbar(`Failed to delete users.`, { variant: "error" });
				});

			removeFromAuth.then((res: any) => {
				return Promise.all(
					uidArray.map((id: string) => {
						firebase
							.database()
							.ref("users/" + id)
							.remove();
					})
				);
			});
		}

		// Remove Group
		const removeGroup = firebase
			.database()
			.ref(`users/${uid}/manage/groups`)
			.child(groupId)
			.remove();

		setDeleting(false);
		enqueueSnackbar(`Group Deleted`, { variant: "success" });
		removeGroup.then((res: any) => {
			history.push("/dashboard");
		});
	};

	console.log(groupId);

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="customized-dialog-title"
				open={open}
				disableBackdropClick={deleting}
				disableEscapeKeyDown={deleting}
			>
				<DialogTitle
					id="customized-dialog-title"
					onClose={handleClose}
					disabled={deleting}
				>
					{deleting ? "Deleting " : "Are you sure you want to delete "}{" "}
					{groupData?.name}
				</DialogTitle>
				<DialogContent dividers>
					{deleting ? (
						<LinearProgress style={{ width: "100%" }} />
					) : (
						<Typography gutterBottom>
							Group deletion is permanent and will affect{" "}
							{groupData ? Object.keys(groupData?.roles).length : ""} role(s).
						</Typography>
					)}
					<Divider style={{ marginBottom: "1rem" }} />

					<Typography gutterBottom>
						<b>
							All affected users will have their accounts deleted, permissions
							revoked, and will need to be recreated.
						</b>
					</Typography>

					<Divider style={{ marginBottom: "1rem" }} />

					{/* Roles */}
					{roleArray.map((role: ITableRow) => (
						<Typography gutterBottom component="div" key={role.roleId}>
							<Role role={role} disabled={deleting} />
						</Typography>
					))}
				</DialogContent>
				<DialogActions>
					<Button
						color="primary"
						onClick={handleDelete}
						disabled={Boolean(deleting)}
					>
						Confirm Group Deletion
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
};
