import React, { useContext, useEffect, useState } from "react";

// DB
import { functions } from "../../firebase/firebase";

// MUI
import { Container, makeStyles } from "@material-ui/core";

import { useSelector } from "react-redux";

// Contexts
import { DataContext } from "../../contexts";

// Pages
import CreateRole from "../../components/AddGroup/CreateRole";

// Interfaces
import { IUserPointPermission } from "../../interfaces";
import useGroupSelector from "../../hooks/useGroupSelector";

// Styles
const useStyles = makeStyles((theme) => ({
	root: {
		width: "100%",
	},
	button: {
		marginRight: theme.spacing(1),
	},
	content: {
		display: "flex",
		justifyContent: "center",
		width: "100%",
		marginTop: theme.spacing(1),
		marginBottom: theme.spacing(1),
		padding: "1rem",
	},
	wrapper: {
		display: "flex",
		justifyContent: "space-evenly",
		flexWrap: "wrap",
		width: "100%",
		marginBottom: "2rem",
	},
	buttonWrapper: {
		display: "flex",
		justifyContent: "space-evenly",
		width: "100%",
		margin: "1rem 0",
	},
	// Appbar
	menuButton: {
		marginRight: 36,
	},
}));

// Interfaces
export interface IFormValues {
	name: string;
	description: string;
}

// DB Functions
const fetchEndpoint = functions.httpsCallable("fetchEndpoint");

// Page for the /addGroup endpoint, the AddGroup stepper
export default function AddRole() {
	const classes = useStyles();

	const group = useGroupSelector();
	const selectedDevices = group?.selectedDevices;

	// Hooks (firebase)
	const firebase = useSelector((state: any) => state.firebase);

	// Hooks (control)
	const [, setLoading] = useState(false);

	// Step 2 State
	const [assignedPermissions, setAssignedPermissions] = useState<
		IUserPointPermission[]
	>([]);
	const [roleName, setRoleName] = useState("");

	// Context
	const { clients, setClients } = useContext(DataContext);

	// Handlers (Step 1: Role)
	const handleRoleName = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRoleName(event.target.value);
	};

	// Get data
	useEffect(() => {
		if (firebase.profile?.api && !clients.length) {
			setLoading(true);

			fetchEndpoint({
				route: "clients",
				api: firebase.profile.api,
			})
				.then((res: any) => {
					setClients(JSON.parse(res.data));
					setLoading(false);
				})
				.catch((e: any) => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line
	}, [firebase.profile]);

	return (
		<div className={classes.root}>
			<Container
				maxWidth="md"
				style={{
					justifyContent: "flex-end",
					position: "relative",
					marginTop: "1rem",
				}}
			>
				<div className={classes.content}>
					<CreateRole
						roleName={roleName}
						setRoleName={setRoleName}
						handleRoleName={handleRoleName}
						selectedDevices={selectedDevices}
						assignedPermissions={assignedPermissions}
						setAssignedPermissions={setAssignedPermissions}
					/>
				</div>
			</Container>
		</div>
	);
}
