import React, { ReactElement } from "react";

// MUI
import {
	Card,
	CardActions,
	CardHeader,
	CardContent,
	Container,
	Grid,
	makeStyles,
	Theme,
	TextField,
	Button,
	LinearProgress,
} from "@material-ui/core";

// Hooks
import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";
import { useSnackbar } from "notistack";

// Util
import { isMatch } from "lodash";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
	},
	root: {
		"& .MuiTextField-root": {
			margin: theme.spacing(1),
		},
	},
}));

interface IProfile {
	firstName: string;
	lastName: string;
	contactEmail: string;
}

export default function DashboardProfile(): ReactElement {
	// Hooks
	const classes = useStyles();
	const firebase = useFirebase();
	const profile = useSelector((state: any) => state.firebase.profile);

	// Snackbar
	const { enqueueSnackbar } = useSnackbar();

	// State
	const [form, setForm] = React.useState<IProfile>({
		firstName: profile.firstName || "",
		lastName: profile.lastName || "",
		contactEmail: profile.contactEmail || "",
	});

	// Update state automatically with database
	React.useEffect(() => {
		setForm({
			firstName: profile.firstName,
			lastName: profile.lastName,
			contactEmail: profile.contactEmail,
		});
	}, [profile]);

	// Linear Progress if no profile for now
	// TODO: Build out standard no access page
	if (!profile || Boolean(!profile?.isLoaded)) return <LinearProgress />;

	// Handle Textfields
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const {
			target: { name, value },
		} = event;

		setForm((profile: IProfile) => ({ ...profile, [name]: value }));
	};

	// Handle Database
	const handleUpdate = () => {
		const updateProfile = firebase.updateProfile({ ...form });
		updateProfile.then((snapshot: any) => {
			if (snapshot.exists()) {
				enqueueSnackbar("Update Successful", {
					variant: "success",
				});
			} else {
				enqueueSnackbar(
					"Update failed, check your internet connection and try again",
					{
						variant: "error",
					}
				);
			}
		});
	};

	const handleRevert = () => {
		setForm({
			firstName: profile.firstName,
			lastName: profile.lastName,
			contactEmail: profile.contactEmail,
		});
		enqueueSnackbar("Profile Changes Canceled", {
			variant: "info",
		});
	};

	return (
		<Container className={classes.container}>
			<Grid container>
				<Grid item xs={12} sm={12} md={8}>
					<Card>
						<CardHeader
							title="Edit Profile"
							subheader={
								<TextField
									defaultValue={profile.email}
									id="company-disabled"
									fullWidth
									disabled
								/>
							}
						/>

						<CardContent>
							<form className={classes.root} noValidate autoComplete="off">
								{/* Email */}
								<Grid container>
									<Grid item xs={12}>
										<TextField
											label="Contact Email"
											id="contact-email"
											name="contactEmail"
											value={form.contactEmail || ""}
											onChange={handleChange}
											fullWidth
										/>
									</Grid>
								</Grid>

								{/* Name */}
								<Grid container>
									<Grid item xs={4} style={{ paddingRight: "1rem" }}>
										<TextField
											label="First Name"
											id="first-name"
											name="firstName"
											value={form.firstName || ""}
											onChange={handleChange}
											fullWidth
										/>
									</Grid>
									<Grid item xs={8}>
										<TextField
											label="Last Name"
											id="last-name"
											name="lastName"
											value={form.lastName || ""}
											onChange={handleChange}
											fullWidth
										/>
									</Grid>
								</Grid>
							</form>
						</CardContent>

						<CardActions style={{ justifyContent: "space-between" }}>
							<Button
								color="default"
								onClick={handleRevert}
								disabled={isMatch(profile, form) || !Boolean(profile.isLoaded)}
							>
								Cancel
							</Button>
							<Button
								color="primary"
								variant="contained"
								onClick={handleUpdate}
								disabled={isMatch(profile, form) || !Boolean(profile.isLoaded)}
							>
								Update Profile
							</Button>
						</CardActions>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
}
