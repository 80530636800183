import React, { ReactElement, useEffect, useState } from "react";

// MUI
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
} from "@material-ui/core";

// Packages
import { SketchPicker } from "react-color";

export interface IPickerDialog {
	open: boolean;
	color: string;
	onClose: (value: string) => void;
	variant: string;
}

export default function PickerDialog({
	open,
	color,
	onClose,
	variant,
}: IPickerDialog): ReactElement {
	const [pickerColor, setPickerColor] = useState(color);

	const handleClose = () => {
		onClose(color);
	};

	const handleCancel = () => {
		onClose("");
	};

	const handleOk = () => {
		onClose(pickerColor);
	};

	useEffect(() => {
		setPickerColor(color);
	}, [color]);

	// Picker
	interface IPickerColorObject {
		hex: string;
		hsl: { h: number; s: number; l: number; a: number };
		hsv: { h: number; s: number; v: number; a: number };
		oldHue: number;
		rgb: { r: number; g: number; b: number; a: number };
		source: string;
	}
	const handlePickColor = (color: IPickerColorObject) => {
		setPickerColor(color.hex);
	};

	const presetColors = [
		"#f44336",
		"#e91e63",
		"#9c27b0",
		"#673ab7",
		"#3f51b5",
		"#2196f3",
		"#03a9f4",
		"#00bcd4",
		"#009688",
		"#4caf50",
		"#8bc34a",
		"#cddc39",
		"#ffeb3b",
		"#ffc107",
		"#ff9800",
		"#ff5722",
	];

	return (
		<div>
			<Dialog
				onClose={handleClose}
				aria-labelledby="color-dialog"
				open={open}
				style={{ justifyContent: "center" }}
			>
				<DialogTitle id="color-dialog">Select {variant} color</DialogTitle>
				<DialogContent>
					<div
						style={{
							display: "flex",
							justifyContent: "center",
							padding: "1rem",
						}}
					>
						<SketchPicker
							presetColors={presetColors}
							style={{ width: "100%" }}
							color={pickerColor}
							onChange={handlePickColor}
						/>
					</div>
				</DialogContent>
				<Divider />
				<DialogActions
					style={{
						display: "flex",
						justifyContent: "space-between",
					}}
				>
					<Button autoFocus onClick={handleCancel}>
						Remove
					</Button>
					<Button onClick={handleOk}>Ok</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}
