import React, { ReactElement, useState } from "react";

// MUI
import {
	Button,
	Checkbox,
	Collapse,
	Container,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Slider,
	TextField,
	Theme,
} from "@material-ui/core";

// Hooks
import { useFirebase } from "react-redux-firebase";
import { useParams } from "react-router";
import useGroupSelector from "../../hooks/useGroupSelector";
import { useSelector } from "react-redux";

// Packages
import PickerDialog from "../../components/Group/Whitelabel/PickerDialog";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			padding: "1rem",
		},
		actionRoot: {
			display: "flex",
			flexDirection: "column",
			alignItems: "center",
			zIndex: -1,
		},
		pickerContainer: {
			display: "flex",
			justifyContent: "space-between",
			flexWrap: "wrap",
		},
		pickerBoxContainer: {
			height: 36,
			width: 36,
			borderRadius: 4,
			padding: 6,
		},
		pickerBox: {
			height: "100%",
			width: "100%",
			borderRadius: 4,
		},
		logoContainer: {
			display: "flex",
			alignItems: "center",
			justifyContent: "flex-end",
			height: 80,
			marginRight: "1rem",
		},
		buttonContainer: {
			marginTop: "2rem",
			display: "flex",
			justifyContent: "space-between",
		},
		removeWhitelabel: {
			color: "rgba(0, 0, 0, 0.25)",
		},
		muiSliderRoot: {
			marginLeft: "2rem",
			color: theme.palette.getContrastText(theme.palette.primary.main),
		},
	})
);

interface IColorPicker {
	color: any;
	classes: any;
}

const PickerBox = ({ color, classes }: IColorPicker) => {
	return (
		<>
			<div className={classes.pickerContainer}>
				<div
					className={classes.pickerBoxContainer}
					style={{
						border: `1px solid gray`,
					}}
				>
					<div
						className={classes.pickerBox}
						style={{
							background: color,
							border: "1px solid gray",
						}}
					/>
				</div>
			</div>
		</>
	);
};

// Render
export default function AddWhitelabel(): ReactElement {
	// Hooks
	const classes = useStyles();

	// DB
	const firebase = useFirebase();
	const params: any = useParams();
	const uid = useSelector((state: any) => state.firebase.auth?.uid);

	// Data
	const group = useGroupSelector();

	// Vars
	const dbPath = `users/${uid}/manage/groups/${params?.groupId}/whitelabel`;
	const whitelabel = group?.whitelabel;

	// State
	interface IWhitelabelState {
		enabled: boolean;
		primary: string;
		secondary: string;
		logoURL: string;
	}

	let defaultWhitelabel: any = {
		enabled: false,
		primary: whitelabel ? whitelabel.primary : "",
		secondary: whitelabel ? whitelabel.secondary : "",
		logoURL: whitelabel ? whitelabel.logoURL : "",
		padding: whitelabel && whitelabel.padding ? whitelabel.padding : 0,
	};

	const [state, setState] = useState<any>(defaultWhitelabel);

	// Picker Dialog
	const [open, setOpen] = useState(false);
	const [colorVariant, setColorVariant] = useState<string>("primary");

	// Component State
	const [addLogo, setAddLogo] = useState(Boolean(whitelabel?.logoURL));
	const [warn, setWarn] = useState(false);

	// Handlers
	// Textfields
	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setState({ ...state, logoURL: event.target.value });
	};

	const handleChangePadding = (event: any, newValue: number | number[]) => {
		setState({ ...state, padding: newValue });
	};

	const handleCommitChangePadding = (
		event: any,
		newValue: number | number[]
	) => {
		if (uid && params.groupId && whitelabel) {
			firebase.update(dbPath, {
				...state,
				enabled: true,
				padding: newValue,
			});
		}
	};

	// Dialogs (Pickers)
	const handleOpen = (variant: string) => {
		setColorVariant(variant);
		setOpen(true);
	};

	const handleClose = (color: string) => {
		setState({ ...state, [colorVariant]: color });
		if (uid && params.groupId && whitelabel) {
			firebase.update(dbPath, {
				...state,
				enabled: true,
				[colorVariant]: color,
			});
		}
		setOpen(false);
	};

	// DB
	// Logo
	const handleAddLogo = () => {
		setAddLogo(!addLogo);
	};

	const handleRemoveLogo = () => {
		firebase.update(dbPath, { logoURL: "" }).then((res: any) => {
			setState({ ...state, logoURL: "" });
			setAddLogo(false);
		});
	};

	const handleUpdateWhitelabel = () => {
		if (uid && params.groupId) {
			firebase.update(dbPath, {
				...state,
				enabled: true,
			});
		}
	};

	const handleDeleteWhitelabel = () => {
		let defaultState: any = {
			enabled: false,
			primary: "",
			secondary: "",
			logoURL: "",
			padding: 0,
		};

		// Delete from DB
		firebase
			.update(dbPath, defaultState)
			.then((res: any) => {
				setState(defaultState);
				setWarn(false);
				setAddLogo(false);
			})
			.catch((e: any) => {
				setState(defaultState);
				setWarn(false);
				setAddLogo(false);
			});
	};

	const toggleEnableWhitelabel = () => {
		if (uid && params.groupId && whitelabel) {
			firebase.update(dbPath, {
				...state,
				enabled: !Boolean(whitelabel.enabled),
			});
		}
	};

	return (
		<Container maxWidth="sm" className={classes.container}>
			<List>
				{/* Primary */}
				<ListItem button onClick={() => handleOpen("primary")}>
					<ListItemText
						primary={`Primary ${state.primary ? ": " + state.primary : ""}`}
						secondary={"Your company's primary color."}
					/>
					<ListItemSecondaryAction classes={{ root: classes.actionRoot }}>
						<PickerBox color={state.primary} classes={classes} />
					</ListItemSecondaryAction>
				</ListItem>

				{/* Secondary */}
				<ListItem button onClick={() => handleOpen("secondary")}>
					<ListItemText
						primary={`Secondary ${
							state.secondary ? ": " + state.secondary : ""
						}`}
						secondary={"Your company's secondary color."}
					/>
					<ListItemSecondaryAction classes={{ root: classes.actionRoot }}>
						<PickerBox color={state.secondary} classes={classes} />
					</ListItemSecondaryAction>
				</ListItem>
				<Divider />

				{/* Add Logo */}
				<ListItem>
					<ListItemText
						onChange={handleChange}
						id={"enable-whitelabel"}
						primary={`Add Logo`}
						secondary={`Add a URL for your brand's logo`}
					/>
					{whitelabel?.logoURL ? (
						<Button onClick={() => handleRemoveLogo()}>Remove Logo</Button>
					) : (
						<Checkbox
							edge="end"
							onChange={() => handleAddLogo()}
							checked={Boolean(addLogo)}
							inputProps={{
								"aria-labelledby": "enable-whitelabel",
							}}
						/>
					)}
				</ListItem>
				<Collapse in={Boolean(addLogo)}>
					<ListItem>
						<TextField
							disabled={Boolean(whitelabel?.logoURL)}
							onChange={handleChange}
							variant="outlined"
							fullWidth
							label="Logo URL"
							helperText="https://www.company.com/logo.png"
							value={state.logoURL}
						/>
					</ListItem>
					<ListItem>
						<ListItemText secondary="Padding" />
						<Slider
							value={state.padding}
							min={0}
							step={1}
							max={50}
							onChange={handleChangePadding}
							onChangeCommitted={handleCommitChangePadding}
							valueLabelDisplay="auto"
							aria-labelledby="padding-slider"
							classes={{ root: classes.muiSliderRoot }}
						/>
					</ListItem>
				</Collapse>

				{/* Enabled */}
				{Boolean(
					whitelabel?.primary || whitelabel?.secondary || whitelabel?.logoURL
				) && (
					<>
						<Divider />
						<ListItem>
							<ListItemText
								id={"enable-whitelabel"}
								primary={`Enable Whitelabel`}
								secondary={`Whitelabeling is ${
									whitelabel?.enabled ? "enabled" : "disabled"
								} for users in this group.`}
							/>
							<Checkbox
								edge="end"
								onChange={() => toggleEnableWhitelabel()}
								checked={Boolean(whitelabel?.enabled)}
								inputProps={{
									"aria-labelledby": "enable-whitelabel",
								}}
							/>
						</ListItem>
						<Divider />
					</>
				)}
			</List>

			<div className={classes.buttonContainer}>
				<Button
					disabled={Boolean(!whitelabel)}
					onClick={() => setWarn(true)}
					classes={{ root: classes.removeWhitelabel }}
				>
					Remove
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => handleUpdateWhitelabel()}
				>
					{whitelabel ? "Update" : "Add Whitelabel"}
				</Button>
			</div>
			{/* Dialogs */}
			<PickerDialog
				open={open}
				color={state[colorVariant]}
				onClose={handleClose}
				variant={colorVariant}
			/>

			{/* Delete Warning */}
			<Dialog
				open={warn}
				onClose={() => setWarn(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{"Delete Whitelabel?"}
				</DialogTitle>
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Warning, this action is permanent and will affect all users within
						the group. Click Delete Whitelabel to proceed.
					</DialogContentText>
				</DialogContent>
				<Divider />
				<DialogActions
					style={{
						display: "flex",
						flexWrap: "wrap",
						justifyContent: "space-between",
					}}
				>
					<Button onClick={() => setWarn(false)} autoFocus>
						Cancel
					</Button>
					<Button
						color="primary"
						onClick={() => handleDeleteWhitelabel()}
						variant="contained"
					>
						Delete Whitelabel
					</Button>
				</DialogActions>
			</Dialog>
		</Container>
	);
}
