import React, { ReactChild } from "react";

// MUI
import {
	AppBar,
	Divider,
	Drawer,
	IconButton,
	List,
	Toolbar,
	Typography,
	CssBaseline,
	makeStyles,
	Theme,
	Hidden,
	useMediaQuery,
} from "@material-ui/core";

// Icons
import { Menu, ChevronLeft, Person, ChevronRight } from "@material-ui/icons";

// Util
import clsx from "clsx";

// Hooks
import { useHistory } from "react-router";
import { useSelector } from "react-redux";

// Components
import DashboardLinks from "./Dashboard/Menus/DashboardLinks";
import ProfileLinks from "./Dashboard/Menus/ProfileLinks";

// Const
const drawerWidth = 240;

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24,
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: 0,
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
	},
	routeWrapper: {
		padding: theme.spacing(2),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
	profileIcon: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},
}));

// Page for the /dashboard endpoint
// Acts as resting place for auth state user on init
interface IDashboard {
	children: ReactChild;
}

export default function Dashboard({ children }: IDashboard) {
	const classes = useStyles();

	// State
	// 		Mobile
	const desktopLayout = useMediaQuery((theme: Theme) =>
		theme.breakpoints.up("sm")
	);

	// 		Handle Screen Size Change
	React.useEffect(() => {
		setMenuDrawerOpen(desktopLayout);
	}, [desktopLayout]);

	// 		Drawers
	const [menuDrawerOpen, setMenuDrawerOpen] = React.useState(
		desktopLayout ? true : false
	);
	const [profileDrawerOpen, setProfileDrawerOpen] = React.useState(false);

	// Hooks
	const auth = useSelector((state: any) => state.firebase.auth);
	const history = useHistory();

	// Handlers
	// 		Menu Drawer
	const handleMenuDrawer = (open: boolean) => {
		setMenuDrawerOpen(open);
	};

	// 		Menu Item Click
	const handleRoute = (val: string) => {
		history.push(val);
		// Close profile Drawer
		setProfileDrawerOpen(false);
	};

	// 		Profile Drawer
	const handleProfileDrawer = (open: boolean) => {
		// If mobile, close menu
		Boolean(!desktopLayout) && setMenuDrawerOpen(false);
		setProfileDrawerOpen(open);
	};

	// return home if not AUTH
	if (!auth.isLoaded || auth.isEmpty) {
		history.push("/");
		return null;
	}

	// Drawer Contents
	// 		Left Menu Drawer
	const MenuDrawer = (
		<div>
			<div className={classes.toolbarIcon}>
				<IconButton onClick={() => handleMenuDrawer(false)}>
					<ChevronLeft />
				</IconButton>
			</div>
			<Divider />
			<List>
				<DashboardLinks />
			</List>
			<Divider />
		</div>
	);

	// 		Right Profile Drawer
	const ProfileDrawer = (
		<div>
			<div className={classes.toolbarIcon}>
				<IconButton onClick={() => handleProfileDrawer(false)}>
					<ChevronRight />
				</IconButton>
			</div>
			<Divider />
			<List>
				<ProfileLinks handleRoute={handleRoute} />
			</List>
			<Divider />
		</div>
	);

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="absolute"
				className={clsx(classes.appBar, menuDrawerOpen && classes.appBarShift)}
			>
				<Toolbar className={classes.toolbar}>
					<IconButton
						edge="start"
						color="inherit"
						aria-label="open drawer"
						onClick={() => handleMenuDrawer(!menuDrawerOpen)}
						className={clsx(
							classes.menuButton,
							menuDrawerOpen && classes.menuButtonHidden
						)}
					>
						<Menu />
					</IconButton>
					<Typography
						component="h1"
						variant="h6"
						color="inherit"
						noWrap
						className={classes.title}
					>
						Dashboard
					</Typography>
					<div style={{ display: "flex", flexGrow: 1 }} />

					<IconButton
						className={classes.profileIcon}
						onClick={() => handleProfileDrawer(!profileDrawerOpen)}
					>
						<Person />
					</IconButton>
				</Toolbar>
			</AppBar>

			{/* Menu Drawer Mobile */}
			<Hidden smUp>
				<Drawer
					variant="temporary"
					classes={{
						paper: clsx(
							classes.drawerPaper,
							!menuDrawerOpen && classes.drawerPaperClose
						),
					}}
					open={menuDrawerOpen}
					onClose={() => handleMenuDrawer(false)}
					ModalProps={{
						keepMounted: true, // Better open performance on mobile.
					}}
				>
					{MenuDrawer}
				</Drawer>
			</Hidden>

			{/* Menu Drawer Desktop */}
			<Hidden xsDown>
				<Drawer
					variant="permanent"
					onClose={() => handleMenuDrawer(false)}
					classes={{
						paper: clsx(
							classes.drawerPaper,
							!menuDrawerOpen && classes.drawerPaperClose
						),
					}}
					open={menuDrawerOpen}
				>
					{MenuDrawer}
				</Drawer>
			</Hidden>

			{/* Main */}
			<main className={clsx(classes.content, classes.container)}>
				<div className={classes.appBarSpacer} />
				{children}
			</main>

			{/* Profile Drawer */}
			<Drawer
				anchor="right"
				variant="temporary"
				open={profileDrawerOpen}
				onClose={() => handleProfileDrawer(false)}
				ModalProps={{
					keepMounted: true, // Better open performance on mobile.
				}}
			>
				{ProfileDrawer}
			</Drawer>
		</div>
	);
}
