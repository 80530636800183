import React, { ReactElement, memo } from "react";

// MUI
import { Divider, List } from "@material-ui/core";

// Interfaces
import { IPermissionsPoint } from "../../../interfaces";
import PermissionListItem from "./PermissionListItem";

// Util
import { orderBy } from "lodash";

interface Props {
    permissions: IPermissionsPoint[];
}

const PermissionList = ({ permissions }: Props): ReactElement => {
    return (
        <List>
            <Divider />
            {orderBy(permissions, [
                "pointName",
                "buildingName",
                "floorName",
                "spaceName",
                "deviceName",
            ]).map((permission: any) => {
                return (
                    <PermissionListItem
                        key={permission.pointId}
                        permission={permission}
                    />
                );
            })}
        </List>
    );
};

export default memo(PermissionList);
