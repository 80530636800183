import React, { ReactElement, useState } from "react";

// MUI
import {
    Button,
    CircularProgress,
    Collapse,
    IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@material-ui/core";

// Icons
import { Delete } from "@material-ui/icons";

// Interfaces
import { IRoleUser } from "../../../interfaces";

interface Props {
    user: IRoleUser;
    handleRemoveUser: (user: IRoleUser) => void;
    deleting: boolean;
}

export default function ListUser({
    user,
    handleRemoveUser,
    deleting,
}: Props): ReactElement {
    // Hooks
    const [warn, setWarn] = useState(false);

    return (
        <React.Fragment>
            <ListItem style={{ display: "flex", flexWrap: "wrap" }}>
                <ListItemText
                    primary={`${user.lastName}, ${user.firstName}`}
                    secondary={user.email}
                />

                <ListItemSecondaryAction>
                    {/* Delete Button */}
                    <Collapse
                        in={!warn}
                        style={{
                            display: "flex",
                            flexDirection: "row-reverse",
                        }}>
                        <IconButton
                            edge="end"
                            aria-label="delete"
                            onClick={() => setWarn(true)}
                            disabled={deleting}>
                            <Delete />
                        </IconButton>
                    </Collapse>{" "}
                    <Collapse in={warn}>
                        <div>
                            <Button
                                style={{ marginRight: ".5rem" }}
                                onClick={() => setWarn(false)}
                                disabled={deleting}>
                                Cancel
                            </Button>
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => handleRemoveUser(user)}
                                disabled={deleting}>
                                {deleting ? (
                                    <CircularProgress size={20} />
                                ) : (
                                    "Delete"
                                )}
                            </Button>
                        </div>
                    </Collapse>
                </ListItemSecondaryAction>
            </ListItem>
            {warn && (
                <Typography color="error" variant="body1" align="center">
                    Warning: This is act is permanent and will block this user.
                </Typography>
            )}
        </React.Fragment>
    );
}
