import * as React from "react";
import { Route, Redirect, Switch } from "react-router-dom";

// Pages
import Auth from "../pages/Auth";

// Layouts
import DashboardLayout from "../pages/DashboardLayout";
import GroupLayout from "../pages/GroupLayout";
import MonitorLayout from "../pages/MonitorLayout";

// Dashboard
import DashboardGroups from "../pages/Dashboard/DashboardGroups";
import DashboardProfile from "../pages/Dashboard/DashboardProfile";
import AddGroup from "../pages/Dashboard/AddGroup";

// Groups
import AddRole from "../pages/Group/AddRole";
import GroupRole from "../pages/Group/GroupRole";
import EditGroup from "../pages/Group/EditGroup";
import EditRole from "../pages/Group/EditRole";
import Whitelabel from "../pages/Group/Whitelabel";
import GroupRoles from "../pages/Group/Roles";

// Monitor
import MonitorClients from "../pages/Monitor/MonitorClients";
import MonitorOverview from "../pages/Monitor/MonitorOverview";

// Dashboard Menu
const DashboardRoutes = () => (
	<DashboardLayout>
		<Switch>
			{/* Default Route: Groups */}
			<Route exact path="/dashboard/groups" component={DashboardGroups} />
			<Redirect exact from="/dashboard" to="/dashboard/groups" />

			{/* Group Routes */}
			<Route exact path="/dashboard/groups/add" component={AddGroup} />
			<Route exact path="/dashboard/profile" component={DashboardProfile} />

			{/* Monitor Routes */}
			<Route exact path="/dashboard/monitor" component={MonitorOverview} />

			{/* Default to menu /groups */}
		</Switch>
	</DashboardLayout>
);

const GroupRoutes = () => (
	<GroupLayout>
		<Switch>
			{/* Default Route */}
			<Route exact path="/group/:groupId/roles" component={GroupRoles} />

			{/* Groups */}
			<Route exact path="/group/:groupId/editGroup" component={EditGroup} />
			<Route exact path="/group/:groupId/addRole" component={AddRole} />
			<Route exact path="/group/:groupId/whitelabel" component={Whitelabel} />
			{/* Group Roles */}
			<Route exact path="/group/:groupId/role/:roleId" component={GroupRole} />
			<Route
				exact
				path="/group/:groupId/role/:roleId/edit"
				component={EditRole}
			/>

			{/* Default to menu /roles */}
			<Redirect exact from="/group/:groupId" to="/group/:groupId/roles" />
		</Switch>
	</GroupLayout>
);

const MonitorRoutes = () => (
	<MonitorLayout>
		<Switch>
			{/* Default Route */}
			<Route exact path="/monitor/overview" component={MonitorOverview} />
			<Route exact path="/monitor/clients" component={MonitorClients} />

			<Redirect exact from="/monitor" to="/dashboard/monitor" />
		</Switch>
	</MonitorLayout>
);

const Routes = () => {
	return (
		<Switch>
			{/* Root */}
			{/* Global Routes */}
			<Route exact path="/" component={Auth} />

			{/* Dashboard Routes */}
			<Route path="/dashboard" component={DashboardRoutes} />

			{/* Group Routes */}
			<Route path="/group/:groupId" component={GroupRoutes} />

			{/* Monitor Routes */}
			<Route path="/monitor" component={MonitorRoutes} />
		</Switch>
	);
};

export default Routes;
