import React, { useEffect, useState } from "react";

// Firebase
import { functions } from "../../../firebase/firebase";

// MUI
import { CircularProgress } from "@material-ui/core";

// Components
import { Tree } from "antd";

// Styles
import "antd/dist/antd.css";

// Interfaces
import {
	IBuilding,
	IDashboardClient,
	ITreeDevice,
	ITreeNodeDevice,
} from "../../../interfaces";
import { useSelector } from "react-redux";

// Util
import { sortBy } from "lodash";

// Call DB
const fetchEndpoint = functions.httpsCallable("fetchEndpoint");
const getBuilding = (id: string, api: string) => {
	return fetchEndpoint({ api, route: `buildings/${id}` })
		.then((res) => JSON.parse(res.data))
		.catch((e) => {
			return e;
		});
};

// Mutate Data to Tree
const createTreeData = (data: IDashboardClient[] = []) => {
	// Map through clients (array of clients from endpoint:clients)
	return sortBy(data, (c: any) => c.client.name).map((d) => {
		const { client } = d;
		console.log(d);
		// Map through clients and build out children (sites) and grandchildren (sites/buildings)
		const children = client?.sites?.map((s) => {
			// Map through sites and build children out of buildings
			const grandChildren = s.buildings.map((b) => {
				return {
					checkable: false,
					key: b.id,
					title: b.name,
					type: "building",
					// Parent Data
					client: client.id,
					clientName: client.name,
					site: s.id,
					siteName: s.name,
					buildingName: b.name,
					// children (loaded async)
					children: [],
				};
			});

			// Site
			return {
				checkable: false,
				key: s.id,
				title: s.name,
				type: "site",
				// Parent Data
				client: client.id,
				clientName: client.name,
				children: grandChildren,
			};
		});

		// Client
		return {
			checkable: false,
			key: client.id,
			title: client.name,
			type: "client",
			// Parent Data
			client: client.id,
			clientName: client.name,
			children: children,
		};
	});
};

interface IDeviceTree {
	data: IDashboardClient[] | [];
	checkedKeys: string[] | [];
	setCheckedKeys: (keys: string[] | []) => void;
	expandedKeys: string[] | [];
	setExpandedKeys: (keys: string[] | []) => void;
	selectedDevices: any[];
	setSelectedDevices: (devices: any | any[]) => void;
}

/*
  DeviceTree is a tree of IDashboardClients with option to load buildings
  data:                 //array of [{client: {}}, ...] from /clients endpoint
  checkedKeys:          //array of [string] to autoCheck previously checked keys
  setCheckedKeys:       //handler for above
  expandedKeys:         //array of [string] to autoExpand previously expanded keys
  setExpandedKeys:      //handler for above
  setSelectedDevices:   //select devices to assign roles to

*/
export function DeviceTree({
	data,
	checkedKeys,
	setCheckedKeys,
	expandedKeys,
	setExpandedKeys,
	setSelectedDevices,
}: IDeviceTree) {
	// Redux State
	const api = useSelector((state: any) => state.firebase.profile?.api);

	// Tree State
	const [autoExpandParent, setAutoExpandParent] = useState<boolean>(true);
	const [treeData, setTreeData] = useState<any>([]);

	// Initial data load
	useEffect(() => {
		if (!treeData.length) {
			setTreeData(createTreeData(data));
		}
		if (checkedKeys.length || expandedKeys.length) {
			setCheckedKeys([...checkedKeys]);
			setExpandedKeys([...expandedKeys]);
		}
		// eslint-disable-next-line
	}, [api, data]);

	// Tree controls
	const onExpand = (expandedKeys: any) => {
		// if not set autoExpandParent to false, if children expanded, parent can not collapse.
		// or, you can remove all expanded children keys.
		setExpandedKeys(expandedKeys);
		setAutoExpandParent(false);
	};

	const onCheck = (checkedKeys: any, e: any) => {
		const devices = e.checkedNodes.filter((n: any) => n.type === "device");
		setSelectedDevices(devices);
		setCheckedKeys(checkedKeys);
	};

	// Load building data from client>site>building expand
	const onLoadData: any = (treeNode: any) => {
		// Only fire if building
		return new Promise((resolve: any) => {
			if (treeNode.type !== "building") {
				resolve();
				return;
			}

			// Get ID for fetch
			const id = treeNode.key;

			// Fetch, promise returns buildings
			const building = getBuilding(id, api)
				.then((res) => {
					// Occasional glitch where multiple buildings returned, so filter
					let building = res;

					if (Array.isArray(building)) {
						building = res.filter((b: IBuilding) => b.id === id)[0];
					}

					return building.floors.map((floor: any) => {
						// Generate Tree
						return {
							type: "floor",
							title: floor.name,
							key: floor.id,
							// Children are the building.floors
							children: floor.spaces.map((space: any) => {
								return {
									type: "space",
									title: space.name,
									key: space.id,
									// Children are the floors.spaces
									children: space.devices.map((device: ITreeNodeDevice) => {
										console.log(device);
										const treeDevice: ITreeDevice = {
											// Tree
											key: device.id, //string
											title: device.name, //string
											type: "device", //string
											checkable: true, //bool
											isLeaf: true, //bool
											// App Data
											// Quirky, must add clients from treeNode client as it doesn't exist on building
											client: treeNode.client, //string
											clientName: treeNode.clientName, //string
											site: treeNode.site, //string
											siteName: treeNode.siteName, //string
											buildingName: treeNode.buildingName, //string
											building: id, //string
											floor: floor.id, //string
											floorName: floor.name, //string
											space: space.id, //string
											spaceName: space.name, //string
											device: device.id, //string
											deviceName: device.name, //string
											points: device.points, //IPoint[]
											variant: device.virtualProfile || "",
										};

										return treeDevice;
									}),
								};
							}),
						};
					});
				})
				.catch((e) => {
					console.log(e);
					return e;
				});

			// Build the new tree with the additional data
			const buildTree = building
				.then((tree) => {
					// tree is new branch created above, update children
					treeNode.children = [...treeNode.children, ...tree];

					// Create copy to force rerender
					const treeDataCopy = [...treeData];

					// Chart a path through tree hierarchy
					const clientIndex = treeData.findIndex(
						(c: any) => c.key === treeNode.client
					);
					const siteIndex = treeData[clientIndex].children.findIndex(
						(s: any) => s.key === treeNode.site
					);
					const buildingIndex = treeData[clientIndex].children[
						siteIndex
					].children.findIndex((b: any) => b.key === treeNode.key);

					// Add loaded data
					treeDataCopy[clientIndex].children[siteIndex].children[
						buildingIndex
					] = treeNode;

					// Set new data to force rerender
					setTreeData(treeDataCopy);
				})
				.catch((e) => {
					return e;
				});

			// Build tree and resolve promise
			return buildTree.then((res) => {
				resolve();
			});
		});
	};

	// If clients fetch hasn't loaded yet, show circular progress
	if (!data.length) {
		return <CircularProgress size={20} />;
	}

	return (
		<div style={{ width: "100%" }}>
			<Tree
				checkable
				selectable={false}
				showIcon
				defaultExpandParent
				onExpand={onExpand}
				expandedKeys={expandedKeys}
				defaultExpandedKeys={expandedKeys}
				autoExpandParent={autoExpandParent}
				onCheck={onCheck}
				checkedKeys={checkedKeys}
				loadData={onLoadData}
				treeData={treeData}
			/>
		</div>
	);
}
