// Hooks
import { useSelector } from "react-redux";
import { useFirebaseConnect } from "react-redux-firebase";
import { useParams } from "react-router";

export default function useGroupSelector() {
    // Hooks
    const firebase = useSelector((state: any) => state.firebase);
    const { groupId }: any = useParams();

    // Firebase realtime connection
    const uid = firebase.auth.uid;
    const dbString = `users/${uid}/manage/groups/${groupId}`;
    useFirebaseConnect([dbString]);

    // Firebase groups from rrf
    const groupData = useSelector(
        (state: any) =>
            state.firebase.data?.users?.[uid]?.manage?.groups?.[groupId]
    );

    return { ...groupData, groupId };
}
