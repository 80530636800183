//@ts-nocheck

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// Main
import App from "./components/App";

// SW
import * as serviceWorker from "./serviceWorker";

// DB Configs
import firebase from "firebase/app";
import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";

// MUI
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";

// Snackbars
import { SnackbarProvider } from "notistack";

// Google Analytics
// import ReactGA from "react-ga";

// Initialize GA
/* 

DEV: UA- string as first param

*/
// const initializeReactGA = () => {
//     ReactGA.initialize("", {
//       gaOptions: {
//         siteSpeedSampleRate: 100
//       }
//     });
//     ReactGA.pageview(window.location.pathname + window.location.search);
//   };

import configureStore from "./store";

export const store = configureStore();

const rrfConfig = {
	userProfile: "users",
	fileMetadataFactory: (
		uploadRes: { metadata: { name: string; fullPath: string } },
		firebase: any,
		metadata: { name: string; fullPath: string },
		downloadURL: string
	) => {
		// Generate URL, path, and name for all files uploaded to storage;
		// Store in DB
		const {
			metadata: { name, fullPath },
		} = uploadRes;

		return {
			name,
			fullPath,
			downloadURL,
		};
	},
};

const rrfProps = {
	firebase,
	config: rrfConfig,
	dispatch: store.dispatch,
	createFirestoreInstance,
};

// Initialize GA
/* 

    DEV: Uncomment to enable Google Analytics

*/
// initializeReactGA();

const theme = createMuiTheme();

ReactDOM.render(
	<Provider store={store}>
		<ReactReduxFirebaseProvider {...rrfProps}>
			<MuiThemeProvider theme={theme}>
				<SnackbarProvider>
					<App />
				</SnackbarProvider>
			</MuiThemeProvider>
		</ReactReduxFirebaseProvider>
	</Provider>,
	document.getElementById("root")
);

// SW Enables Offline Services
serviceWorker.unregister();
