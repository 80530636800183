import React, { memo, ReactElement } from "react";

// MUI
import {
    Button,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
} from "@material-ui/core";

interface Props {
    user: any;
    manageUser: (user: any) => void;
}

const UserListItem = ({ user, manageUser }: Props): ReactElement => {
    return (
        <ListItem>
            <ListItemText
                primary={`${user.firstName} ${user.lastName}`}
                secondary={user.email}
            />
            <ListItemSecondaryAction>
                <Button onClick={() => manageUser(user)}>Manage</Button>
            </ListItemSecondaryAction>
        </ListItem>
    );
};

export default memo(UserListItem);
