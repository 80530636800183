import React, { ReactElement } from "react";

// MUI
import {
	Divider,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Theme,
} from "@material-ui/core";

// Icons
import {
	People,
	CreateNewFolder,
	Edit,
	ChevronLeft,
	AddPhotoAlternate,
	Check,
} from "@material-ui/icons";

// Util
import clsx from "clsx";
import { useHistory, useLocation, useParams } from "react-router";

interface Props {
	group: any;
}

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	active: {
		color: theme.palette.getContrastText(theme.palette.background.paper),
		fontWeight: 700,
	},
	rolesOpen: {
		transform: "rotate(-180deg)",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	rolesClosed: {
		transform: "rotate(0)",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function GroupLinks({ group }: Props): ReactElement {
	// Styles
	const classes = useStyles();

	// Hooks
	const history = useHistory();
	const { pathname } = useLocation();
	const params: any = useParams();

	// Path IDs
	const groupId = params?.groupId;

	const isActive = (test: string) => {
		const path = pathname.slice(pathname.lastIndexOf(groupId), pathname.length);
		return path.includes(`/${test}`);
	};

	const whitelabelEnabled = group?.whitelabel?.enabled;

	return (
		<div>
			<ListItem>
				<ListItemText primary="Group Menu" secondary="Navigate your group" />
			</ListItem>
			<ListItem button onClick={() => history.push(`/group/${groupId}/roles`)}>
				<ListItemIcon>
					<People
						className={clsx({
							[classes.active]: isActive("roles"),
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Roles"
					classes={{
						primary: clsx({
							[classes.active]: isActive("roles"),
						}),
					}}
				/>
			</ListItem>

			<Divider />

			<ListItem>
				<ListItemText
					primary="Group Actions"
					secondary="Manage your group settings"
				/>
			</ListItem>

			<ListItem
				button
				onClick={() => history.push(`/group/${groupId}/addRole`)}
			>
				<ListItemIcon>
					<CreateNewFolder
						className={clsx({
							[classes.active]: isActive("addRole"),
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Create Role"
					classes={{
						primary: clsx({
							[classes.active]: isActive("addRole"),
						}),
					}}
				/>
			</ListItem>
			<ListItem
				button
				onClick={() => history.push(`/group/${groupId}/whitelabel`)}
			>
				<ListItemIcon
					className={clsx({
						[classes.active]: isActive("whitelabel"),
					})}
				>
					<AddPhotoAlternate />
				</ListItemIcon>
				<ListItemText
					primary="Whitelabel"
					classes={{
						primary: clsx({
							[classes.active]: isActive("whitelabel"),
						}),
					}}
				/>
				{whitelabelEnabled && (
					<ListItemSecondaryAction style={{ zIndex: -1 }}>
						<Check style={{ fontSize: "1rem", zIndex: -1 }} />
					</ListItemSecondaryAction>
				)}
			</ListItem>
			<ListItem
				button
				onClick={() => history.push(`/group/${groupId}/editGroup`)}
			>
				<ListItemIcon
					className={clsx({
						[classes.active]: isActive("editGroup"),
					})}
				>
					<Edit />
				</ListItemIcon>
				<ListItemText
					primary="Edit Group"
					classes={{
						primary: clsx({
							[classes.active]: isActive("editGroup"),
						}),
					}}
				/>
			</ListItem>
			<Divider />
			<ListItem
				button
				onClick={() => history.push(`/dashboard`)}
				style={{ marginTop: "1rem" }}
			>
				<ListItemIcon>
					<ChevronLeft />
				</ListItemIcon>
				<ListItemText primary="Dashboard" />
			</ListItem>
		</div>
	);
}
