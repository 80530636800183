import * as React from "react";

// MUI
import {
	Collapse,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	makeStyles,
	Paper,
	Theme,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	aqContainer: {
		padding: "1rem",
		boxShadow: (props: any) => `inset 0px 0px 5px 0px ${props.aqColor}`,
		webkitBoxShadow: "inset 0px 0px 5px 0px #72727255",
	},
	aqWrapper: {
		display: "flex",
		flexWrap: "wrap",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
	},
	center: {
		display: "flex",
		justifyContent: "center",
	},
}));

interface IAirQualityMetadata {
	airQuality: any;
	condensed?: boolean;
}

export const AirQualityMetadata: React.FC<IAirQualityMetadata> = ({
	airQuality,
	condensed,
}) => {
	const { ave = "", high = "", low = "", count = "" } = airQuality;

	const aqColor =
		Number(ave) < 50
			? "#f4433655"
			: Number(ave) < 75
			? "#ffc10755"
			: "#8bc34a55";

	const classes = useStyles({ aqColor });

	// Mobile
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	return (
		<Paper elevation={0}>
			<div>
				<Paper
					className={classes.aqContainer}
					style={{
						margin: mobile ? ".5rem" : "1rem",
						padding: condensed ? 0 : "1rem",
					}}
				>
					<Collapse in={!condensed}>
						<Grid container style={{ paddingBottom: "1rem" }}>
							<Grid item xs={12} style={{ paddingBottom: "1rem" }}>
								<Typography variant="h6" align="center">
									Air Quality
								</Typography>
							</Grid>
							<Grid item xs={4} className={classes.aqWrapper}>
								<Typography variant="overline" color="textSecondary">
									Low
								</Typography>
								<Typography variant="h6">{Math.round(Number(ave))}</Typography>
							</Grid>
							<Grid item xs={4} className={classes.aqWrapper}>
								<Typography variant="overline" color="textPrimary">
									Ave
								</Typography>
								<Typography variant="h4">{Math.round(Number(low))}</Typography>
							</Grid>
							<Grid item xs={4} className={classes.aqWrapper}>
								<Typography variant="overline" color="textSecondary">
									High
								</Typography>
								<Typography variant="h6">{Math.round(Number(high))}</Typography>
							</Grid>
						</Grid>
						<Typography
							variant="caption"
							color="textSecondary"
							align="center"
							className={classes.center}
						>
							Across {count} devices in the building
						</Typography>
					</Collapse>

					<Collapse in={condensed}>
						<List>
							<ListItem>
								<ListItemText
									primary="Air Quality"
									secondary={`${count} devices`}
								/>
								<ListItemSecondaryAction style={{ textAlign: "center" }}>
									<Typography
										variant="overline"
										color="textPrimary"
										align="center"
									>
										Ave
									</Typography>
									<Typography variant="h4" align="center">
										{Math.round(Number(low))}
									</Typography>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
					</Collapse>
				</Paper>
			</div>
		</Paper>
	);
};
