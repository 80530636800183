import React, { ReactElement } from "react";

// MUI
import {
	Button,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Theme,
} from "@material-ui/core";

// Icons
import { AccountCircle } from "@material-ui/icons";

// Util
import clsx from "clsx";
import { useLocation } from "react-router";
import { firebase } from "../../../firebase";

interface Props {
	activeLink: string;
	handleActiveLink: (val: string) => void;
}

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	active: {
		color: theme.palette.primary.main,
		fontWeight: 700,
	},
}));

// Add Group

interface IProfileLinks {
	handleRoute: (route: string) => void;
}

export default function ProfileLinks({
	handleRoute,
}: IProfileLinks): ReactElement {
	// Styles
	const classes = useStyles();

	const { pathname } = useLocation();

	return (
		<div>
			<ListItem>
				<ListItemText
					primary="Profile Menu"
					secondary="Navigate your personal options"
				/>
			</ListItem>

			{/* Profile Page */}
			<ListItem button onClick={() => handleRoute("/dashboard/profile")}>
				<ListItemIcon>
					<AccountCircle
						className={clsx({
							[classes.active]: pathname === "/dashboard/profile",
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Profile"
					className={clsx({
						[classes.active]: pathname === "/dashboard/profile",
					})}
				/>
			</ListItem>

			<Divider />

			<ListItem>
				<ListItemText primary="Actions" secondary="Log in and sign out" />
			</ListItem>
			<ListItem>
				<Button
					fullWidth
					color="primary"
					onClick={() => firebase.auth.signOut()}
				>
					Sign Out
				</Button>
			</ListItem>
		</div>
	);
}
