import * as React from "react";
import { useEffect } from "react";

// MUI
import {
	AppBar,
	Button,
	Card,
	CardActions,
	CardContent,
	CardHeader,
	Checkbox,
	Chip,
	createStyles,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	Grid,
	IconButton,
	makeStyles,
	Paper,
	Slider,
	Switch,
	TextField,
	Theme,
	Toolbar,
	Typography,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

import TreeView from "@material-ui/lab/TreeView";
import TreeItem, { TreeItemProps } from "@material-ui/lab/TreeItem";

// Icons
import {
	AddCircle,
	ArrowDropDown,
	ArrowRight,
	CheckCircle,
	Error,
	FilterList,
	Info,
	Warning,
} from "@material-ui/icons";

// Util
import { isEmpty } from "lodash";
import { useSnackbar } from "notistack";

// Components
import { AirQualityMetadata, TemperatureMetadata } from "./Widgets";

// Colors
import { deepOrange, green, lightBlue, orange } from "@material-ui/core/colors";
import { useSelector } from "react-redux";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
	},
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
	menuButton: {
		marginRight: theme.spacing(2),
		display: "flex",
		[theme.breakpoints.up("sm")]: {
			display: "none",
		},
	},
	title: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
		flexGrow: 1,
	},
	dialogContentRoot: {
		flexGrow: 1,
		padding: "1rem",
	},
	dialogContentWrapper: {
		padding: "1rem",
	},
	dialogCardWrapper: {
		padding: "1rem",
	},
	formControl: {
		margin: theme.spacing(3),
	},
	labelInfo: {
		display: "flex",
		alignItems: "center",
	},
	verticalAlign: {
		display: "flex",
		flexWrap: "wrap",
		alignItems: "center",
	},

	// Slider
	sliderRoot: {
		color: theme.palette.text.secondary,
	},
	sliderTrack: {
		backgroundColor: orange[300],
	},

	// Report Emails
	emailList: {
		display: "flex",
		flexWrap: "wrap",
		listStyle: "none",
		padding: ".5rem 0",
		margin: 0,
	},
	chip: {
		margin: theme.spacing(0.5),
	},
}));

declare module "csstype" {
	interface Properties {
		"--tree-view-color"?: string;
		"--tree-view-bg-color"?: string;
	}
}

type StyledTreeItemProps = TreeItemProps & {
	bgColor?: string;
	color?: string;
	labelIcon?: any;
	labelInfo?: string;
	labelText: string;
};

const useTreeItemStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			color: theme.palette.text.secondary,
			"&:hover > $content": {
				backgroundColor: theme.palette.action.hover,
			},
			"&:focus > $content, &$selected > $content": {
				backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
				color: "var(--tree-view-color)",
			},
			"&:focus > $content $label, &:hover > $content $label, &$selected > $content $label":
				{
					backgroundColor: "transparent",
				},
		},
		content: {
			color: theme.palette.text.secondary,
			borderTopRightRadius: theme.spacing(2),
			borderBottomRightRadius: theme.spacing(2),
			paddingRight: theme.spacing(1),
			fontWeight: theme.typography.fontWeightMedium,
			"$expanded > &": {
				fontWeight: theme.typography.fontWeightRegular,
			},
		},
		group: {
			marginLeft: 0,
			"& $content": {
				paddingLeft: theme.spacing(2),
			},
		},
		expanded: {},
		selected: {},
		label: {
			fontWeight: "inherit",
			color: "inherit",
		},
		labelRoot: {
			display: "flex",
			alignItems: "center",
			padding: theme.spacing(0.5, 0),
		},
		labelIcon: {
			marginLeft: theme.spacing(1),
		},
		labelText: {
			fontWeight: "inherit",
			flexGrow: 1,
		},
		labelInfo: {
			display: "flex",
			alignItems: "center",
		},
	})
);

function StyledTreeItem(props: StyledTreeItemProps) {
	const classes = useTreeItemStyles();
	const {
		labelText,
		labelIcon: LabelIcon,
		labelInfo,
		color,
		bgColor,
		...other
	} = props;

	return (
		<TreeItem
			label={
				<div className={classes.labelRoot}>
					<Typography variant="body2" className={classes.labelText}>
						{labelText}
					</Typography>
					<Typography
						variant="caption"
						color="inherit"
						className={classes.labelInfo}
					>
						{labelInfo}
						{Boolean(LabelIcon) && LabelIcon}
					</Typography>
				</div>
			}
			style={{
				"--tree-view-color": color,
				"--tree-view-bg-color": bgColor,
			}}
			classes={{
				root: classes.root,
				content: classes.content,
				expanded: classes.expanded,
				selected: classes.selected,
				group: classes.group,
				label: classes.label,
			}}
			{...other}
		/>
	);
}

//   Interface
interface IBuildingMetadataCardProps {
	building: any;
}

// Component
export const BuildingMetadataCard: React.FC<IBuildingMetadataCardProps> = ({
	building,
}) => {
	const classes = useStyles();

	// Redux
	const userEmail = useSelector((state: any) => state.firebase.auth.email);
	console.log(userEmail);

	// Mobile
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Notify
	const { enqueueSnackbar } = useSnackbar();

	const {
		metadata: { temperature = {}, airQuality = {} },
	} = building;

	// State
	const [condensed, setCondensed] = React.useState(false);

	// Details
	const [openDetails, setOpenDetails] = React.useState(false);
	const [type, setType] = React.useState({ type: "", label: "" });
	const [treeData, setTreeData] = React.useState({ children: [] });

	// Sorting and filtering
	const [status, setStatus] = React.useState([30, 70]);
	const [filter, setFilter] = React.useState({
		success: true,
		warning: true,
		error: true,
	});

	const [collapseFilter, setCollapseFilter] = React.useState(true);

	// Report
	const [generateReport, setGenerateReport] = React.useState(false);
	const [emailList, setEmailList]: any[] = React.useState([]);
	const [emailText, setEmailText] = React.useState("");

	const handleEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
		setEmailText(event.target.value);
	};

	const handleCloseReport = () => {
		setEmailText("");
		setGenerateReport(false);
	};

	const handleSubmitReport = () => {
		// Notify
		if (Boolean(emailList.length)) {
			enqueueSnackbar(
				`Report generated and successfully sent to ${emailList.length} recipients.`,
				{
					variant: "success",
				}
			);
		}

		// Revert to default
		setEmailList([]);
		setEmailText("");

		// Close
		handleCloseReport();
	};

	const handleAdd = () => {
		setEmailList([...emailList, emailText]);
		setEmailText("");
	};

	const handleDelete = (chipToDelete: any) => () => {
		setEmailList((chips: any) =>
			chips.filter((chip: any) => chip !== chipToDelete)
		);
	};

	// Slider
	const marks = [
		{
			value: status[0],
			label: (
				<Typography
					variant="caption"
					color="inherit"
					className={classes.labelInfo}
				>
					<Error
						style={{
							color: deepOrange[300],
						}}
					/>{" "}
				</Typography>
			),
		},
		{
			value: status[1],
			label: (
				<Typography
					variant="caption"
					color="inherit"
					className={classes.labelInfo}
				>
					<CheckCircle style={{ color: green[300] }} />{" "}
				</Typography>
			),
		},
	];

	const getAQIcon = (avg: string) => {
		return Number(avg) < status[0] ? (
			<Error style={{ color: deepOrange[300], marginLeft: "1rem" }} />
		) : Number(avg) < status[1] ? (
			<Warning style={{ color: orange[300], marginLeft: "1rem" }} />
		) : Number(avg) >= status[1] ? (
			<CheckCircle style={{ color: green[300], marginLeft: "1rem" }} />
		) : (
			<Info style={{ color: lightBlue[300], marginLeft: "1rem" }} />
		);
	};

	// Handlers
	const handleOpenDialog = (data: { type: string; label: string }) => {
		setOpenDetails(true);
		setType(data);
	};

	const handleStatusChange = (event: any, newValue: number | number[]) => {
		setStatus(newValue as number[]);
	};

	const handleFilterData = (event: React.ChangeEvent<HTMLInputElement>) => {
		setFilter({ ...filter, [event.target.name]: event.target.checked });
	};

	const handleCollapseFilters = () => {
		setCollapseFilter(!collapseFilter);
	};

	const handleGenerateReport = () => {
		setGenerateReport(true);
	};

	useEffect(() => {
		let data = { id: building.id, name: building.name, children: [] };

		data.children = building.floors.map((floor: any) => ({
			id: floor?.id,
			name: floor?.name,
			labelText: floor?.name,
			labelInfo: floor?.metadata?.[type.type]?.avg,
			labelIcon: getAQIcon(floor?.metadata?.[type.type]?.avg),
			children: floor?.spaces
				?.filter((space: any) => {
					if (filter.success && space.metadata?.[type.type]?.avg >= status[1]) {
						return space;
					}

					if (
						filter.warning &&
						space.metadata?.[type.type]?.avg <= status[1] &&
						space.metadata?.[type.type]?.avg >= status[0]
					) {
						return space;
					}

					if (filter.error && space.metadata?.[type.type]?.avg <= status[0]) {
						return space;
					}
				})
				.map((space: any) => ({
					id: space.id,
					name: space.name,
					labelText: space.name,
					labelInfo: space.metadata?.[type.type]?.avg,
					labelIcon: getAQIcon(space?.metadata?.[type.type]?.avg),
					children: space?.devices
						?.filter((device: any) => {
							return device.virtualProfile === "virtualAirQuality";
						})
						.map((device: any) => ({
							id: device.id,
							name: device.name,
							labelText: device.name,
							labelIcon: getAQIcon(device?.metadata?.[type.type]?.avg),
							children: [],
						})),
				})),
		}));

		setTreeData(data);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [building, type, status, filter]);

	return (
		<>
			<Card className={classes.root}>
				<CardHeader
					action={
						<Switch
							size="small"
							checked={condensed}
							onChange={() => setCondensed(!condensed)}
							color="primary"
							inputProps={{ "aria-label": "primary checkbox" }}
						/>
					}
					title={building.name}
					subheader={building.address}
				/>
				<CardContent style={{ padding: condensed ? 0 : "1rem" }}>
					<Grid container>
						<Grid item xs={12} sm={6}>
							{!Boolean(isEmpty(airQuality)) ? (
								<div
									onClick={() =>
										handleOpenDialog({
											type: "airQuality",
											label: "Air Quality",
										})
									}
								>
									<AirQualityMetadata
										airQuality={airQuality}
										condensed={condensed}
									/>
								</div>
							) : (
								<Typography
									variant="body2"
									align="center"
									color="textSecondary"
								>
									No Air Quality Metadata
								</Typography>
							)}
						</Grid>
						<Grid item xs={12} sm={6}>
							{!Boolean(isEmpty(temperature)) ? (
								<div
									onClick={() =>
										handleOpenDialog({
											type: "temperature",
											label: "Temperature",
										})
									}
								>
									<TemperatureMetadata
										temperature={temperature}
										condensed={condensed}
									/>
								</div>
							) : (
								<Typography
									variant="body2"
									align="center"
									color="textSecondary"
								>
									No Temperature Metadata
								</Typography>
							)}
						</Grid>
					</Grid>
				</CardContent>
			</Card>

			<Dialog
				open={openDetails}
				onClose={() => setOpenDetails(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				fullScreen
			>
				<AppBar position="static">
					<Toolbar>
						<Typography variant="h6" className={classes.title}>
							{building.name} {type.label} Details
						</Typography>
						<IconButton
							edge="start"
							className={classes.menuButton}
							color="inherit"
							aria-label="menu"
							onClick={handleCollapseFilters}
						>
							<FilterList />
						</IconButton>
					</Toolbar>
				</AppBar>

				<Grid container className={classes.dialogContentRoot}>
					<Grid item xs={12} sm={6} className={classes.dialogContentWrapper}>
						<TreeView
							className={classes.root}
							defaultExpanded={["3"]}
							defaultCollapseIcon={<ArrowDropDown />}
							defaultExpandIcon={<ArrowRight />}
							defaultEndIcon={<div style={{ width: 24 }} />}
						>
							{treeData.children?.map((floor: any) => {
								return (
									<StyledTreeItem
										key={`floor_${floor.id}`}
										nodeId={floor.id}
										labelText={floor.labelText}
										labelInfo={floor.labelInfo}
										labelIcon={floor.labelIcon}
										color="#e3742f"
										bgColor="#fcefe3"
									>
										{floor?.children?.map((space: any) => {
											return (
												<StyledTreeItem
													key={`space_${space.id}`}
													nodeId={space.id}
													labelText={space.labelText}
													labelInfo={space.labelInfo}
													labelIcon={space.labelIcon}
													color="#e3742f"
													bgColor="#fcefe3"
												>
													{space?.children?.map((device: any) => {
														return (
															<StyledTreeItem
																key={`device_${device.id}`}
																nodeId={device.id}
																labelText={device.labelText}
																labelInfo={device.labelInfo}
																labelIcon={device.labelIcon}
																color="#e3742f"
																bgColor="#fcefe3"
															/>
														);
													})}
												</StyledTreeItem>
											);
										})}
									</StyledTreeItem>
								);
							})}
						</TreeView>
					</Grid>

					{/* Filter Options */}
					{!mobile && (
						<Grid item xs={12} sm={6} className={classes.dialogContentWrapper}>
							<Card className={classes.dialogCardWrapper}>
								{/* Filter Header */}
								<CardHeader
									title="Filters"
									subheader="Display devices based upon status"
								/>

								{/* Device Status Slider */}
								<Slider
									value={status}
									defaultValue={status}
									onChange={handleStatusChange}
									aria-labelledby="status-slider"
									valueLabelDisplay="auto"
									min={0}
									max={100}
									classes={{
										root: classes.sliderRoot,
										track: classes.sliderTrack,
									}}
									marks={marks}
								/>

								{/* Status Filters */}
								<FormControl
									component="fieldset"
									className={classes.formControl}
								>
									<FormGroup>
										<FormControlLabel
											control={
												<Checkbox
													checked={filter.success}
													onChange={handleFilterData}
													name="success"
													color="primary"
												/>
											}
											label={
												<Typography
													variant="caption"
													color="inherit"
													className={classes.labelInfo}
												>
													Safe Air Quality
													<CheckCircle
														style={{ color: green[300], marginLeft: "1rem" }}
													/>{" "}
												</Typography>
											}
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={filter.warning}
													onChange={handleFilterData}
													name="warning"
													color="primary"
												/>
											}
											label={
												<Typography
													variant="caption"
													color="inherit"
													className={classes.labelInfo}
												>
													Air Quality Warning
													<Warning
														style={{ color: orange[300], marginLeft: "1rem" }}
													/>{" "}
												</Typography>
											}
										/>
										<FormControlLabel
											control={
												<Checkbox
													checked={filter.error}
													onChange={handleFilterData}
													name="error"
													color="primary"
												/>
											}
											label={
												<Typography
													variant="caption"
													color="inherit"
													className={classes.labelInfo}
												>
													Dangerous Air Quality
													<Error
														style={{
															color: deepOrange[300],
															marginLeft: "1rem",
														}}
													/>{" "}
												</Typography>
											}
										/>
									</FormGroup>
								</FormControl>

								<CardActions style={{ flexDirection: "row-reverse" }}>
									<Button
										size="small"
										color="primary"
										onClick={() => handleGenerateReport()}
									>
										Generate Report
									</Button>
								</CardActions>
							</Card>
						</Grid>
					)}
				</Grid>

				<DialogActions>
					<Button onClick={() => setOpenDetails(false)} color="primary">
						Close
					</Button>
				</DialogActions>
			</Dialog>

			{/* Generate Report */}
			<Dialog
				open={generateReport}
				onClose={() => setGenerateReport(false)}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					Email Report of Filtered Devices
				</DialogTitle>

				<Divider />

				<DialogContent>
					<Grid
						container
						className={classes.verticalAlign}
						style={{ margin: "1rem 0" }}
					>
						<Grid item xs={8}>
							<TextField
								autoFocus
								id="name"
								label="Email Address"
								type="email"
								value={emailText}
								onChange={handleEmail}
								fullWidth
							/>
						</Grid>
						<Grid
							item
							xs={4}
							className={classes.verticalAlign}
							style={{ justifyContent: "center" }}
						>
							<IconButton
								size="small"
								onClick={() => handleAdd()}
								disabled={Boolean(!emailText)}
								color="primary"
							>
								<AddCircle />
							</IconButton>
						</Grid>
					</Grid>

					<DialogContentText>
						Email a report of devices within your filter range. Report includes
						timestamp, filter conditions, and device data.
					</DialogContentText>

					<Grid container>
						{/* From */}
						<Grid item xs={2} className={classes.verticalAlign}>
							<Typography variant="caption" color="textSecondary">
								From
							</Typography>
						</Grid>
						<Grid item xs={10}>
							<TextField disabled value={userEmail} />
						</Grid>

						{/* To */}
						<Grid item xs={2} className={classes.verticalAlign}>
							<Typography variant="caption" color="textSecondary">
								To
							</Typography>
						</Grid>
						<Grid item xs={10}>
							<Paper component="ul" className={classes.emailList} elevation={0}>
								{/* Emails */}
								{emailList.map((email: any) => {
									return (
										<li key={email}>
											<Chip
												label={email}
												onDelete={handleDelete(email)}
												className={classes.chip}
											/>
										</li>
									);
								})}
							</Paper>
						</Grid>
					</Grid>
				</DialogContent>

				<Divider />

				<DialogActions>
					<Button onClick={handleCloseReport} color="default">
						Cancel
					</Button>
					<Button
						onClick={handleSubmitReport}
						color="primary"
						autoFocus
						disabled={!Boolean(emailList.length)}
					>
						Send
					</Button>
				</DialogActions>
			</Dialog>
		</>
	);
};
