import React, { useState } from "react";

// Routes
import { ConnectedRouter } from "connected-react-router";

import { history } from "../store";

// Contexts and Models
import { DataContext, MBuilding, MFloor, MSpace } from "../contexts";

// Routes
import Routes from "../routes";

function App() {
	// Set up contexts for provider (mainly used clients, rest is JIC for frequent req changes)
	const [clients, setClients] = useState([]);
	const [buildings, setBuildings] = useState({});

	const [site, setSite] = useState({ id: "", name: "", buildings: [] });
	const [building, setBuilding] = useState(MBuilding);
	const [floor, setFloor] = useState(MFloor);
	const [space, setSpace] = useState(MSpace);

	return (
		// Wrap router with provider
		<DataContext.Provider
			value={{
				clients,
				setClients,
				buildings,
				setBuildings,
				site,
				setSite,
				building,
				setBuilding,
				floor,
				setFloor,
				space,
				setSpace,
			}}
		>
			{/* Routes */}
			<ConnectedRouter history={history}>
				<Routes />
			</ConnectedRouter>
		</DataContext.Provider>
	);
}

export default App;
