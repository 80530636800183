import React, { ReactElement } from "react";

// MUI
import {
	Breadcrumbs,
	Button,
	Container,
	LinearProgress,
	Typography,
	Theme,
	makeStyles,
	useMediaQuery,
	Grid,
} from "@material-ui/core";

// Components
import MaterialTable from "material-table";
import { WarnDeleteDialog } from "./Roles/WarnDeleteDialog";

// Hooks
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { useFirebaseConnect } from "react-redux-firebase";

// Interfaces
import { IGroupData, ITableRow } from "../../interfaces";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	container: {
		paddingTop: theme.spacing(4),
		paddingBottom: theme.spacing(4),
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
	},
	breadcrumbs: {
		display: "flex",
		justifyContent: "flex-end",
		padding: "1rem",
		fontWeight: 700,
	},
}));

// Interfaces

export default function GroupRoles(): ReactElement {
	// Styles
	const classes = useStyles();

	// State
	const [warnDeleteGroup, setWarnDeleteGroup] = React.useState(false);

	// Routing
	const history = useHistory();

	// Desktop / Mobile
	const isMobile = useMediaQuery("(max-width:600px)");

	// Group ID for Firebase watcher
	const params: { groupId: string } = useParams();
	const { groupId = "" } = params;

	// Firebase watcher
	const firebase = useSelector((state: any) => state.firebase);
	const uid = firebase.auth.uid;
	useFirebaseConnect([`users/${uid}/manage/groups/${groupId}`]);

	// Firebase watcher result
	const groupData: IGroupData = useSelector(
		(state: any) => state.firebase.data?.users?.[uid]?.manage?.groups?.[groupId]
	);

	// Row objects mapped to tableData array
	const tableData: ITableRow[] =
		(groupData?.roles &&
			Object.keys(groupData.roles).map((k: string) => ({
				...groupData.roles[k],
				roleId: k,
			}))) ||
		[];

	// Loading Bar While Requesting
	if (firebase.requesting[`users/${uid}/manage/groups`]) {
		return <LinearProgress style={{ width: "100%" }} />;
	}

	// Handlers
	const handleOpenWarnDialog = () => {
		setWarnDeleteGroup(true);
	};

	const handleCloseWarnDialog = () => {
		setWarnDeleteGroup(false);
	};

	// Render
	return (
		<>
			<Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumbs}>
				<Typography color="inherit">{groupData?.name || ""}</Typography>
				<Typography color="textSecondary" style={{ fontWeight: 700 }}>
					Roles
				</Typography>
			</Breadcrumbs>

			<Container className={classes.container}>
				<MaterialTable
					data={tableData}
					title={`${groupData?.name || ""} > Roles`}
					columns={[
						{ title: "Name", field: "name" },
						{
							title: "Permissions",
							field: "permissions",
							render: (row: ITableRow) => {
								const permissionsCount: number = row.permissions
									? Object.keys(row.permissions).length
									: 0;

								return permissionsCount || "None";
							},
							type: "numeric",
						},
						{
							title: "Users",
							field: "users",
							render: (row: ITableRow) => {
								const userCount: number = row.users
									? Object.keys(row.users).length
									: 0;

								return userCount || "None";
							},
							type: "numeric",
						},
						{
							title: "Actions",
							field: "roles",
							render: (row: ITableRow) => (
								<Button
									color="primary"
									variant="contained"
									onClick={() =>
										history.push(`/group/${groupId}/role/${row.roleId}`)
									}
								>
									Manage
								</Button>
							),
							type: "numeric",
						},
					]}
					options={{
						actionsColumnIndex: 3,
						headerStyle: {
							padding: "0rem 1rem",
						},
						search: !Boolean(isMobile),
					}}
				/>
				<Grid container>
					<Grid
						item
						xs={12}
						style={{ display: "flex", justifyContent: "flex-end" }}
					>
						<Button onClick={handleOpenWarnDialog}>Delete Group</Button>
					</Grid>
				</Grid>
			</Container>

			{/* Dialogs */}
			<WarnDeleteDialog
				open={warnDeleteGroup}
				handleClose={handleCloseWarnDialog}
				groupData={groupData}
			/>
		</>
	);
}
