import * as React from "react";

// MUI
import {
	Button,
	Card,
	CardActions,
	CardHeader,
	Checkbox,
	Chip,
	CircularProgress,
	Collapse,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	LinearProgress,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
	Theme,
	makeStyles,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

// MUI Icons
import {
	AccountCircle,
	Business,
	ExpandMore,
	FilterList,
	Replay,
} from "@material-ui/icons";

// Context
import { DataContext } from "../../contexts";

// Hooks
import useClientSelector from "../../hooks/useClientSelector";
import useBuildingSelector from "../../hooks/useBuildingSelector";

// Utils
import { difference, orderBy } from "lodash";
import clsx from "clsx";

import { BuildingMetadataCard } from "../../components/Monitor/BuildingMetadataCard";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		padding: "1rem",
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
	},
	container: {
		display: "flex",
		alignItems: "center",
		padding: "1rem",
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
	},
	fullWidth: {
		width: "100%",
	},
	errorContainer: {
		padding: "1rem",
		width: "100%",
		justifyContent: "center",
		flexWrap: "wrap",
	},
	errorButton: {
		width: "100%",
		alignContent: "center",
		alignItems: "center",
		justifyContent: "center",
	},

	// Status
	active: {
		background: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		"&:hover": {
			background: theme.palette.primary.light,
		},
	},
	loadingActive: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},

	// Main
	moduleWrapper: {
		padding: "1rem",
	},

	// Client -> Sites Expand
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},

	// Overrides
	disabledStyle: {
		color: theme.palette.text.hint,
	},
}));

interface IMonitorOverviewProps {}
const MonitorOverview: React.FC<IMonitorOverviewProps> = (props) => {
	const classes = useStyles();

	// Mobile
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Fetch clients and set in DataContext for future use
	const { clients, setClients, buildings }: any = React.useContext(DataContext);
	const [fetchedClients, loadingClients]: any = useClientSelector(clients);

	// STATE
	// Select Client
	const [selectedClient, setSelectedClient]: any = React.useState(null);
	const [menuAnchorEl, setMenuAnchorEl] =
		React.useState<null | HTMLElement>(null);

	// Select Buildings
	const [selectedBuildings, setSelectedBuildings]: any = React.useState(null);

	// Fetch Buildings
	const [loadingBuildings]: any = useBuildingSelector(selectedClient?.client);

	// Expand Buildings
	const [expandedSites, setExpandedSites] = React.useState("");

	// Filter Buildings
	const [openFilter, setOpenFilter] = React.useState(false);

	// HANDLERS

	// Building Expand
	const handleExpandedSites = (clientId: string) => {
		if (clientId === expandedSites) {
			setExpandedSites("");
		} else {
			setExpandedSites(selectedClient?.client.id);
		}
	};

	// Building Filter
	const handleOpenBuildingFilter = () => {
		setOpenFilter(true);
	};

	const handleCloseBuildingFilter = () => {
		setOpenFilter(false);
	};

	const handleSelectBuildings = (ids: string[]) => {
		if (selectedBuildings?.some((id: string) => ids?.includes(id))) {
			setSelectedBuildings(difference(selectedBuildings, ids));
		} else {
			if (selectedBuildings && ids) {
				setSelectedBuildings([...selectedBuildings, ...ids]);
			} else {
				setSelectedBuildings(ids);
			}
		}
	};

	// Client Menu
	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLElement>,
		client: any
	) => {
		setSelectedClient(client);
		setMenuAnchorEl(null);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};

	// Convenience Vars
	const clientBuildings =
		buildings[selectedClient?.client.id]?.filter((bldg: any) =>
			selectedBuildings?.includes(bldg.id)
		) || [];

	// Effects
	React.useEffect(() => {
		if (!selectedClient && fetchedClients?.length && !fetchedClients?.error) {
			setClients(fetchedClients);
			setSelectedClient(
				// TODO: SWITCH TO [0]
				orderBy(fetchedClients, (client: any) => client.client.name)[1]
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchedClients]);

	React.useEffect(() => {
		handleSelectBuildings(
			buildings[selectedClient?.client.id]?.map((building: any) => building.id)
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [buildings, selectedClient]);

	// Show loading
	if (loadingClients) return <LinearProgress />;

	// Show error
	if ((!clients?.length && !loadingClients) || fetchedClients?.error)
		return (
			<Grid container className={classes.errorContainer}>
				<Typography>Error fetching clients</Typography>
				<Button
					className={classes.errorButton}
					color="secondary"
					onClick={() => window.location.reload()}
				>
					<Replay /> Retry Client Fetch
				</Button>
			</Grid>
		);

	return (
		<Grid container className={classes.root}>
			{/* HEADER */}
			<Grid item xs={12} sm={6} className={classes.container}>
				<ListItem>
					<Typography variant="h6">
						Monitoring Overview{" "}
						{selectedClient && `(${selectedClient.client.name})`}
					</Typography>
				</ListItem>
			</Grid>

			{/* CLIENT SELECTOR */}
			<Grid item xs={12} sm={6} className={classes.container}>
				<List component="nav" className={classes.fullWidth}>
					<ListItem
						button
						aria-haspopup="true"
						aria-controls="lock-menu"
						aria-label="when device is locked"
						onClick={(event: any) => handleMenuClick(event)}
					>
						<ListItemText
							primary={selectedClient?.client.name || ""}
							secondary="Selected Client"
						/>
						<ListItemIcon style={{ justifyContent: "flex-end" }}>
							<ExpandMore />
						</ListItemIcon>
					</ListItem>
				</List>
			</Grid>

			{/* ROW 1: CONTACT and BUILDINGS */}
			{selectedClient && (
				<>
					{/* CONTACT */}
					<Grid item xs={12} sm={6} className={classes.moduleWrapper}>
						<Card>
							<CardHeader
								avatar={<AccountCircle />}
								title={mobile ? selectedClient?.client.contact : "Contacts"}
								subheader={mobile ? "Primary Contact" : "Client Contacts"}
							/>
							<Divider />
							<Grid container>
								{/* Primary Contact */}
								{!mobile && (
									<Grid item xs={12} sm={6}>
										<List>
											<ListItem>
												<ListItemText
													primary={selectedClient?.client.contact}
													secondary="Primary Contact"
												/>
											</ListItem>
										</List>
									</Grid>
								)}

								{/* Contact Information */}
								<Grid item xs={12} sm={6}>
									<List>
										<ListItem>
											<ListItemText
												primary={selectedClient?.client.phoneNumber}
												secondary={selectedClient?.client.email}
											/>
										</ListItem>
									</List>
								</Grid>
							</Grid>
						</Card>
					</Grid>

					{/* BUILDINGS */}
					<Grid item xs={12} sm={6} className={classes.moduleWrapper}>
						<Card>
							<CardHeader
								avatar={<Business />}
								title={"Sites & Buildings"}
								subheader={"View buildings in your sites"}
								action={
									<IconButton
										aria-label="settings"
										onClick={handleOpenBuildingFilter}
									>
										{Boolean(
											loadingBuildings?.includes(selectedClient?.client.id) &&
												mobile
										) ? (
											<CircularProgress size={20} />
										) : (
											<FilterList />
										)}
									</IconButton>
								}
							/>
							<Divider />

							{/* Expanded Sites */}
							{Boolean(selectedClient?.client?.sites?.length && !mobile) && (
								<>
									<CardActions disableSpacing style={{ padding: 0 }}>
										<List style={{ width: "100%" }}>
											<ListItem
												button
												onClick={() =>
													handleExpandedSites(selectedClient?.client.id)
												}
												disabled={Boolean(
													loadingBuildings?.includes(selectedClient?.client.id)
												)}
											>
												<ListItemText
													primary={"Sites"}
													secondary={
														<span
															style={{
																display: "flex",
																alignItems: "center",
																margin: 0,
															}}
														>
															Click
															<FilterList
																style={{ fontSize: 14, margin: "0 6px" }}
															/>
															to filter buildings
														</span>
													}
												/>

												<ListItemIcon>
													{loadingBuildings?.includes(
														selectedClient?.client.id
													) ? (
														<CircularProgress size={20} />
													) : (
														<ExpandMore
															className={clsx(classes.expand, {
																[classes.expandOpen]: Boolean(
																	expandedSites === selectedClient?.client.id
																),
															})}
														/>
													)}
												</ListItemIcon>
											</ListItem>
										</List>
									</CardActions>

									{/* Site Data */}
									<Collapse
										in={Boolean(expandedSites === selectedClient?.client.id)}
										timeout="auto"
										unmountOnExit
									>
										<Divider />
										<List>
											{selectedClient?.client.sites.map((site: any) => (
												<ListItem key={site.id}>
													<ListItemText primary={site.name} />
													<ListItemSecondaryAction>
														<Checkbox
															edge="end"
															onChange={() =>
																handleSelectBuildings(
																	site?.buildings.map(
																		(building: any) => building.id
																	)
																)
															}
															checked={Boolean(
																selectedBuildings?.length &&
																	difference(
																		site.buildings.map(
																			(building: any) => building.id
																		),
																		selectedBuildings
																	).length === 0
															)}
														/>
													</ListItemSecondaryAction>
												</ListItem>
											))}
										</List>
									</Collapse>
								</>
							)}
						</Card>
					</Grid>
				</>
			)}

			<Grid item xs={12} style={{ paddingTop: "1rem" }}>
				<Typography variant="h6" align="center">
					Buildings
				</Typography>
				{/* FILTERED BUILDINGS */}

				{Boolean(clientBuildings.length) && (
					<Grid container className={classes.moduleWrapper} spacing={3}>
						{clientBuildings.map((building: any) => {
							return (
								<Grid item key={building.id} xs={12} sm={6}>
									<BuildingMetadataCard building={building} />
								</Grid>
							);
						})}
					</Grid>
				)}
			</Grid>

			{/* MENUS AND MODALS */}
			{/* CLIENT SELECTOR */}
			<Menu
				anchorEl={menuAnchorEl}
				keepMounted
				open={Boolean(menuAnchorEl)}
				onClose={handleMenuClose}
			>
				{orderBy(clients, (client: any) => client.client.name).map(
					(client: any) => {
						return (
							<MenuItem
								key={client.client.id}
								onClick={(event: any) => handleMenuItemClick(event, client)}
								disabled={!client.client.sites?.length}
							>
								{client.client.name}
							</MenuItem>
						);
					}
				)}
			</Menu>

			{/* BUILDING FILTER */}
			<Dialog
				open={openFilter}
				onClose={handleCloseBuildingFilter}
				fullScreen={mobile ? true : false}
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
			>
				<DialogTitle id="alert-dialog-title">
					{selectedClient
						? `Filter ${selectedClient.client.name} Buildings`
						: "Filter Buildings to View"}
				</DialogTitle>
				<Divider />
				<DialogContent>
					<DialogContentText id="alert-dialog-description">
						Select a building you would like to monitor, or click on an option
						below to select all or by site
					</DialogContentText>
					<Divider />

					{/* Filter List */}
					{loadingBuildings?.includes(selectedClient?.client.id) ? (
						<LinearProgress />
					) : (
						<List style={{ paddingTop: "1rem" }}>
							{selectedClient?.client?.sites?.map((site: any) => (
								<List key={site.id}>
									<ListItem style={{ display: "flex", flexWrap: "wrap" }}>
										<ListItemText primary={site.name} secondary={"Site"} />
										<ListItemSecondaryAction>
											<Checkbox
												edge="end"
												onChange={() =>
													handleSelectBuildings(
														site?.buildings.map((building: any) => building.id)
													)
												}
												checked={Boolean(
													selectedBuildings?.length &&
														difference(
															site.buildings.map(
																(building: any) => building.id
															),
															selectedBuildings
														).length === 0
												)}
											/>
										</ListItemSecondaryAction>
									</ListItem>
									<List className={classes.fullWidth}>
										{site?.buildings.map((building: any) => {
											return (
												<Chip
													key={building.id}
													label={building.name}
													style={{ margin: ".5rem" }}
													onClick={() => handleSelectBuildings([building.id])}
													variant={
														selectedBuildings?.includes(building.id)
															? "default"
															: "outlined"
													}
													color={
														selectedBuildings?.includes(building.id)
															? "primary"
															: "default"
													}
												/>
											);
										})}
									</List>
									<Divider />
								</List>
							))}
						</List>
					)}
				</DialogContent>

				<DialogActions style={{ justifyContent: "flex-start" }}>
					<Button
						onClick={() => setSelectedBuildings([])}
						classes={{ text: classes.disabledStyle }}
						disabled={loadingBuildings?.includes(selectedClient?.client.id)}
					>
						Clear Filters
					</Button>
					<span style={{ display: "flex", flexGrow: 1 }} />
					<Button
						onClick={handleCloseBuildingFilter}
						variant="text"
						classes={{ text: classes.disabledStyle }}
					>
						Close
					</Button>
					<Button
						disabled={loadingBuildings?.includes(selectedClient?.client.id)}
						onClick={handleCloseBuildingFilter}
						color="primary"
						variant="contained"
						autoFocus
					>
						Apply
					</Button>
				</DialogActions>
			</Dialog>
		</Grid>
	);
};

export default MonitorOverview;
