import * as React from "react";

import clsx from "clsx";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	createStyles,
	fade,
	FormControl,
	FormControlLabel,
	Link,
	ListItemText,
	makeStyles,
	Menu,
	MenuItem,
	RadioGroup,
	Theme,
	Typography,
} from "@material-ui/core";

import { blue } from "@material-ui/core/colors";

import { ExpandMore } from "@material-ui/icons";
import { compact, orderBy, uniqBy } from "lodash";
import { RoleDevice } from "../RoleDevice";

// -------- STYLES START --------- //
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		grow: {
			flexGrow: 1,
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
		icon: {
			verticalAlign: "bottom",
			height: 20,
			width: 20,
		},
		details: {
			alignItems: "center",
		},
		helper: {
			borderLeft: `2px solid ${theme.palette.divider}`,
			padding: theme.spacing(1, 2),
		},
		link: {
			color: theme.palette.primary.main,
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
			},
		},

		// Accordion Heading
		divider: {
			display: "flex",
			justifyContent: "center",
			borderBottom: "1px solid #ccc",
		},
		expanded: {
			margin: "2rem -4rem !important",
			boxShadow: theme.shadows[10],
		},
		summaryExpanded: {
			paddingRight: 0,
		},
		primary: {
			fontWeight: 800,
		},
		accordionSummaryRoot: {
			flexDirection: "row-reverse",
		},
		accordionSummaryContent: {
			alignItems: "center",
			paddingLeft: "2rem",
		},
		center: {
			display: "flex",
			justifyContent: "center",
			alignItems: "center",
		},
		column: {
			width: "100%",
			display: "flex",
			flexBasis: "50%",
		},
		columnThird: {
			display: "flex",
			flexBasis: "33%",
		},
		columnTwoThirds: {
			display: "flex",
			flexBasis: "66%",
		},
		end: {
			justifyContent: "flex-end",
		},

		// Search
		search: {
			position: "relative",
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.15),
			"&:hover": {
				backgroundColor: fade(theme.palette.common.white, 0.25),
			},
			marginLeft: 0,
			width: "100%",
			height: 35,
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.spacing(1),
				width: "auto",
			},
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: "100%",
			position: "absolute",
			pointerEvents: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			color: theme.palette.text.hint,
		},
		inputRoot: {
			color: "inherit",
			borderRadius: 8,
			border: `2px solid ${blue[100]}`,
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create("width"),
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: "15ch",
				"&:focus": {
					width: "25ch",
				},
			},
		},
	})
);
// -------- STYLES END --------- //

interface IFloorContainerProps {
	space: any;
	setParentPermissions: any;
}

const getCheckedState = (data: any, variant: string) => {
	return Boolean(
		data.children &&
			data.children.filter((f: any) => f.permission === variant).length ===
				data.children.length
	);
};

const getIndeterminateState = (data: any, variant: string) => {
	let dataChildren = data.children?.filter(
		(f: any) => f.permission === variant
	);
	return Boolean(
		dataChildren?.length && dataChildren.length !== data.children?.length
	);
};

export const SpaceContainer: React.FC<IFloorContainerProps> = ({
	space,
	setParentPermissions,
}) => {
	const classes = useStyles();
	// -------- COMPONENT STATE --------- //
	const [selectedDevice, setSelectedDevice] = React.useState(-1);
	const [activeDeviceFilter, setActiveDeviceFilter] = React.useState({
		id: "",
		text: "",
	});

	// -------- END COMPONENT STATE --------- //

	// -------- FLOOR STATE START --------- //
	// Building-level show floors
	const [showDevicesAnchor, setShowDevicesAnchor] =
		React.useState<null | HTMLElement>(null);

	const handleShowDevices = (event: React.MouseEvent<HTMLButtonElement>) => {
		setShowDevicesAnchor(event.currentTarget);
	};

	const handleCloseShowDevices = () => {
		setShowDevicesAnchor(null);
	};
	// -------- FLOOR STATE END --------- //

	// -------- GENERATE MENU ITEMS --------- //
	const handleSelectDeviceClick = (
		event: React.MouseEvent<HTMLElement>,
		idx: number
	) => {
		setSelectedDevice(idx);
		setShowDevicesAnchor(null);
	};

	const handleSelectDeviceFilter = (
		event: React.MouseEvent<HTMLElement>,
		variant: any
	) => {
		setActiveDeviceFilter(variant);
		setShowDevicesAnchor(null);
	};

	// Generate menu item based on variant types
	let variants: any[] = uniqBy(space?.children, "variant") || [];
	if (variants?.length) {
		variants = variants.map((v: any) => {
			if (v.variant?.toLowerCase().includes("airquality"))
				return { id: v.variant, text: "Air Quality" };
			if (v.variant?.toLowerCase().includes("thermostat"))
				return { id: v.variant, text: "Thermostat" };
			if (v.variant?.toLowerCase().includes("light"))
				return { id: v.variant, text: "Lights" };
			return null;
		});
	}
	variants = compact(variants);

	let devicesMenu = [];

	if (variants?.length) {
		devicesMenu = variants.map((variant: any) => (
			<MenuItem
				key={variant.id}
				onClick={(event) => handleSelectDeviceFilter(event, variant)}
				selected={activeDeviceFilter === variant.id}
			>
				{variant.text}
			</MenuItem>
		));
	} else {
		devicesMenu = space?.children?.map((d: any, idx: number) => {
			return (
				<MenuItem
					key={d.id}
					onClick={(event) => handleSelectDeviceClick(event, idx)}
					selected={idx === selectedDevice}
				>
					{d.name}
				</MenuItem>
			);
		});
	}

	// -------- END MENU ITEMS --------- //

	return (
		<div className={classes.root}>
			<Accordion
				square
				TransitionProps={{ unmountOnExit: true }}
				disabled={!space?.children?.length}
				classes={{ expanded: classes.expanded }}
			>
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-label="Expand"
					aria-controls="additional-actions1-content"
					id="additional-actions1-header"
					classes={{
						root: classes.accordionSummaryRoot,
						content: classes.accordionSummaryContent,
						expanded: classes.summaryExpanded,
					}}
				>
					<div className={clsx(classes.column, classes.grow)}>
						<ListItemText
							primary={space.name}
							secondary={
								space?.children?.length ? (
									<Link
										onClick={(event: any) => {
											event.stopPropagation();
											handleShowDevices(event);
										}}
										onFocus={(event: any) => event.stopPropagation()}
									>
										{selectedDevice !== -1 && !activeDeviceFilter.id
											? `${space?.children[selectedDevice]?.name} (Filter Active)`
											: activeDeviceFilter.id
											? `Filtering ${activeDeviceFilter.text}`
											: "All Devices"}
									</Link>
								) : (
									"No Devices Found"
								)
							}
							classes={{ primary: classes.primary }}
						/>
					</div>
					<div
						className={clsx(classes.column, classes.end)}
						style={{ paddingRight: "1rem" }}
					>
						<FormControl
							component="fieldset"
							margin="dense"
							style={{ width: "100%" }}
						>
							<RadioGroup
								row
								aria-label="Assign to all devices in Floor"
								name="mass-select"
								onClick={(event: any) => {
									event.stopPropagation();
								}}
								onFocus={(event: any) => event.stopPropagation()}
							>
								<div
									className={clsx(classes.columnThird, classes.center)}
									style={{ borderRight: "1px solid #ccc" }}
								>
									<FormControlLabel
										disabled={Boolean(activeDeviceFilter.id)}
										checked={getCheckedState(space, "read")}
										value="read"
										control={
											<Checkbox
												onClick={() =>
													setParentPermissions(
														space,
														space.permission === "read" ? "" : "read"
													)
												}
												color="primary"
												size="small"
												indeterminate={getIndeterminateState(space, "read")}
											/>
										}
										label="Read"
										labelPlacement="bottom"
									/>
								</div>
								<div
									className={clsx(classes.columnThird, classes.center)}
									style={{ borderRight: "1px solid #ccc" }}
								>
									<FormControlLabel
										disabled={Boolean(activeDeviceFilter.id)}
										checked={getCheckedState(space, "write")}
										value="write"
										control={
											<Checkbox
												color="primary"
												size="small"
												indeterminate={getIndeterminateState(space, "write")}
												onClick={() =>
													setParentPermissions(
														space,
														space.permission === "write" ? "" : "write"
													)
												}
											/>
										}
										label="Write"
										labelPlacement="bottom"
									/>
								</div>
								<div className={clsx(classes.columnThird, classes.center)}>
									<FormControlLabel
										disabled={Boolean(activeDeviceFilter.id)}
										checked={getCheckedState(space, "readwrite")}
										value="readwrite"
										control={
											<Checkbox
												color="primary"
												size="small"
												indeterminate={getIndeterminateState(
													space,
													"readwrite"
												)}
												onClick={() =>
													setParentPermissions(
														space,
														space.permission === "readwrite" ? "" : "readwrite"
													)
												}
											/>
										}
										label="ReadWrite"
										labelPlacement="bottom"
									/>
								</div>
							</RadioGroup>
						</FormControl>
					</div>
				</AccordionSummary>

				{/* CONTAINER */}
				<AccordionDetails
					style={{ flexDirection: "column", padding: 0, marginBottom: "1rem" }}
				>
					{/* // -------- SPACES START --------- // */}
					<div className={clsx(classes.columnThird, classes.divider)} />
					<Typography
						variant="caption"
						align="center"
						style={{ padding: ".5rem" }}
					>
						{space.name} Devices
					</Typography>
					<div className={clsx(classes.columnThird, classes.divider)} />

					{selectedDevice === -1 && !activeDeviceFilter.id
						? orderBy(space.children, ["name"])?.map((device: any) => (
								<RoleDevice
									key={device.id}
									device={device}
									setParentPermissions={setParentPermissions}
								/>
						  ))
						: activeDeviceFilter.id
						? space.children
								.filter((s: any) => {
									return s.variant.includes(activeDeviceFilter.id);
								})
								.map((device: any) => (
									<RoleDevice
										key={device.id}
										device={device}
										setParentPermissions={setParentPermissions}
									/>
								))
						: space.children
								.filter((s: any) => space.children[selectedDevice]?.id === s.id)
								.map((device: any) => (
									<RoleDevice
										key={device.id}
										device={device}
										setParentPermissions={setParentPermissions}
									/>
								))}

					{/* // -------- SPACES END --------- // */}
				</AccordionDetails>
			</Accordion>

			{/* Floor-level Menus */}

			{/* Show Floors Menu */}
			<Menu
				id="spaces-menu"
				anchorEl={showDevicesAnchor}
				keepMounted
				open={Boolean(showDevicesAnchor)}
				onClose={handleCloseShowDevices}
			>
				{!activeDeviceFilter.id ? (
					<MenuItem
						onClick={(event: any) => handleSelectDeviceClick(event, -1)}
						selected={selectedDevice === -1}
					>
						All Devices
					</MenuItem>
				) : (
					<MenuItem
						onClick={(event: any) => handleSelectDeviceFilter(event, "")}
						selected={activeDeviceFilter.id === ""}
					>
						All Devices
					</MenuItem>
				)}

				{devicesMenu}
			</Menu>
		</div>
	);
};
