import React, { ReactElement } from "react";

// MUI
import {
	Button,
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Theme,
} from "@material-ui/core";

// Icons
import { BarChart, People } from "@material-ui/icons";

// Util
import clsx from "clsx";
import { useHistory, useLocation } from "react-router";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	active: {
		color: theme.palette.primary.main,
		fontWeight: 700,
	},
}));

// Add Group

export default function DashboardLinks(): ReactElement {
	// Styles
	const classes = useStyles();
	const history = useHistory();

	const addGroup = () => {
		history.push("/dashboard/groups/add");
	};

	const { pathname } = useLocation();

	return (
		<div>
			<ListItem>
				<ListItemText
					primary="Dashboard Menu"
					secondary="Navigate your user groups"
				/>
			</ListItem>

			<ListItem button onClick={() => history.push("/dashboard/groups")}>
				<ListItemIcon>
					<People
						className={clsx({
							[classes.active]: pathname === "/dashboard/groups",
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Groups"
					className={clsx({
						[classes.active]: pathname === "/dashboard/groups",
					})}
				/>
			</ListItem>

			<ListItem button onClick={() => history.push(`/dashboard/monitor`)}>
				<ListItemIcon>
					<BarChart
						className={clsx({
							[classes.active]: pathname === "/dashboard/monitor",
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Monitor"
					className={clsx({
						[classes.active]: pathname === "/dashboard/monitor",
					})}
				/>
			</ListItem>

			<Divider />

			<ListItem>
				<ListItemText primary="Actions" secondary="Create groups and roles" />
			</ListItem>
			<ListItem>
				<Button
					fullWidth
					color="primary"
					variant="contained"
					onClick={() => addGroup()}
				>
					Add Group
				</Button>
			</ListItem>
		</div>
	);
}
