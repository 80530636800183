import React, { ReactElement } from "react";

// MUI
import {
	Button,
	Container,
	LinearProgress,
	makeStyles,
	Theme,
	Typography,
} from "@material-ui/core";
import useMediaQuery from "@material-ui/core/useMediaQuery";

// Components
import MaterialTable from "material-table";

// Hooks
import { useSelector } from "react-redux";
import { useFirebaseConnect } from "react-redux-firebase";
import { useHistory } from "react-router";

// MUI Icons
import { Add } from "@material-ui/icons";
import { compact } from "lodash";
import { TempDial } from "../../components/TempDial/TempDial";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	container: {
		padding: theme.spacing(2),
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
	},
}));

export default function DashboardGroups(): ReactElement {
	// Hooks
	const classes = useStyles();
	const firebase = useSelector((state: any) => state.firebase);
	const history = useHistory();
	const matches = useMediaQuery("(max-width:600px)");

	// Firebase realtime connection
	const uid = firebase.auth.uid;
	useFirebaseConnect([`users/${uid}/manage/groups`]);

	// Firebase groups from rrf
	const groups = useSelector(
		(state: any) => state.firebase.data?.users?.[uid]?.manage?.groups
	);

	// Loading Bar While Requesting
	if (firebase.requesting[`users/${uid}/manage/groups`]) {
		return <LinearProgress style={{ width: "100%" }} />;
	}

	// Handlers
	const addGroup = () => {
		history.push("/dashboard/groups/add");
	};

	interface IUser {}

	// TESTING DEV
	// if (true) {
	if (!groups) {
		return (
			<Container style={{ paddingTop: "1rem" }}>
				<Typography variant="body1" style={{ marginBottom: "2rem" }}>
					You currently don't have any permission groups. Click below to create
					your first group.
				</Typography>
				<Button variant="contained" color="primary" onClick={() => addGroup()}>
					get started
				</Button>
			</Container>
		);
	}

	interface IDatabaseGroup {
		creator: string;
		description: string;
		name: string;
		roles: any;
	}

	interface IGroupData extends IDatabaseGroup {
		id: string;
		tableData: { id: string };
	}

	// Object.entries = [["id", {IDatabaseGroup}], [...]]
	// Flatmap adds the id into the DatabaseGroup to be used in the settings action
	let groupData = Object.entries(groups).flatMap((arr: any[]): IGroupData => {
		if (arr[1]) {
			arr[1]["id"] = arr[0];
			return arr[1];
		}
		return arr[1];
	});
	groupData = compact(groupData);

	if (Boolean(groupData.length)) {
		return (
			<Container className={classes.container}>
				<MaterialTable
					title="Your Groups"
					columns={[
						{ title: "Name", field: "name" },
						{ title: "Description", field: "description" },
						{
							title: "Whitelabel",
							field: "whitelabel",
							render: (rowData: any) => {
								if (
									rowData.whitelabel?.primary ||
									rowData.whitelabel?.secondary ||
									rowData.whitelabel?.logoURL
								) {
									return rowData.whitelabel.enabled ? "Enabled" : "Disabled";
								}
								return "None";
							},
						},
						{
							title: "Roles",
							field: "roles",
							render: (rowData: any) => {
								const roles = rowData.roles
									? Object.keys(rowData?.roles).length
									: 0;
								if (Boolean(roles)) {
									return roles;
								}

								return "None";
							},
							type: "numeric",
						},
						{
							title: "Actions",
							field: "roles",
							render: (rowData: any) => {
								return (
									<Button
										color="primary"
										variant="contained"
										onClick={() => history.push(`/group/${rowData.id}`)}
									>
										Manage
									</Button>
								);
							},
							type: "numeric",
						},
					]}
					data={groupData}
					actions={[
						{
							icon: () => <Add />,
							iconProps: {
								color: "primary",
							},
							tooltip: "Add Group",
							isFreeAction: true,
							onClick: () => addGroup(),
						},
					]}
					options={{
						actionsColumnIndex: 3,
						headerStyle: {
							padding: "0rem 1rem",
						},
						search: matches ? false : true,
					}}
				/>
			</Container>
		);
	}

	return <div></div>;
}
