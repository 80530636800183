import React, { ReactElement } from "react";

// MUI
import { Button, Container, Typography } from "@material-ui/core";

// Icons
import { ChevronLeft } from "@material-ui/icons";

// Hooks
import { useHistory } from "react-router";

interface IErrorGroupNotFound {
    variant?: string;
    redirect?: string;
}

export default function ErrorGroupNotFound({
    variant,
    redirect,
}: IErrorGroupNotFound): ReactElement {
    const history = useHistory();

    if (variant && redirect) {
        return (
            <Container
                maxWidth="md"
                style={{
                    padding: "1rem",
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                }}>
                <Typography variant="body1">
                    Sorry, no route data found. Select route from group
                    dashboard.
                </Typography>
                <Button
                    onClick={() => history.push(redirect)}
                    style={{ display: "flex", alignContent: "center" }}>
                    <ChevronLeft style={{ marginRight: "1rem" }} /> Go Back
                </Button>
            </Container>
        );
    }

    return (
        <Container
            maxWidth="md"
            style={{
                padding: "1rem",
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
            }}>
            <Typography variant="body1">
                Sorry, no group data found. Select group from dashboard.
            </Typography>
            <Button
                onClick={() => history.push("/dashboard")}
                style={{ display: "flex", alignContent: "center" }}>
                <ChevronLeft style={{ marginRight: "1rem" }} /> Go Back
            </Button>
        </Container>
    );
}
