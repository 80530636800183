//Router
import { routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { applyMiddleware, compose, createStore } from "redux";
import createRootReducer from "../reducers";

//Middleware
//Logger
import logger from "redux-logger";

//Thunk
import thunk from "redux-thunk";

//Create Middleware
export const history = createBrowserHistory();

export default function configureStore(): any {
  let store;

  store =
    process.env.NODE_ENV === "production"
      ? createStore(
          createRootReducer(history),
          compose(applyMiddleware(routerMiddleware(history), thunk))
        )
      : createStore(
          createRootReducer(history),
          compose(applyMiddleware(routerMiddleware(history), logger))
        );

  //Run sagas
  // @ts-ignore

  return store;
}
