import * as React from "react";

// MUI
import {
	Button,
	Grid,
	LinearProgress,
	Typography,
	makeStyles,
	Theme,
	List,
	ListItem,
	ListItemText,
	Paper,
	Card,
	CardHeader,
	Divider,
	CardActions,
	Collapse,
	ListItemIcon,
	CircularProgress,
	Menu,
	MenuItem,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

// MUI Icons
import {
	AccountCircle,
	ExpandMore,
	Replay,
	SupervisedUserCircle,
} from "@material-ui/icons";

// Context
import { DataContext } from "../../contexts";

// Hooks
import useClientSelector from "../../hooks/useClientSelector";
import useBuildingSelector from "../../hooks/useBuildingSelector";

// Utils
import { orderBy } from "lodash";
import clsx from "clsx";

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		width: "100%",
		padding: "1rem",
	},
	container: {
		display: "flex",
		alignItems: "center",
		padding: "1rem",
		[theme.breakpoints.down("sm")]: {
			padding: 0,
		},
	},
	fullWidth: {
		width: "100%",
	},
	errorContainer: {
		padding: "1rem",
		width: "100%",
		justifyContent: "center",
		flexWrap: "wrap",
	},
	errorButton: {
		width: "100%",
		alignContent: "center",
		alignItems: "center",
		justifyContent: "center",
	},

	// Status
	active: {
		background: theme.palette.primary.main,
		color: theme.palette.getContrastText(theme.palette.primary.main),
		"&:hover": {
			background: theme.palette.primary.light,
		},
	},
	loadingActive: {
		color: theme.palette.getContrastText(theme.palette.primary.main),
	},

	// Main
	moduleWrapper: {
		padding: "1rem",
	},

	// Client -> Sites Expand
	expand: {
		transform: "rotate(0deg)",
		marginLeft: "auto",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	expandOpen: {
		transform: "rotate(180deg)",
	},
}));

interface IMonitorClientsProps {}
const MonitorClients: React.FC<IMonitorClientsProps> = (props) => {
	const classes = useStyles();

	// Mobile
	const theme = useTheme();
	const mobile = useMediaQuery(theme.breakpoints.down("sm"));

	// Fetch clients and set in DataContext for future use
	const { clients, setClients }: any = React.useContext(DataContext);
	const [fetchedClients, loadingClients]: any = useClientSelector(clients);

	// Overview State
	const [selectedClient, setSelectedClient]: any = React.useState(null);

	// Client -> Sites Expanded State
	const [expandedSites, setExpandedSites] = React.useState("");

	// Client select menu
	const [menuAnchorEl, setMenuAnchorEl] =
		React.useState<null | HTMLElement>(null);

	// Convenience variable
	const activeClient =
		clients.filter((client: any) => client.client.id === selectedClient)[0]
			?.client || null;

	// Fetch Buildings
	const [loadingBuildings]: any = useBuildingSelector(activeClient);

	// Handlers
	const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		setMenuAnchorEl(event.currentTarget);
	};

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLElement>,
		client: any
	) => {
		setSelectedClient(client);
		setMenuAnchorEl(null);
	};

	const handleMenuClose = () => {
		setMenuAnchorEl(null);
	};

	const handleExpandedSites = (clientId: string) => {
		if (clientId === expandedSites) {
			setExpandedSites("");
		} else {
			setExpandedSites(activeClient.id);
		}
	};

	React.useEffect(() => {
		if (!selectedClient && fetchedClients?.length && !fetchedClients?.error) {
			setClients(fetchedClients);
			setSelectedClient(
				orderBy(fetchedClients, (client: any) => client.client.name)[0]
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fetchedClients]);

	// Show loading
	if (loadingClients) return <LinearProgress />;

	// Show error
	if ((!clients?.length && !loadingClients) || fetchedClients?.error)
		return (
			<Grid container className={classes.errorContainer}>
				<Typography>Error fetching clients</Typography>
				<Button
					className={classes.errorButton}
					color="secondary"
					onClick={() => window.location.reload()}
				>
					<Replay /> Retry Client Fetch
				</Button>
			</Grid>
		);

	console.log(clients);

	return (
		<Grid container className={classes.root}>
			<Grid item xs={12} sm={6} className={classes.container}>
				<ListItem>
					<Typography variant="h6">Clients Overview</Typography>
				</ListItem>
			</Grid>
			<Grid item xs={12} sm={6} className={classes.container}>
				<List
					component="nav"
					aria-label="Device settings"
					className={classes.fullWidth}
				>
					<ListItem
						button
						aria-haspopup="true"
						aria-controls="lock-menu"
						aria-label="when device is locked"
						onClick={(event: any) => handleMenuClick(event)}
					>
						<ListItemText
							primary={selectedClient?.client.name || ""}
							secondary="Selected Client"
						/>
						<ListItemIcon>
							<ExpandMore />
						</ListItemIcon>
					</ListItem>
				</List>
			</Grid>

			{/* Clients List */}
			<Grid item xs={6} className={classes.moduleWrapper}>
				<Card>
					{/* Header */}
					<CardHeader
						avatar={<SupervisedUserCircle />}
						title={mobile ? "Mobile" : "Clients"}
						subheader="Manage your clients and sites here"
					/>
					<Divider />

					{/* List of clients */}
					<List>
						{orderBy(clients, (client: any) => client.client.name).map(
							(client: any) => (
								<ListItem
									button
									key={client.client.id}
									disabled={!client.client.sites?.length}
									className={clsx({
										[classes.active]: selectedClient === client.client.id,
									})}
									onClick={() => setSelectedClient(client.client.id)}
								>
									{/* Client Name */}
									<ListItemText
										primary={client.client.name}
										secondary={
											!client.client.sites?.length ? "No Site Data" : ""
										}
									/>

									{/* Loading Icon */}
									{Boolean(loadingBuildings?.includes(client.client.id)) && (
										<ListItemIcon>
											<CircularProgress
												size={20}
												className={clsx({
													[classes.loadingActive]: Boolean(
														selectedClient === client.client.id &&
															loadingBuildings?.includes(selectedClient)
													),
												})}
											/>
										</ListItemIcon>
									)}
								</ListItem>
							)
						)}
					</List>
				</Card>
			</Grid>

			<Grid item xs={6} className={classes.moduleWrapper}>
				{activeClient && (
					// Show loading
					<Paper>
						<Card>
							<CardHeader
								avatar={<AccountCircle />}
								title={activeClient.name}
								subheader="Contact Information"
							/>
							<Divider />
							<Grid container>
								{/* Primary Contact */}
								<Grid item xs={6}>
									<List>
										<ListItem>
											<ListItemText
												primary={activeClient.contact}
												secondary="Primary Contact"
											/>
										</ListItem>
									</List>
								</Grid>

								{/* Contact Information */}
								<Grid item xs={6}>
									<List>
										<ListItem>
											<ListItemText
												primary={activeClient.phoneNumber}
												secondary={activeClient.email}
											/>
										</ListItem>
									</List>
								</Grid>
							</Grid>

							{/* Expanded Sites */}

							{activeClient?.sites?.length && (
								<>
									<CardActions disableSpacing>
										<List style={{ width: "100%" }}>
											<ListItem
												button
												onClick={() => handleExpandedSites(activeClient.id)}
												disabled={Boolean(
													loadingBuildings.includes(activeClient.id)
												)}
											>
												<ListItemText primary={"Sites & Buildings"} />

												<ListItemIcon>
													{loadingBuildings.includes(activeClient.id) ? (
														<CircularProgress size={20} />
													) : (
														<ExpandMore
															className={clsx(classes.expand, {
																[classes.expandOpen]: Boolean(
																	expandedSites === activeClient.id
																),
															})}
														/>
													)}
												</ListItemIcon>
											</ListItem>
										</List>
									</CardActions>

									{/* Site Data */}
									<Collapse
										in={Boolean(expandedSites === activeClient.id)}
										timeout="auto"
										unmountOnExit
									>
										<Divider />
										<List>
											{activeClient.sites.map((site: any) => (
												<ListItem key={site.id}>
													<ListItemText primary={site.name} />
												</ListItem>
											))}
										</List>
									</Collapse>
								</>
							)}
						</Card>
					</Paper>
				)}
			</Grid>

			{/* Client Selector Menu */}
			<Menu
				anchorEl={menuAnchorEl}
				keepMounted
				open={Boolean(menuAnchorEl)}
				onClose={handleMenuClose}
			>
				{orderBy(clients, (client: any) => client.client.name).map(
					(client: any) => {
						return (
							<MenuItem
								key={client.client.id}
								onClick={(event: any) => handleMenuItemClick(event, client)}
								disabled={!client.client.sites?.length}
							>
								{client.client.name}
							</MenuItem>
						);
					}
				)}
			</Menu>
		</Grid>
	);
};

export default MonitorClients;
