import React, { useEffect } from "react";

// MUI
import {
	AppBar,
	Divider,
	Drawer,
	IconButton,
	LinearProgress,
	List,
	Toolbar,
	Typography,
	CssBaseline,
	makeStyles,
	Theme,
	ThemeProvider,
	createMuiTheme,
	useMediaQuery,
	useTheme,
} from "@material-ui/core";

// Icons
import { ChevronLeft, Menu } from "@material-ui/icons";

// Colors
import { indigo } from "@material-ui/core/colors";

// Components

// Util
import clsx from "clsx";

// Hooks
import { useFirebaseConnect } from "react-redux-firebase";
import { useSelector } from "react-redux";

// Routing
import { useParams } from "react-router";
import MonitorLinks from "./Monitor/Menu/MonitorLinks";

// Const
const drawerWidth = 240;

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	root: {
		display: "flex",
	},
	toolbar: {
		paddingRight: 24,
		paddingLeft: 0,
	},
	logoContainer: {
		height: 64,
	},
	toolbarIcon: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: "0 8px",
		...theme.mixins.toolbar,
	},
	appBar: {
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
		[theme.breakpoints.down("sm")]: {
			marginRight: 0,
		},
	},
	menuButtonHidden: {
		display: "none",
	},
	title: {
		flexGrow: 1,
	},
	drawerPaper: {
		position: "relative",
		whiteSpace: "nowrap",
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerPaperClose: {
		overflowX: "hidden",
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		width: 0,
	},
	appBarSpacer: theme.mixins.toolbar,
	content: {
		flexGrow: 1,
		height: "100vh",
		overflow: "auto",
	},
	container: {
		marginTop: 64,
		padding: `0 ${theme.spacing(1)}`,
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		overflow: "auto",
		flexDirection: "column",
	},
	fixedHeight: {
		height: 240,
	},
	hideMobile: {
		[theme.breakpoints.down("sm")]: {
			display: "none",
		},
	},
}));

// Page for the /dashboard endpoint
// Acts as resting place for auth state user on init
interface IMonitorLayout {
	children?: any;
}

export default function MonitorLayout({ children }: IMonitorLayout) {
	const classes = useStyles();

	// State
	// Default open not mobile
	const themeObject = useTheme();
	const mobile = useMediaQuery(themeObject.breakpoints.up("sm"));

	const [drawerOpen, setDrawerOpen] = React.useState(mobile);

	// Scan for mobile
	useEffect(() => {
		setDrawerOpen(mobile);
	}, [mobile]);

	// Hooks
	const firebase = useSelector((state: any) => state.firebase);
	const { groupId }: any = useParams();

	// Firebase realtime connection
	const uid = firebase.auth.uid;
	const dbString = `users/${uid}/manage/groups/${groupId}`;
	useFirebaseConnect([dbString]);

	// Firebase groups from rrf
	const group = useSelector(
		(state: any) => state.firebase.data?.users?.[uid]?.manage?.groups?.[groupId]
	);

	// Handlers (sidebar)
	const handleDrawerOpen = () => {
		setDrawerOpen(true);
	};

	const handleDrawerClose = () => {
		setDrawerOpen(false);
	};

	// Show progress while auth is loading
	if (!firebase.auth.isLoaded || !firebase.profile.isLoaded) {
		return <LinearProgress style={{ width: "100%" }} />;
	}

	let theme = {};

	if (
		group?.whitelabel &&
		group?.whitelabel.enabled &&
		(group?.whitelabel.primary || group?.whitelabel.secondary)
	) {
		theme = createMuiTheme({
			palette: {
				primary: {
					main: group.whitelabel.primary
						? group.whitelabel.primary
						: indigo[500],
				},
				secondary: {
					main: group.whitelabel.secondary
						? group.whitelabel.secondary
						: indigo[500],
				},
			},
		});
	}

	return (
		<ThemeProvider theme={theme}>
			<div className={classes.root}>
				<CssBaseline />

				{/* Drawer: APPBAR */}
				<AppBar
					position="absolute"
					className={clsx(classes.appBar, drawerOpen && classes.appBarShift)}
				>
					<Toolbar className={classes.toolbar}>
						<IconButton
							color="inherit"
							aria-label="drawerOpen drawer"
							onClick={handleDrawerOpen}
							className={clsx(
								classes.menuButton,
								drawerOpen && classes.menuButtonHidden
							)}
						>
							<Menu />
						</IconButton>

						<Typography
							align="center"
							component="h1"
							variant="h6"
							color="inherit"
							noWrap
							className={classes.title}
						>
							Global Monitoring
						</Typography>
					</Toolbar>
				</AppBar>

				{/* Drawer: CONTROL */}
				<Drawer
					anchor="right"
					variant="permanent"
					classes={{
						paper: clsx(
							classes.drawerPaper,
							!drawerOpen && classes.drawerPaperClose
						),
					}}
					open={drawerOpen}
				>
					<div className={classes.toolbarIcon}>
						<IconButton onClick={handleDrawerClose}>
							<ChevronLeft />
						</IconButton>
					</div>
					<Divider />
					<List style={{ height: "100%" }}>
						<MonitorLinks group={group} />
					</List>
					<Divider />
				</Drawer>
				<main className={clsx(classes.content, classes.container)}>
					{children}
				</main>
			</div>
		</ThemeProvider>
	);
}
