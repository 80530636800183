import React, { useState } from "react";

import clsx from "clsx";

import {
	Theme,
	createStyles,
	makeStyles,
	fade,
} from "@material-ui/core/styles";

import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Chip,
	Divider,
	IconButton,
	InputBase,
	Link,
	ListItemIcon,
	ListItemText,
	Menu,
	MenuItem,
} from "@material-ui/core";

import {
	Clear,
	Create,
	ExpandMore,
	MoreVert,
	Search,
	VerifiedUser,
	Visibility,
} from "@material-ui/icons";

import { blue, lightBlue } from "@material-ui/core/colors";

// Components
import { FloorContainer } from "../FloorContainer";
import { compact, orderBy } from "lodash";

// -------- STYLES START --------- //
const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: "100%",
		},
		grow: {
			flexGrow: 1,
		},
		heading: {
			fontSize: theme.typography.pxToRem(15),
		},
		secondaryHeading: {
			fontSize: theme.typography.pxToRem(15),
			color: theme.palette.text.secondary,
		},
		icon: {
			verticalAlign: "bottom",
			height: 20,
			width: 20,
		},
		details: {
			alignItems: "center",
		},
		column: {
			flexBasis: "33.33%",
		},
		helper: {
			borderLeft: `2px solid ${theme.palette.divider}`,
			padding: theme.spacing(1, 2),
		},
		link: {
			color: theme.palette.primary.main,
			textDecoration: "none",
			"&:hover": {
				textDecoration: "underline",
			},
		},

		// Accordion Heading
		paper: {
			background: blue[50],
			"&$expanded": {
				margin: "2rem 0 !important",
				background: blue[200],
				boxShadow: theme.shadows[5],
			},
		},
		expanded: { margin: "1rem 0" },
		primary: {
			fontWeight: 800,
		},
		accordionSummaryRoot: {
			flexDirection: "row-reverse",
		},
		accordionSummaryContent: {
			alignItems: "center",
			paddingLeft: "2rem",
		},
		center: {
			display: "flex",
			alignItems: "center",
		},

		// Search
		search: {
			position: "relative",
			borderRadius: theme.shape.borderRadius,
			backgroundColor: fade(theme.palette.common.white, 0.15),
			"&:hover": {
				backgroundColor: fade(theme.palette.common.white, 0.25),
			},
			marginLeft: 0,
			width: "100%",
			height: 35,
			[theme.breakpoints.up("sm")]: {
				marginLeft: theme.spacing(1),
				width: "auto",
			},
		},
		searchIcon: {
			padding: theme.spacing(0, 2),
			height: "100%",
			position: "absolute",
			pointerEvents: "none",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			color: theme.palette.text.hint,
		},
		inputRoot: {
			color: "inherit",
			borderRadius: 8,
			border: `2px solid ${blue[100]}`,
		},
		inputInput: {
			padding: theme.spacing(1, 1, 1, 0),
			// vertical padding + font size from searchIcon
			paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
			transition: theme.transitions.create("width"),
			width: "100%",
			[theme.breakpoints.up("sm")]: {
				width: "15ch",
				"&:focus": {
					width: "25ch",
				},
			},
		},

		// Menus
		buildingList: {
			paddingBottom: 0,
		},

		// Chip
		outlinedPrimary: {
			border: `1px solid ${lightBlue[700]}`,
			color: lightBlue[700],
		},
	})
);
// -------- STYLES END --------- //

// -------- INTERFACES --------- //
interface IBuildingContainer {
	building: any;
	permissionedDevices: any;
	setParentPermissions: any;
}

// -------- COMPONENT --------- //
export const BuildingContainer = ({
	building,
	permissionedDevices,
	setParentPermissions,
}: IBuildingContainer) => {
	const classes = useStyles();
	// -------- COMPONENT STATE --------- //
	const [selectedFloor, setSelectedFloor] = useState(-1);
	// -------- END COMPONENT STATE --------- //

	// -------- BUILDING STATE START --------- //
	// Building-level MoreVert menu
	const [
		buildingMoreVertAnchor,
		setBuildingMoreVertAnchor,
	] = React.useState<null | HTMLElement>(null);

	const handleMoreVert = (event: React.MouseEvent<HTMLButtonElement>) => {
		setBuildingMoreVertAnchor(event.currentTarget);
	};

	const handleCloseMoreVert = () => {
		setBuildingMoreVertAnchor(null);
	};

	// Building-level show floors
	const [
		showFloorsAnchor,
		setShowFloorsAnchor,
	] = React.useState<null | HTMLElement>(null);

	const handleShowFloors = (event: React.MouseEvent<HTMLButtonElement>) => {
		setShowFloorsAnchor(event.currentTarget);
	};

	const handleCloseShowFloors = () => {
		setShowFloorsAnchor(null);
	};
	// -------- BUILDING STATE END --------- //

	// -------- GENERATE MENU ITEMS --------- //
	const handleSelectFloorClick = (
		event: React.MouseEvent<HTMLElement>,
		idx: number
	) => {
		setSelectedFloor(idx);
		setShowFloorsAnchor(null);
	};

	const floorsMenu = compact(
		building?.children.map((f: any, idx: number) =>
			f.children?.length ? (
				<MenuItem
					key={f.id}
					onClick={(event) => handleSelectFloorClick(event, idx)}
					selected={idx === selectedFloor}
				>
					{f.name}
				</MenuItem>
			) : null
		)
	);

	// -------- END MENU ITEMS --------- //

	const handleMenuItemClick = (
		event: React.MouseEvent<HTMLElement>,
		permission: string
	) => {
		console.log(permission);
		setParentPermissions(building, permission);
		setBuildingMoreVertAnchor(null);
	};

	// -------- RENDER --------- //
	return (
		<div className={classes.root}>
			<Accordion
				square
				TransitionProps={{ unmountOnExit: true }}
				classes={{ root: classes.paper, expanded: classes.expanded }}
				disabled={!floorsMenu?.length}
			>
				{/* HEADER */}
				<AccordionSummary
					expandIcon={<ExpandMore />}
					aria-label="Expand"
					aria-controls="additional-actions1-content"
					id="additional-actions1-header"
					classes={{
						root: classes.accordionSummaryRoot,
						content: classes.accordionSummaryContent,
					}}
				>
					<div className={clsx(classes.grow)}>
						<ListItemText
							primary={building.name}
							secondary={
								floorsMenu?.length ? (
									<Link
										onClick={(event: any) => {
											event.stopPropagation();
											handleShowFloors(event);
										}}
										onFocus={(event: any) => event.stopPropagation()}
									>
										{selectedFloor !== -1
											? `${building?.children[selectedFloor]?.name} (Filter Active)`
											: `${floorsMenu?.length || "0"} ${
													floorsMenu?.length === 1 ? "Floor" : "Floors"
											  } Available`}
									</Link>
								) : (
									"No floors available"
								)
							}
							classes={{ primary: classes.primary }}
						/>
					</div>
					<div>
						{/* Device / Points */}
						{Boolean(permissionedDevices?.length) && (
							<Chip
								onClick={(event: any) => {
									event.stopPropagation();
								}}
								label={`${permissionedDevices.length} Device${
									permissionedDevices.length === 1 ? "" : "s"
								}`}
								clickable
								variant="outlined"
								color="primary"
								classes={{ outlinedPrimary: classes.outlinedPrimary }}
							/>
						)}
					</div>
					<div className={clsx(classes.search, classes.center)}>
						{/* Search */}
						<div className={classes.searchIcon}>
							<Search />
						</div>
						<InputBase
							onClick={(event: any) => {
								event.stopPropagation();
							}}
							onFocus={(event: any) => event.stopPropagation()}
							placeholder="Search Devices..."
							classes={{
								root: classes.inputRoot,
								input: classes.inputInput,
							}}
							inputProps={{
								"aria-label": "search",
							}}
							endAdornment={
								<IconButton size="small">
									<Clear color="disabled" fontSize="small" />
								</IconButton>
							}
						/>
					</div>
					<IconButton
						aria-label="more"
						aria-controls="long-menu"
						aria-haspopup="true"
						onClick={(event: any) => {
							event.stopPropagation();
							handleMoreVert(event);
						}}
					>
						<MoreVert />
					</IconButton>
				</AccordionSummary>

				{/* CONTAINER */}
				<AccordionDetails style={{ flexDirection: "column" }}>
					{/* // -------- FLOORS START --------- // */}

					{selectedFloor === -1
						? orderBy(building.children, ["name"])?.map((floor: any) => (
								<FloorContainer
									key={floor.id}
									floor={floor}
									setParentPermissions={setParentPermissions}
								/>
						  ))
						: building.children
								.filter(
									(f: any) => building?.children[selectedFloor]?.id === f.id
								)
								.map((floor: any) => (
									<FloorContainer
										key={floor.id}
										floor={floor}
										setParentPermissions={setParentPermissions}
									/>
								))}

					{/* // -------- FLOORS END --------- // */}
				</AccordionDetails>
			</Accordion>

			{/* Building-level Menus */}

			{/* Show Floors Menu */}
			<Menu
				id="floors-menu"
				anchorEl={showFloorsAnchor}
				keepMounted
				open={Boolean(showFloorsAnchor)}
				onClose={handleCloseShowFloors}
			>
				<MenuItem
					onClick={(event: any) => handleSelectFloorClick(event, -1)}
					selected={selectedFloor === -1}
				>
					All Floors
				</MenuItem>
				{floorsMenu}
			</Menu>

			{/* Building Mass Assign Devices Menu */}
			<Menu
				id="building-menu"
				anchorEl={buildingMoreVertAnchor}
				keepMounted
				open={Boolean(buildingMoreVertAnchor)}
				onClose={handleCloseMoreVert}
				classes={{ list: classes.buildingList }}
			>
				<MenuItem disabled>
					<ListItemText primary="All Devices in Building" />
				</MenuItem>
				<MenuItem
					onClick={(event) => handleMenuItemClick(event, "read")}
					selected={building.permission === "read"}
				>
					<ListItemIcon>
						<Visibility fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Read Only" />
				</MenuItem>
				<MenuItem onClick={(event) => handleMenuItemClick(event, "write")}>
					<ListItemIcon>
						<Create fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Write Only" />
				</MenuItem>
				<MenuItem onClick={(event) => handleMenuItemClick(event, "readwrite")}>
					<ListItemIcon>
						<VerifiedUser fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Full Access" />
				</MenuItem>
				<Divider />
				<MenuItem onClick={(event) => handleMenuItemClick(event, "")}>
					<ListItemIcon>
						<Clear fontSize="small" />
					</ListItemIcon>
					<ListItemText primary="Clear All" />
				</MenuItem>
			</Menu>
		</div>
	);
};
