import React, { ReactElement } from "react";

// MUI
import {
	Button,
	CircularProgress,
	Container,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";

// Icons
import { Edit, Security, Visibility } from "@material-ui/icons";

// Colors
import { amber, green, lightBlue } from "@material-ui/core/colors";

// Components
import { AddRole } from "./AddRole";

// Interfaces
import { IPermissionsPoint, IUserPointPermission } from "../../interfaces";

// Utils
import { flatten, pick, uniqBy } from "lodash";
import { useHistory, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useFirebase } from "react-redux-firebase";

const getNodePoints = (node: any, collect: any[]): any => {
	if (node.points) {
		collect.push(node);
		return node;
	}

	if (node.children) {
		let i;

		for (i = 0; i < node.children.length; i++) {
			getNodePoints(node.children[i], collect);
		}
		return collect;
	}
	return collect;
};

const getPoints = (data: any) => {
	let i = 0,
		collect: any[] = [];

	for (i; i < data.length; i++) {
		getNodePoints(data[i], collect);
	}

	return collect;
};

// Helpers
let transformSelectedDevices = (data: any) => {
	return uniqBy(data, "building").map((building: any) => ({
		type: "building",
		name: building.buildingName,
		id: building.building,
		permission: "",
		children: uniqBy(data, "floor")
			.filter((f: any) => f.building === building.building)
			.map((floor: any) => ({
				type: "floor",
				name: floor.floorName,
				buildingId: building.building,
				id: floor.floor,
				permission: "",
				children: uniqBy(data, "space")
					.filter((s: any) => s.floor === floor.floor)
					.map((space: any) => ({
						type: "space",
						name: space.spaceName,
						buildingId: building.building,
						floorId: floor.floor,
						id: space.space,
						permission: "",
						children: uniqBy(data, "device")
							.filter((d: any) => d.space === space.space)
							.map((device: any) => ({
								type: "device",
								name: device.deviceName,
								buildingId: building.building,
								floorId: floor.floor,
								spaceId: space.space,
								id: device.device,
								permission: "",
								...device,
								points:
									device.points?.map((point: any) => ({
										...point,
										buildingName: building.buildingName,
										buildingId: building.building,
										floorName: floor.floorName,
										floorId: floor.floor,
										spaceName: space.spaceName,
										spaceId: space.space,
										deviceName: device.deviceName,
										deviceId: device.device,
										pointName: point.name,
										pointId: point.id,
										clientName: device.clientName,
										clientId: device.client,
									})) || [],
							})),
					})),
			})),
	}));
};

const pointKeys = [
	"clientId",
	"clientName",
	"siteId",
	"siteName",
	"buildingId",
	"buildingName",
	"floorId",
	"floorName",
	"spaceId",
	"spaceName",
	"deviceId",
	"deviceName",
	"pointId",
	"pointName",
	"permission",
];

export const createPermission = (
	point: IPermissionsPoint,
	permission: "read" | "write" | "readwrite"
): IUserPointPermission => ({
	clientName: point.clientName, // this doesn't exist on the API point
	clientId: point.clientId, // this doesn't exist on the API point
	siteName: point.siteName, // this doesn't exist on the API point
	siteId: point.siteId,
	buildingName: point.buildingName, // this doesn't exist on the API point
	buildingId: point.buildingId, // this doesn't exist on the API point
	floorName: point.floorName, // this doesn't exist on the API point
	floorId: point.floorId, // this doesn't exist on the API point
	spaceName: point.spaceName, // this doesn't exist on the API point
	spaceId: point.spaceId, // this doesn't exist on the API point
	deviceName: point.deviceName, // this doesn't exist on the API point
	deviceId: point.deviceId,
	pointName: point.pointName,
	pointId: point.pointId,
	permission: permission,
});

// Interfaces
interface Props {
	roleName: string;
	setRoleName?: any;
	roleNameExists?: boolean;
	handleRoleName: (event: React.ChangeEvent<HTMLInputElement>) => void;
	selectedDevices: any[];
	assignedPermissions: any;
	setAssignedPermissions: (permissions: any[] | []) => void;
}

/*
  CreateRole takes a list of selected devices and allows for custom sets of permissions to be assigned
    roleName:               //textfield for role name
    handleRoleName:         //handler for above
    selectedDevices:        //array of [{... pure chaos, needs interface ...}] device data
    assignedPermissions:    //array of IUserPointPermission
    setAssignedPermissions: //handler for above
*/
export default function CreateRole({
	roleName,
	setRoleName,
	roleNameExists,
	handleRoleName,
	selectedDevices,
	assignedPermissions,
	setAssignedPermissions,
}: Props): ReactElement {
	// Get group id for edit
	const params: any = useParams();
	const groupId = params?.groupId;

	// Redux
	const history = useHistory();

	// Hooks (firebase)
	const firebase = useSelector((state: any) => state.firebase);
	const rrf = useFirebase();

	const [rolePermissionData, setRolePersmissionData] = React.useState(() =>
		transformSelectedDevices(selectedDevices)
	);

	const [submitting, setSubmitting] = React.useState(false);
	const [openDialog, setOpenDialog] = React.useState(false);

	React.useEffect(() => {
		const devices = getPoints(rolePermissionData)?.filter(
			(d: any) => d.permission
		);

		setAssignedPermissions(devices);
	}, [rolePermissionData, setAssignedPermissions]);

	const handleCloseDialog = () => {
		setOpenDialog(false);
	};

	// Handle Create Role
	const handleCreateRole = () => {
		setOpenDialog(true);
		setSubmitting(true);
		// Values

		const role = {
			name: roleName,
			permissions: assignedPermissions,
			// Tree
		};

		// Add Role to Group
		const roleAdded = rrf.push(
			`users/${firebase.auth.uid}/manage/groups/${groupId}/roles`,
			role
		);

		// Cleanup
		roleAdded
			.then((res: any) => {
				setSubmitting(false);
				setRoleName("");
				history.push(`/group/${groupId}/role/${res.key}`);
				setOpenDialog(false);
			})
			.catch((e: any) => {
				setSubmitting(false);
				setOpenDialog(false);
			});
	};

	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12} style={{ marginBottom: "1rem" }}>
					{/* Title */}
					<Typography variant="h6" gutterBottom>
						Role Name
					</Typography>{" "}
					{/* Role Name */}
					<TextField
						error={roleNameExists}
						autoFocus
						required
						id="role-name"
						name="Role Name"
						label="Role Name"
						value={roleName}
						variant="outlined"
						fullWidth
						onChange={handleRoleName}
						helperText={
							roleNameExists
								? `Already a role called ${roleName}. Please change to something unique.`
								: ""
						}
					/>
					{/* Description */}
					<Typography
						paragraph
						variant="body1"
						gutterBottom
						style={{ marginTop: "1rem" }}
					>
						Configure access for each point by creating a role. <br />
						Roles are custom permission sets that may then be assigned to your
						users to grant or limit access.
					</Typography>
					<Typography
						paragraph
						variant="body1"
						gutterBottom
						style={{ display: "flex", alignItems: "center" }}
					>
						There are three options, Read Only (
						<Visibility style={{ color: green[500], padding: ".25rem" }} />
						) , Write Only (
						<Edit
							style={{
								color: lightBlue[500],
								padding: ".25rem",
							}}
						/>
						) , or Read and Write (
						<Security style={{ color: amber[700], padding: ".25rem" }} />)
					</Typography>
				</Grid>

				<Container maxWidth="md">
					<AddRole
						data={rolePermissionData}
						permissionedDevices={assignedPermissions}
						setRolePersmissionData={setRolePersmissionData}
						setPermissionedDevices={setAssignedPermissions}
					/>
				</Container>

				{Boolean(groupId) && (
					<div
						style={{
							display: "flex",
							padding: "2rem",
							justifyContent: "center",
							width: "100%",
						}}
					>
						<Button
							disabled={Boolean(submitting) || Boolean(!roleName)}
							color="primary"
							variant="contained"
							style={{ display: "flex", padding: "1rem" }}
							onClick={() => handleCreateRole()}
						>
							{" "}
							{submitting ? <CircularProgress size={20} /> : "Create Role"}
						</Button>
					</div>
				)}
			</Grid>

			{/* Dialog */}
			<div>
				<Dialog
					open={openDialog}
					onClose={() => handleCloseDialog()}
					aria-labelledby="alert-dialog-title"
					aria-describedby="alert-dialog-description"
					disableBackdropClick={true}
				>
					<DialogTitle id="alert-dialog-title">
						{"Creating new role..."}
					</DialogTitle>
					<DialogContent
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
						}}
					>
						<CircularProgress size={40} disableShrink />
					</DialogContent>
				</Dialog>
			</div>
		</React.Fragment>
	);
}
