import * as React from "react";

import { BuildingContainer } from "./BuildingContainer";

import { cloneDeep } from "lodash";

const searchNodes = (data: any, id: string) => {
	let i = 0,
		found;

	for (i; !found && i < data.length; i++) {
		found = getNode(data[i], id);
	}

	return found;
};

const getNode = (node: any, id: string): any => {
	if (node.id === id) return node;

	if (node.children) {
		let i, result;

		for (i = 0; !result && i < node.children.length; i++) {
			result = getNode(node.children[i], id);
		}
		return result;
	}
	return null;
};

const setChildPermissions = (node: any, permission: string) => {
	node.permission = permission;

	let i = 0;
	if (node.points) {
		for (i; i < node.points.length; i++) {
			node.permission = permission;
			setChildPermissions(node.points[i], permission);
		}
	}

	if (!node.children) return node;

	i = 0;
	for (i; i < node.children.length; i++) {
		node.permission = permission;
		setChildPermissions(node.children[i], permission);
	}

	return node;
};

interface IAddRoleProps {
	data: any[];
	permissionedDevices: any;
	setRolePersmissionData: any;
	setPermissionedDevices: any;
}

export const AddRole: React.FC<IAddRoleProps> = ({
	data,
	permissionedDevices,
	setPermissionedDevices,
	setRolePersmissionData,
}) => {
	const setParentPermissions = (parent: any, permission: string) => {
		let dataClone = cloneDeep(data);

		let node = searchNodes(dataClone, parent.id);
		setChildPermissions(node, permission);

		setRolePersmissionData(dataClone);
	};

	return (
		<div>
			{data.map((d: any) => (
				<BuildingContainer
					key={d.id}
					building={d}
					permissionedDevices={permissionedDevices.filter(
						(device: any) => device.buildingId === d.id
					)}
					setParentPermissions={setParentPermissions}
				/>
			))}
		</div>
	);
};
