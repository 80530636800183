import React, { ReactElement } from "react";

// MUI
import {
	Divider,
	ListItem,
	ListItemIcon,
	ListItemText,
	makeStyles,
	Theme,
} from "@material-ui/core";

// Icons
import {
	People,
	ChevronLeft,
	SupervisedUserCircle,
	Business,
	Search,
	FilterList,
	Assessment,
} from "@material-ui/icons";

// Util
import clsx from "clsx";
import { useHistory, useLocation } from "react-router";

interface Props {
	group: any;
}

// Styles
const useStyles = makeStyles((theme: Theme) => ({
	active: {
		color: theme.palette.getContrastText(theme.palette.background.paper),
		fontWeight: 700,
	},
	rolesOpen: {
		transform: "rotate(-180deg)",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	rolesClosed: {
		transform: "rotate(0)",
		transition: theme.transitions.create("transform", {
			duration: theme.transitions.duration.shortest,
		}),
	},
	nested: {
		paddingLeft: theme.spacing(4),
	},
}));

export default function MonitorLinks({ group }: Props): ReactElement {
	// Styles
	const classes = useStyles();

	// Hooks
	const history = useHistory();
	const { pathname } = useLocation();

	const isActive = (test: string) => {
		return pathname.includes(`/${test}`);
	};

	return (
		<div>
			<ListItem>
				<ListItemText
					primary="Device Monitoring"
					secondary="Monitor your building's devices"
				/>
			</ListItem>
			<ListItem button onClick={() => history.push(`/monitor/overview`)}>
				<ListItemIcon>
					<People
						className={clsx({
							[classes.active]: isActive("overview"),
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Overview"
					classes={{
						primary: clsx({
							[classes.active]: isActive("overview"),
						}),
					}}
				/>
			</ListItem>
			<Divider />

			{/* OVERVIEW */}
			<ListItem>
				<ListItemText
					primary="Overview"
					secondary="Top-level information dashboards"
				/>
			</ListItem>

			{/* Clients */}
			<ListItem
				button
				onClick={() => history.push(`/monitor/clients`)}
				disabled
			>
				<ListItemIcon>
					<SupervisedUserCircle
						className={clsx({
							[classes.active]: isActive("clients"),
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Clients"
					classes={{
						primary: clsx({
							[classes.active]: isActive("clients"),
						}),
					}}
				/>
			</ListItem>

			{/* Buildings */}
			<ListItem
				button
				onClick={() => history.push(`/monitor/buildings`)}
				disabled
			>
				<ListItemIcon>
					<Business
						className={clsx({
							[classes.active]: isActive("buildings"),
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Buildings"
					classes={{
						primary: clsx({
							[classes.active]: isActive("buildings"),
						}),
					}}
				/>
			</ListItem>
			<Divider />

			{/* Devices */}
			<ListItem>
				<ListItemText
					primary="Devices"
					secondary="Ground-level device information"
				/>
			</ListItem>

			{/* Browse */}
			<ListItem button onClick={() => history.push(`/monitor/browse`)} disabled>
				<ListItemIcon>
					<Search
						className={clsx({
							[classes.active]: isActive("browse"),
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Browse"
					classes={{
						primary: clsx({
							[classes.active]: isActive("browse"),
						}),
					}}
				/>
			</ListItem>

			{/* Filter */}
			<ListItem button onClick={() => history.push(`/monitor/filter`)} disabled>
				<ListItemIcon>
					<FilterList
						className={clsx({
							[classes.active]: isActive("filter"),
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Filter"
					classes={{
						primary: clsx({
							[classes.active]: isActive("filter"),
						}),
					}}
				/>
			</ListItem>
			<Divider />

			{/* REPORTS */}
			<ListItem>
				<ListItemText primary="Reports" secondary="Generate device report" />
			</ListItem>

			{/* Browse */}
			<ListItem button onClick={() => history.push(`/monitor/monthly-summary`)}>
				<ListItemIcon>
					<Assessment
						className={clsx({
							[classes.active]: isActive("monthly-summary"),
						})}
					/>
				</ListItemIcon>
				<ListItemText
					primary="Monthly Summary"
					classes={{
						primary: clsx({
							[classes.active]: isActive("monthly-summary"),
						}),
					}}
				/>
			</ListItem>
			<Divider />

			{/* GO BACK */}
			<ListItem
				button
				onClick={() => history.push(`/dashboard`)}
				style={{ marginTop: "1rem" }}
			>
				<ListItemIcon>
					<ChevronLeft />
				</ListItemIcon>
				<ListItemText primary="Dashboard" />
			</ListItem>
		</div>
	);
}
