import React, { ReactElement, useState } from "react";

// MUI
import {
    Collapse,
    Grid,
    IconButton,
    List,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Typography,
} from "@material-ui/core";

// Icon
import { Info } from "@material-ui/icons";

// Interfaces
import { IDashboardClient, ITreeDevice } from "../../interfaces";

// Components
import PointInfoModal from "./DeviceSelection/PointInfoModal";
import { DeviceTree } from "./DeviceSelection/DeviceTree";

interface IDeviceSelection {
    clients: IDashboardClient[] | [];
    checkedKeys: string[] | [];
    setCheckedKeys: (keys: string[] | []) => void;
    expandedKeys: string[] | [];
    setExpandedKeys: (keys: string[] | []) => void;
    selectedDevices: ITreeDevice[];
    setSelectedDevices: (devices: any | any[]) => void;
}

/*
  DeviceTree wrapper
  clients:              //array of [{client: {}}, ...] from /clients endpoint
  checkedKeys:          //array of [string] to autoCheck previously checked keys
  setCheckedKeys:       //handler for above
  expandedKeys:         //array of [string] to autoExpand previously expanded keys
  selectedDevices:      //array of ITreeDevice[]
  setSelectedDevices:   //handler for above
*/
export default function DeviceSelection({
    clients,
    checkedKeys,
    setCheckedKeys,
    expandedKeys,
    setExpandedKeys,
    selectedDevices,
    setSelectedDevices,
}: IDeviceSelection): ReactElement {
    // State
    const [devicePointInfo, setDevicePointInfo] = useState<ITreeDevice | any>(
        {}
    );
    const [open, setOpen] = useState(false);

    // Handlers (Point info for selected devices)
    const handleInfoClick = (device: any) => {
        setDevicePointInfo(device);
        setOpen(true);
    };

    const handleClose = () => {
        setDevicePointInfo({});
        setOpen(false);
    };

    return (
        <React.Fragment>
            <Grid container>
                <Grid item xs={12} sm={6} style={{ marginBottom: "1rem" }}>
                    {/* Title */}
                    <Typography variant="h6" gutterBottom>
                        Select Your Devices
                    </Typography>

                    {/* Device Tree */}
                    <DeviceTree
                        data={clients}
                        expandedKeys={expandedKeys}
                        setExpandedKeys={setExpandedKeys}
                        checkedKeys={checkedKeys}
                        setCheckedKeys={setCheckedKeys}
                        selectedDevices={selectedDevices}
                        setSelectedDevices={setSelectedDevices}
                    />
                </Grid>
                <Grid item xs={12} sm={6} style={{ marginBottom: "1rem" }}>
                    {/* Collapse when devices exist to interrogate */}
                    <Collapse in={Boolean(selectedDevices.length)}>
                        {/* Title */}
                        <Typography variant="h6" gutterBottom>
                            Selected Devices ({selectedDevices.length})
                        </Typography>

                        {/* 
                        
                        List of SelectedDevices || THIS NEEDS MEMO
                        
                        */}
                        <List>
                            {selectedDevices.map((device: any) => {
                                const {
                                    key,
                                    deviceName,
                                    buildingName,
                                    floorName,
                                    spaceName,
                                } = device;

                                return (
                                    <ListItem key={key}>
                                        <ListItemText
                                            primary={deviceName}
                                            secondary={`${buildingName} > ${floorName} > ${spaceName}`}
                                        />
                                        {/* Info Button showing point data */}
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                onClick={() =>
                                                    handleInfoClick(device)
                                                }
                                                edge="end"
                                                aria-label="delete"
                                                size="small">
                                                <Info />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Collapse>
                </Grid>
            </Grid>

            {/* Point Modal from Info Button click */}
            <PointInfoModal
                devicePointInfo={devicePointInfo}
                open={open}
                handleClose={handleClose}
            />
        </React.Fragment>
    );
}
