import React, { ReactElement } from "react";

// MUI
import { Grid, TextField, Typography } from "@material-ui/core";

// Interfaces
import { IFormValues } from "../../pages/Dashboard/AddGroup";
interface Props {
	name: string;
	description: string;
	handleChange: (
		value: keyof IFormValues
	) => (event: React.ChangeEvent<HTMLInputElement>) => void;
}

// Name and description for group
export default function NameDescription({
	name,
	description,
	handleChange,
}: Props): ReactElement {
	return (
		<React.Fragment>
			<Grid container>
				<Grid item xs={12} style={{ marginBottom: "1rem" }}>
					<Typography variant="h6" gutterBottom>
						Group Name
					</Typography>
					<TextField
						required
						id="name"
						name="name"
						label="Name"
						value={name}
						variant="outlined"
						fullWidth
						onChange={handleChange("name")}
					/>
				</Grid>
				<Grid item xs={12} style={{ marginBottom: "1rem" }}>
					<Typography variant="h6" gutterBottom>
						Group Description
					</Typography>
					<TextField
						id="description"
						name="description"
						label="Description"
						value={description}
						multiline
						rows={4}
						variant="outlined"
						fullWidth
						onChange={handleChange("description")}
					/>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
