import React from "react";

// Firebase
import { auth } from "../../firebase/firebase";

// Redux
import { useSelector } from "react-redux";

// MUI
import {
	Button,
	Card,
	CardHeader,
	Container,
	FormControl,
	Grid,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	LinearProgress,
	Slide,
	TextField,
	createStyles,
	makeStyles,
	Theme,
} from "@material-ui/core";

// Icons
import { Visibility, VisibilityOff } from "@material-ui/icons";

// Styles
import clsx from "clsx";
import TapaLogo from "../../static/icons/tapaIcon.png";

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		card: {
			justifyContent: "center",
			height: 550,
			maxWidth: 345,
			padding: "2em",
			margin: "0 auto",
			position: "relative",
			borderRadius: 0,
			boxShadow: "none",
		},
		cardHeaderRoot: {
			padding: 0,
		},
		cardHeader: {
			textAlign: "center",
			paddingBottom: "2rem",
		},
		textField: {
			fontSize: "1em",
		},

		margin: {
			margin: "1em 0",
		},
		button: {
			color: "white",
			borderRadius: 0,
			padding: "1em",
		},
	})
);

// State
interface State {
	email: string;
	password: string;
	showPassword: boolean;
	error: string;
	loading: boolean;
}

function Login() {
	// Styles
	const classes = useStyles();

	// State
	const defaultValues = {
		email: "",
		password: "",
		showPassword: false,
		error: "",
		loading: false,
	};
	const [values, setValues] = React.useState<State>(defaultValues);
	const { error } = values;

	// Redux
	const firebase = useSelector((state: any) => state.firebase);

	// Handlers
	const handleChange =
		(prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
			setValues({ ...values, [prop]: event.target.value });
		};

	const handleClickShowPassword = () => {
		setValues({ ...values, showPassword: !values.showPassword });
	};

	const handleMouseDownPassword = (
		event: React.MouseEvent<HTMLButtonElement>
	) => {
		event.preventDefault();
	};

	const handleLogin = (event: React.MouseEvent<HTMLButtonElement>) => {
		event.preventDefault();

		setValues({ ...values, loading: true });
		auth
			.signInWithEmailAndPassword(values.email, values.password)
			.then((res: any) => {
				setValues({ ...values, loading: false });
			})
			.catch((error: any) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(errorCode, errorMessage);
			});
	};

	// Show loading bar while AUTH initializes
	if (!firebase.auth.isLoaded) {
		return <LinearProgress style={{ width: "100%" }} />;
	}

	return (
		<Container
			maxWidth="md"
			style={{
				justifyContent: "flex-end",
				position: "relative",
				marginTop: "1rem",
			}}
		>
			<Card className={classes.card}>
				<div
					style={{
						display: "flex",
						justifyContent: "center",
						paddingBottom: "2rem",
					}}
				>
					<img alt={"tapa logo"} src={TapaLogo} height={80} />
				</div>
				{error ? (
					<CardHeader
						className={clsx(classes.cardHeader)}
						title={"SIGN IN"}
						subheader={error}
					/>
				) : (
					<CardHeader
						className={clsx(classes.cardHeader)}
						title={"SIGN IN"}
						classes={{
							root: classes.cardHeaderRoot,
						}}
						subheader={error ? error : "Enter your email and password to login"}
					/>
				)}

				{/* Default */}
				<Slide direction="left" in={true} mountOnEnter unmountOnExit>
					<Grid container style={{ display: "flex", justifyContent: "center" }}>
						<form action="/" onSubmit={(e: any) => handleLogin}>
							<Grid item xs={12}>
								<TextField
									className={clsx(classes.textField, classes.margin)}
									onChange={handleChange("email")}
									required
									variant="outlined"
									fullWidth
									value={values.email}
									label="Email"
								/>
							</Grid>
							<Grid item xs={12}>
								<FormControl
									className={clsx(classes.margin, classes.textField)}
								>
									<InputLabel htmlFor="standard-adornment-password">
										Password
									</InputLabel>
									<Input
										id="standard-adornment-password"
										type={values.showPassword ? "text" : "password"}
										value={values.password}
										onChange={handleChange("password")}
										endAdornment={
											<InputAdornment position="end">
												<IconButton
													aria-label="toggle password visibility"
													onClick={handleClickShowPassword}
													onMouseDown={handleMouseDownPassword}
												>
													{values.showPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										}
									/>
								</FormControl>
							</Grid>
							<Grid item xs={12} style={{ paddingTop: "1em" }}>
								<Button
									fullWidth
									type="submit"
									variant="contained"
									color="primary"
									className={classes.button}
									onClick={handleLogin}
									disabled={values.email === "" || values.password === ""}
								>
									sign in
								</Button>
							</Grid>
						</form>
					</Grid>
				</Slide>
			</Card>
		</Container>
	);
}

export default Login;
